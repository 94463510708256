import { Badge } from "react-bootstrap"
import { IMAGES } from "../../constants/images"
import { db } from "../../config/firebase"
import {
  Timestamp,
  collection,
  doc,
  query,
  setDoc,
  updateDoc,
} from "firebase/firestore"
import { useCollectionData } from "react-firebase-hooks/firestore"
import { useSelector } from "react-redux"
import { userSelector } from "../../app/userRedux/userSlice"

const dateDisplay = (date) => {
  // Convert the timestamp string to a number and create a Date object
  if(date !== undefined){
    return date.toLocaleTimeString([], {
      hour: "2-digit",
      minute: "2-digit",
    })
  }
}

const stripHtmlTags = (htmlString) => {
  const tempElement = document.createElement('div');
  tempElement.innerHTML = htmlString;
  return tempElement.textContent || tempElement.innerText || '';
};
function removeTrailingNbsp(message) {
  while (message.endsWith('&nbsp;')) {
      message = message.slice(0, -6); // Remove the last 6 characters (length of "&nbsp;")
      console.log("Removing trail")
  }
  return message;
}


const Conversation = ({ conversation, onClick, activeConversationId }) => {
  const user = useSelector(userSelector)
  const conversationPartner = conversation.conversationPartner

  const [messages, loadingMessages] = useCollectionData(
    query(collection(db, "conversations", conversation.id, "messages")),
  )
  const unreadMessagesCount = messages
  ? messages.filter(
      message => !message.read && message.sentBy !== user?.uid
    ).length
  : 0;

  const messagesSorted = messages
    ? messages.sort((a, b) => {
        if (a.date && b.date) {
          return a.date.toDate() - b.date.toDate();
        }
        return 0; // If dates are not available or not in expected format
      })
    : [];

  const lastMessage =
    messagesSorted.length > 0
      ? messagesSorted[messagesSorted.length - 1]?.message
      : null;
  const lastMessageDate =
    messagesSorted.length > 0
      ? messagesSorted[messagesSorted.length - 1]?.date
      : null;

  const formattedLastMessageDate = lastMessageDate
    ? lastMessageDate.toLocaleString() // Format the date as per your requirement
    : null;

    const strippedMessage = lastMessage && (
      removeTrailingNbsp(stripHtmlTags(lastMessage))
    )

  const truncatedLastMessage =
    strippedMessage && strippedMessage.length > 20
      ? strippedMessage.substring(0, 20) + "..."
      : strippedMessage;



  return (
    messages?.length !== 0 && (
      <div
        className={`flex hover-effect justify-between align-items-center h-16 px-3 ${
          activeConversationId === conversation.id ? "selected" : ""
        }`}
        onClick={onClick}
      >
        <div className="lg:w-[15%]">
          <img
            src={
              conversationPartner.isEmployer
                ? conversation.incognito
                  ? IMAGES.U_ICON
                  : conversationPartner.profileImg
                  ? conversationPartner.profileImg
                  : IMAGES.U_ICON
                : conversationPartner.firmLogo
            }
            className="w-10 h-10 rounded-full"
            alt=""
          />
        </div>
        {/* info */}
        <div className="hidden lg:flex w-[60%] flex-column items-left justify-center pl-2">
          <p className="font-bold">
            {conversationPartner.isEmployer
              ? conversation.incognito
                ? "Anonymous"
                : conversationPartner.name
                ? conversationPartner.name + " " + conversationPartner.surname
                : "Anonymous"
              : conversationPartner.companyName}
            {/* {JSON.stringify(conversation)} */}
          </p>
          <p className={unreadMessagesCount !== 0 ? "text-2xs font-bold" : "text-2xs"}  >{truncatedLastMessage}</p>
        </div>
        <div className="w-25 flex flex-column gap-y-1 items-end">
          <p className="hidden md:inline-block font-bold">{dateDisplay(lastMessageDate?.toDate())}</p>
          {
            unreadMessagesCount !== 0 && (
              <Badge
                bg=""
                className="bg-[#868BFF] rounded-full w-5 h-5 flex items-center justify-center"
              >
                {unreadMessagesCount}
              </Badge>
            )

          }
          
        </div>
      </div>
    )
  )
}

export default Conversation