import React, { useState } from "react"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import { IMAGES } from "../../constants/images"

import { CiLocationOn } from "react-icons/ci"
import { PiSuitcaseLight } from "react-icons/pi"
import { BiMessage } from "react-icons/bi"
import { MdOutlineMenu } from "react-icons/md"
import ApplicationFinished from "./ApplicationFinished"

import { useSelector } from "react-redux"
import { useDispatch } from "react-redux"
import { jobApply } from "../../app/functions"
import { saveData } from "../../app/dataSlice.js"

const ApplicationPreview = ({ backBtn, handleClose, setApplied }) => {
  const [lastPage, setLastPage] = useState(false)

  const dispatch = useDispatch()
  const initialData = useSelector((state) => state.initialData)
  const user = useSelector((state) => state.data.value)

  const sendApplication = () => {
    const job_id = initialData.selectedJob.uid
    const employer_id = initialData.selectedJob.userid
    const user_id = initialData.uid
    const cv = initialData.cv
    const coverLetter = initialData.coverLetter
    const transcripts = initialData.transcripts

    setLastPage(true)
    jobApply(job_id, user_id, cv, coverLetter, transcripts, employer_id)
    setApplied(true)
  }

  return (
    <>
      {lastPage ? (
        <>
          <ApplicationFinished handleClose={handleClose} />
        </>
      ) : (
        <>
          <Row className="text-black text-center mt-3">
            <p className="font-bold text-2xl mb-1">Preview Application</p>
            <p>Check if the data is correct.</p>
          </Row>
          {/* jobs info */}
          <Row className="flex flex-column items-center my-3 text-sm">
            <Row>
              <Col>
                <p className="flex flex-col md:flex-row text-center md:text-start items-center mb-1">
                  <PiSuitcaseLight size={20} className="mr-2" />
                  {user?.experience ? (
                    <>
                      <p>{user?.experience} of experience</p>
                    </>
                  ) : (
                    <>
                      <p>No experience </p>
                    </>
                  )}
                </p>

                <p className="flex flex-col md:flex-row text-center md:text-start items-center mb-1">
                  <MdOutlineMenu size={20} className="mr-2" />
                  {user?.expertise[0] ? (
                    <>{user?.expertise?.join(" | ")}</>
                  ) : (
                    <>
                      <p>No expertise is added</p>
                    </>
                  )}
                </p>
              </Col>
              <Col className="flex flex-column justify-start items-end">
                {/* <p className="flex flex-col md:flex-row text-center md:text-start items-center mb-1">
                  <CiLocationOn size={20} className="mr-2" />
                  {initialData?.location == "" ? (
                    <>Location not added</>
                  ) : (
                    initialData?.location
                  )}
                </p> */}

                <p className="flex flex-col md:flex-row text-center md:text-start items-center mb-1">
                  <BiMessage size={20} className="mr-2" />
                  {user?.selectedLanguages[0] ? (
                    <>{user?.selectedLanguages.join(" | ")}</>
                  ) : (
                    <>
                      <p>No language is added</p>
                    </>
                  )}
                </p>
              </Col>
            </Row>
            <Row className="mt-3">
              <p className="text-justify">
                {initialData?.description == "" ? (
                  <>No description added.</>
                ) : (
                  <>{initialData?.description}</>
                )}
              </p>
            </Row>
          </Row>
          {/* jobs  attachments */}
          <Row className="flex flex-column items-center my-3">
            <Row className="flex justify-center gap-x-5 ">
              {initialData.cv !== "" && (
                <Row
                  className="flex flex-column items-center text-center bg-[#F1F1F5] p-2 cursor-pointer w-[32%] rounded-md "
                  onClick={() => window.open(initialData.cv, "_blank")}
                >
                  {/* <img src={IMAGES.BOOK} className="h-12 w-16" />
                  <p>CV</p> */}
                  <label
                    className="flex flex-column items-center gap-y-2  cursor-pointer "
                    for="newCL"
                  >
                    <img className="" src={IMAGES.BOOK} />
                    CV
                  </label>
                </Row>
              )}
              {initialData.coverLetter !== "" && (
                <Row
                  className="flex flex-column items-center text-center bg-[#F1F1F5] p-2 cursor-pointer w-[32%] rounded-md ml-2"
                  onClick={() => window.open(initialData.coverLetter, "_blank")}
                >
                  <label
                    className="flex flex-column items-center gap-y-2  cursor-pointer "
                    for="newCL"
                  >
                    <img className="" src={IMAGES.BOOK} />
                    Cover letter
                  </label>
                </Row>
              )}
            </Row>
          </Row>
          {/* jobs */}

          {/* buttons */}
          <Row className="flex flex-column items-center gap-y-3 mb-3">
            <button
              className="bg-[#46D6EA] w-75 py-2 rounded-md font-bold  border-1 border-[#46D6EA] cursor-pointer hover:bg-transparent"
              onClick={() => sendApplication()}
            >
              Send Application
            </button>
            <button
              className="  py-2 rounded-md w-75 font-bold  border-1 border-black cursor-pointer hover:bg-transparent"
              onClick={backBtn}
            >
              Back
            </button>
          </Row>
        </>
      )}
    </>
  )
}

export default ApplicationPreview
