import React, { useState } from "react"
import { FiChevronLeft } from "react-icons/fi"
import { useDispatch, useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"
import { updateInitialData } from "../../app/initialDataSlice"
import { IMAGES } from "../../constants/images.jsx"
import styles from "./CandidateData.module.css"

const CandidateData6 = () => {
  const dispatch = useDispatch()
  const initialData = useSelector((state) => state.initialData)

  const [description, setDescription] = useState("")

  const prevWindow = (e) => {
    navigate("/candidatedata2")
  }

  const navigate = useNavigate()

  const nextWindow = async () => {
    navigate("/candidatedata4")
  }

  return (
    <div className={styles.container}>
      <div className="flex flex-column justify-center items-center bg-white border-5 border-t-purple-400 rounded-md w-50 p-3 ">
        <div className="flex flex-column items-center w-[80%] my-3">
          <img src={IMAGES.JLFULL} className="h-10 my-2" />
          <p className="font-bold text-start text-gray-400">Candidate</p>
          <div className="w-full text-start my-2">
            <p className="font-semibold">Almost done!</p>
            <p className="my-2">
              Consider this your personal spotlight – feel free to add anything
              that matters to you. When you apply for a position, law firms will
              get to know you better by reading this additional information.
              It's your chance to stand out!
            </p>
            <p className="font-semibold">About me</p>
            <textarea
              className="border-1 border-black rounded-md w-full h-[150px] px-2 py-1 mt-2"
              placeholder="About me"
              onChange={(e) =>
                dispatch(updateInitialData({ description: e.target.value }))
              }
              value={initialData.description}
            />
          </div>
          <div className="flex justify-between items-center w-100">
            <button
              className="flex items-center py-2 rounded-md font-bold  w-[20%]  cursor-pointer"
              onClick={(e) => prevWindow(e)}
            >
              <FiChevronLeft size={22} />
              Back
            </button>
            <p className="flex-1 text-center">5/5</p>
            <button
              className="bg-[#868BFF]  py-2 rounded-md font-bold  w-[20%] border-1 border-[#868BFF] cursor-pointer hover:bg-transparent"
              onClick={(e) => nextWindow(e)}
            >
              Finish
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default CandidateData6
