import { doc } from "firebase/firestore"
import { useDocumentData } from "react-firebase-hooks/firestore"
import { db } from "../config/firebase"

const useFetchJob = (id) => {
  const [job, jobIsLoading] = useDocumentData(doc(db, "jobs", id))

  return {
    job,
    jobIsLoading,
  }
}

export default useFetchJob
