import React from "react"
import { IMAGES } from "../constants/images"
import Nav from "react-bootstrap/Nav"
import { NavLink } from "react-router-dom"
import { BiLogoFacebook, BiLogoInstagramAlt } from "react-icons/bi"
import { BsTwitterX, BsYoutube } from "react-icons/bs"

const Footer = () => {
  return (
    <div
      name="contact"
      className="flex flex-col items-center justify-center p-5"
    >
      <div className="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-4 border-b-[1px] gap-2 border-[#D8E0EB]/100 pb-4 w-full">
        <div className="mb-3">
          <img src={IMAGES.JL_WHITE} className="w-75" />
        </div>
        <div>
          <h3 className="font-semibold">Learn More</h3>
          <div className="flex flex-col text-xs mt-3 gap-y-2">
            <NavLink to="#">About us</NavLink>
            <NavLink to="#">Privacy Policy</NavLink>
            <NavLink to="#">Terms</NavLink>
          </div>
        </div>
        <div>
          <h3 className="font-semibold">Contact Us</h3>
          <div className="flex items-center text-xs mt-3">
            <p>Support contact:&nbsp; </p> <span>+32 555 555 555</span>
          </div>
        </div>
        <div className="font-semibold">
          <h3>Social</h3>
          <div className="flex items-center gap-x-3 mt-3">
            <NavLink to="#">
              <BiLogoFacebook />
            </NavLink>
            <NavLink to="#">
              <BiLogoInstagramAlt />
            </NavLink>
            <NavLink to="#">
              <BsTwitterX />
            </NavLink>
            <NavLink to="#">
              <BsYoutube />
            </NavLink>
          </div>
        </div>
      </div>
      <div className="flex flex-col lg:flex-row items-center text-center justify-around text-[#D8E0EB]/100 text-sm mt-3 w-75">
        <p>© 2019 Just Lawyers. All Rights Reserved </p>
        <p>Terms</p>
        <p>Policy</p>
        <p>Trust center</p>
        <p>Legal & compliance </p>
        <p>Cookies </p>
      </div>
    </div>
  )
}

export default Footer
