import { Route, Routes } from "react-router-dom"
import GuestGuard from "../components/routing/GuestGuard"
import FirmRegister from "../pages/RecruiterRegister"
import LoginPage from "./auth/Login"
import RegisterPage from "./auth/Register"

const AuthenticationApp = () => {
  return (
    <GuestGuard>
      <Routes>
        <Route path="login" element={<LoginPage />} />
        <Route path="register" element={<RegisterPage />} />
        <Route path="register/firm" element={<FirmRegister />} />
        <Route path="*" element="404 Page Not Found" />
      </Routes>
    </GuestGuard>
  )
}

export default AuthenticationApp
