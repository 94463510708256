import classNames from "classnames"
import React, { useState } from "react"
import { Link, NavLink, useNavigate } from "react-router-dom"
import { signOutUser } from "../app/functions.js"
import UserDropdown from "../components/UserDropdown"
import { IMAGES } from "../constants/images"
import styles from "./Navbar.module.css"
import { Navbar, Container, Offcanvas } from "react-bootstrap"
import NotificationDropdown from "../components/NotificationDropdown.jsx"
import MessagesNotification from "../components/MessagesNotification.jsx"
// import ChatRoom from "./pages/ChatRoom/ChatRoom.jsx"

const NavBarItem = ({ children, to }) => {
  return (
    <NavLink
      to={to}
      className={({ isActive }) =>
        classNames(styles.link, { [styles.activeCandidate]: isActive })
      }
    >
      {children}
    </NavLink>
  )
}

const NavBar = () => {
  const [showMenu, setShowMenu] = useState(false)

  const handleMenuOpen = () => {
    setShowMenu(true)
  }

  const handleMenuClose = () => {
    setShowMenu(false)
  }

  const handleItemClick = () => {
    setShowMenu(false) // Close the menu when an item is clicked
  }

  const navigate = useNavigate()

  const handleEditUser = () => {
    // Add logic for handling Edit User action
    console.log("Edit User clicked")
  }

  const handleSignout = async () => {
    await signOutUser()
  }

  return (
    <>
      <div className={styles.navbar}>
        <Link to="/homeCandidate" className={styles.logo}>
          <img
            className="logo_css cursor-pointer"
            src={IMAGES.JLFULL}
            alt="logo"
          />
        </Link>
        <div className={styles.links}>
          <NavBarItem to="/jobboard">Home</NavBarItem>


          <NavBarItem to="/mymatches">My Matches</NavBarItem>
          <div className="flex flex-row justify-center items-center  ">
            <NavBarItem to="/chatroom">Messages</NavBarItem>
            <MessagesNotification/>
          </div>
         
          <NavBarItem to="/userprofile">User Profile</NavBarItem>
          <div className="justify-center align-items-center flex">
            <NotificationDropdown />

            <UserDropdown
              handleEditUser={handleEditUser}
              handleSignout={handleSignout}
              navigate={navigate}
            />
          </div>
        </div>
      </div>
      <Navbar
        expand="lg"
        className="inline-block custom:hidden bg-body-tertiary mb-3 w-full"
      >
        <Container fluid>
          <Navbar.Brand href="/">
            <Link to="/homeCandidate" className={styles.logo}>
              <img
                className="logo_css cursor-pointer"
                src={IMAGES.JLFULL}
                alt="logo"
              />
            </Link>
          </Navbar.Brand>
          <Navbar.Toggle
            aria-controls={`offcanvasNavbar-expand`}
            onClick={handleMenuOpen}
            className="bg-white"
          />
          <Navbar.Offcanvas
            id={`offcanvasNavbar-expand`}
            aria-labelledby={`offcanvasNavbarLabel-expand`}
            placement="end"
            onHide={handleMenuClose}
            show={showMenu}
            className="w-1/2"
            style={{width:"50%"}}
          >
            <Offcanvas.Header  className="flex justify-center">
              <Offcanvas.Title
              // id={`offcanvasNavbarLabel-expand`}
              >
                <Link
                  to="/homeCandidate"
                  className={styles.logo}
                  onClick={handleItemClick}
                >
                  <img
                    className="cursor-pointer h-[25px]"
                    src={IMAGES.JLFULL}
                    alt="logo"
                  />
                </Link>
              </Offcanvas.Title>
              {/* <button type="button" class="btn-close" aria-label="Close">X</button> */}
            </Offcanvas.Header>
            <Offcanvas.Body className="">
              <div className="flex flex-col justify-center items-center gap-y-3">
                {/* <NavBarItem to="/homeCandidate" onClick={handleItemClick}>
                  <p className="text-xl">Home</p>
                </NavBarItem> */}
                <NavBarItem to="/jobboard" onClick={handleItemClick}>
                  
                  <p className="text-xl">Home</p>
                  {/* <p className="text-xl">Job Board</p> */}
                </NavBarItem>
                <NavBarItem to="/mymatches" onClick={handleItemClick}>
                  
                  <p className="text-xl">My Matches</p>
                </NavBarItem>
                <NavBarItem to="/chatroom" onClick={handleItemClick}>
                  
                  <p className="text-xl">Messages</p>
                  <MessagesNotification/>

                </NavBarItem>
                <NavBarItem to="/userprofile" onClick={handleItemClick}>
                  
                  <p className="text-xl">User Profile</p>
                </NavBarItem>
                <div>
                  <UserDropdown
                    handleEditUser={handleEditUser}
                    handleSignout={handleSignout}
                    navigate={navigate}
                  />
                </div>
              </div>
            </Offcanvas.Body>
          </Navbar.Offcanvas>
        </Container>
      </Navbar>
    </>
  )
}

export default NavBar
