import React, { useState } from "react"

import { Modal, Button } from "react-bootstrap"
const IncognitoModal = ({ show, handleClose, setIncognitoMode, isIncognito}) => {
  return (
    <Modal show={show} onHide={handleClose} centered>
      {/* <Modal.Header></Modal.Header> */}
      <Modal.Body  className="border-t-8 border-[#868BFF] rounded-t-lg">
        <div className="flex flex-column items-center justify-center px-4 ">
          {
            isIncognito?
            <p className="font-bold text-2xl mb-3">Deactivate Incognito Mode</p>
            :
            <p className="font-bold text-2xl mb-3">Activate Incognito Mode</p>
          } 
          {
            isIncognito?
            <p >
              You are about to switch off incognito mode. Law firms will see who
              you are when you send a message.
            </p>
            :
            <p >
              You are about to switch on incognito mode. Law firms won't see who
              you are when you send a message.
            </p>
          } 
          
        </div>
      </Modal.Body>
      {
            isIncognito?
            <Modal.Footer className="flex items-center flex-column justify-center w-full">
              <Button
                variant=""
                className="bg-[#46D6EA] w-50"
                onClick={() => {
                  setIncognitoMode(false)
                  handleClose()
                }}
              >
                Switch to active mode
              </Button>
              <Button
                variant=""
                className="border-[1px] border-black w-50"
                onClick={() => {
                  setIncognitoMode(true)
                  handleClose()
                }}
              >
                Back to incognito mode
              </Button>
            </Modal.Footer>
            :
            <Modal.Footer className="flex items-center flex-column justify-center w-full">
              <Button
                variant=""
                className="bg-[#46D6EA] w-50"
                onClick={() => {
                  setIncognitoMode(true)
                  handleClose()
                }}
              >
                Switch to incognito mode
              </Button>
              <Button
                variant=""
                className="border-[1px] border-black w-50"
                onClick={() => {
                  setIncognitoMode(false)
                  handleClose()
                }}
              >
                
                Back to active mode
              </Button>
            </Modal.Footer>
        } 
      
    </Modal>
  )
}

export default IncognitoModal
