import React, { useState } from "react"
import { OverlayTrigger, Tooltip } from "react-bootstrap"
import { useDispatch, useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"
import CreatableSelect from "react-select/creatable"
import { updateInitialData } from "../../app/initialDataSlice"
import {
  citiesOptions,
  expertiseOptions,
  firmsOptions,
  salaryRangeOptions,
  typeOfFirmOptions
} from "../../constants/Data.js"
import { IMAGES } from "../../constants/images.jsx"

const CandidateData5 = () => {
  const initialData = useSelector((state) => state.initialData)

  const [queryFirmType, setQueryFirmType] = useState("")

  const [notMatchingList, setNotMatchingList] = useState([])
  const [queryNotMatching, setQueryNotMatching] = useState("")

  const navigate = useNavigate()
  const dispatch = useDispatch()
  const loggedUser = useSelector((state) => state.data.value)

  const handleChangeTypeofFirm = (e) => {
    e.preventDefault()

    setQueryFirmType(e.target.value)
  }

  const getNotMatchingList = async (name) => {
    setQueryNotMatching(name)
    if (name.length < 1) {
      // Don't fetch suggestions if the query is too short
      setNotMatchingList([])
      return
    }
  }

  const handleSelectionCities = (selectedOptions) => {
    const newCities = selectedOptions
      ? selectedOptions.map((option) => option.label)
      : []
    dispatch(updateInitialData({ cities: newCities }))
  }

  const handleSelectionTypeOfFirems = (selectedOptions) => {
    const newTypes = selectedOptions
      ? selectedOptions.map((option) => option.label)
      : []
    dispatch(updateInitialData({ typeOfFirm: newTypes }))
  }

  const handleChangeRemuneration = (e) => {
    const newRemuneration = e
    dispatch(updateInitialData({ annualRemuneration: newRemuneration }))
  }

  const handleSelectionExpertise = (expertiseOptions) => {
    const arrayOfLabels = expertiseOptions.map((obj) => obj.label)
    dispatch(updateInitialData({ expertise: arrayOfLabels }))
    // setQueryExpertise(arrayOfLabels)
  }
  const handleSelectionFirms = (firmsOptions) => {
    const arrayOfLabels = firmsOptions.map((obj) => obj.label)

    dispatch(updateInitialData({ nottomatch: arrayOfLabels }))
  }
  const handleSelectionFirmsToMatch = (firmsOptions) => {
    const arrayOfLabels = firmsOptions.map((obj) => obj.label)

    dispatch(updateInitialData({ tomatch: arrayOfLabels }))
  }

  const prevWindow = () => {
    navigate("/candidatedata0")
  }
  const nextWindow = () => {
    navigate("/candidatedata1")
  }

  const tooltipContent1 =
    "If yes, we'll make sure to notify you if they post a job."

  const tooltipContent =
    "If yes, just let us know, and we'll make sure to exclude them from your updates."

  return (
    <div className="flex justify-center items-center p-[100px] text-black overflow-y-auto">
      <div className="flex flex-column justify-center items-center bg-white border-5 border-t-purple-400 rounded-md w-50 p-3 ">
        <div className="flex flex-column items-center w-[80%] my-3">
          <img src={IMAGES.JLFULL} className="h-10 my-2" />
          <p className="font-bold text-start text-gray-400">Candidate</p>

          <div className="items-start my-4 w-full">
            <div>
              <p className="my-3">Time to describe your ideal job!</p>
              <p className="my-3">
                Your input fuels our AI-driven algorithm, creating perfect
                matches just for you. The more we know, the better your
                opportunities. Let's begin!
              </p>
            </div>
            {/* location */}
            <div className="w-full mt-2">
              <label className="font-bold">Cities</label>
              <CreatableSelect
                isClearable
                placeholder="Select cities..."
                defaultValue={[]}
                isMulti
                name="cities"
                options={citiesOptions}
                className="basic-multi-select"
                classNamePrefix="select"
                onChange={handleSelectionCities}
                value={initialData.cities.map((city) => ({
                  label: city,
                  value: city,
                }))}
              />
            </div>
            {/* type of firm */}
            <div>
              <div className=" my-2">
                <p className="font-bold">Type of Firm</p>
                <CreatableSelect
                  isClearable
                  placeholder="Select Type..."
                  defaultValue={[]}
                  isMulti
                  name="type"
                  options={typeOfFirmOptions}
                  className="basic-multi-select"
                  classNamePrefix="select"
                  onChange={handleSelectionTypeOfFirems}
                  value={initialData.typeOfFirm?.map((firm) => ({
                    label: firm,
                    value: firm,
                  }))}
                />
              </div>
            </div>
            {/* years of Experience
            <div className="w-full mt-2">
              <label>Experience</label>
              <Select
                className="basic-single"
                classNamePrefix="select"
                defaultValue={""}
                name="Experience"
                options={experienceOptions}
                onChange={handleChangeYearsOfExperience}
              />
            </div> */}

            {/* Expertise */}
            <div>
              <div className=" my-2">
                <p className="font-bold">Expertise</p>
                <CreatableSelect
                  isClearable
                  placeholder="Select Type..."
                  defaultValue={[]}
                  isMulti
                  name="type"
                  options={expertiseOptions}
                  className="basic-multi-select"
                  classNamePrefix="select"
                  onChange={handleSelectionExpertise}
                  value={initialData.expertise?.map((exp) => ({
                    label: exp,
                    value: exp,
                  }))}
                />
              </div>
            </div>
            {/* Annual remuneration */}
            <div className="w-full mt-2">
              <p className="font-bold">Annual remuneration</p>

              <CreatableSelect
                isClearable
                className="basic-single"
                classNamePrefix="select"
                defaultValue={""}
                name="Annual remuneration"
                options={salaryRangeOptions}
                onChange={handleChangeRemuneration}
                value={initialData.annualRemuneration}
              />
            </div>
            {/* <div className=" my-2">
              <p className="font-bold">Annual remuneration</p>
              <select
                className="w-full border-1 border-gray-400 rounded-md p-2"
                name="position"
                id="position"
                //   value={}
                onChange={handleChangeRemuneration}
              >
                <option className="" value="" disabled selected hidden>
                  Select Remuneration
                </option>
                <option value="0 – 30.000 EUR">0 – 30.000 EUR</option>
                <option value="30.000 – 40.000 EUR">30.000 – 40.000 EUR</option>
                <option value="40.000 – 50.000 EUR">40.000 – 50.000 EUR</option>
                <option value="50.000  – 75.000 EUR">
                  50.000 – 75.000 EUR
                </option>
                <option value="75.000  – 100.000 EUR">
                  75.000 – 100.000 EUR
                </option>
                <option value="100.000  – 130.000 EUR">
                  100.000 – 130.000 EUR
                </option>
                <option value="130.000  – 150.000 EUR">
                  130.000 – 150.000 EUR
                </option>
                <option value="150.000  – 175.000 EUR">
                  150.000 – 175.000 EUR
                </option>
                <option value="175.000  – 200.000 EUR">
                  175.000 – 200.000 EUR
                </option>
                <option value="200.000  – 250.000 EUR">
                  200.000 – 250.000 EUR
                </option>
                <option value="250K+ EUR">250K+ EUR</option>
              </select>
            </div> */}
          </div>
          <div
            className="w-full border-t-2 border-gray-400 pt-2"
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <p>Any specific law firms you'd like to follow? &#8205; </p>
            <OverlayTrigger
              placement="top"
              overlay={
                <Tooltip placement="top" className="in" id="tooltip-top">
                  {tooltipContent1}
                </Tooltip>
              }
            >
              <img src={IMAGES.QUESTIONMARK} height={17.5} width={17.5} />
            </OverlayTrigger>
          </div>
          <div className="w-full">
            {/* low firm list candidate doesn't want to work with */}
            <div className=" my-2 bg-green-100 px-2 py-1 rounded-md">
              <p className="font-bold">Law Firm </p>
              <CreatableSelect
                isClearable
                placeholder="Select Law Firm..."
                defaultValue={[]}
                isMulti
                name="type"
                options={firmsOptions}
                className="basic-multi-select"
                classNamePrefix="select"
                onChange={handleSelectionFirmsToMatch}
                value={initialData.tomatch.map((name) => ({
                  label: name,
                  value: name,
                }))}
              />
            </div>
          </div>
          <div
            className="w-full border-t-2 border-gray-400 pt-2"
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <p>
              Any specific law firms you'd rather not be matched with? &#8205;{" "}
            </p>
            <OverlayTrigger
              placement="top"
              overlay={
                <Tooltip placement="top" className="in" id="tooltip-top">
                  {tooltipContent}
                </Tooltip>
              }
            >
              <img src={IMAGES.QUESTIONMARK} height={17.5} width={17.5} />
            </OverlayTrigger>
          </div>
          <div className="w-full">
            {/* low firm list candidate doesn't want to work with */}
            <div className=" my-2 bg-red-100 px-2 py-1 rounded-md">
              <p className="font-bold">Law Firm </p>
              <CreatableSelect
                isClearable
                placeholder="Select Law Firm..."
                defaultValue={[]}
                isMulti
                name="type"
                options={firmsOptions}
                className="basic-multi-select"
                classNamePrefix="select"
                onChange={handleSelectionFirms}
                value={initialData.nottomatch.map((name) => ({
                  label: name,
                  value: name,
                }))}
              />
            </div>
          </div>
          <div className="flex justify-between items-center w-100">
            <button
              className=" py-2 rounded-md font-bold  w-[20%] border-1 cursor-pointer"
              onClick={(e) => prevWindow(e)}
            >
              Back
            </button>
            <p className=" text-center">2/5</p>
            <button
              className="  py-2 rounded-md font-bold  w-[20%] border-1 border-[#46D6EA] cursor-pointer hover:bg-transparent"
              onClick={(e) => nextWindow(e)}
            >
              Skip
            </button>
            <button
              className="bg-[#46D6EA]  py-2 rounded-md font-bold  w-[20%] border-1 border-[#46D6EA] cursor-pointer hover:bg-transparent"
              onClick={(e) => nextWindow(e)}
            >
              Next
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default CandidateData5

{
  /* <select
                  className="w-full border-1 border-gray-400 rounded-md p-2  "
                  name="position"
                  id="position"
                  //   value={}
                  onChange={handleChangeTypeofFirm}
                >
                  <option className="" value="" disabled selected hidden>
                    Select Type
                  </option>
                  <option value="Belgian">Belgian</option>
                  <option value="Benelux">Benelux</option>
                  <option value="International">International</option>
                  <option value="niche">Niche</option>
                  <option value="full-service-firm">Full-service firm</option>
                  <option value="full-service-firm">No preference</option>
                </select> */
}
