import React from "react"
import { IMAGES } from "../../constants/images.jsx"
import NavbarLanding from "../../utils/NavBarLanding.jsx"
import { HiArrowLongRight } from "react-icons/hi2"
import { useNavigate } from "react-router-dom"
import LandingPageCard from "../../components/LandingPageCard.jsx"
import { Accordion, Carousel } from "react-bootstrap"
import Footer from "../../utils/Footer.jsx"
import { testNode } from "../../app/functions.js"
// import OurMission from "./OurMission.jsx"

const LandingPage1 = () => {
  const navigate = useNavigate()
  return (
    <div className="flex flex-col items-center justify-center bg-[#131F2E] h-full w-full text-white ">
      <NavbarLanding />
      {/* welcome section */}
      <section className="flex items-start md:items-center p-0 md:pl-10 mt-5 md:mt-3 h-[75vh] md:h-fit  w-full">
        <div className="flex flex-col items-center justify-center gap-y-5 md:gap-y-3 p-0 md:pl-[80px] mt-3 lg:m-0">
          <div className="flex flex-col w-75 gap-y-5 md:gap-y-3 items-center md:items-start">
            <h1 className="font-semibold text-5xl text-center md:text-left"
              onClick={()=> testNode()}
            >
              Welcome to JustLawyers.{" "}
            </h1>
            <h2 className="text-2xl italic">No recruiters, just lawyers.</h2>
            <p className=" text-center md:text-justify">
              The first fully automated AI-driven matchmaking platform tailored
              for lawyers and law firms. Thanks to our cutting-edge algorithm,
              your top matches await!
            </p>
          </div>
          <div className="flex w-75 justify-center lg:justify-start mt-5">
            <button
              className="flex items-center justify-center w-[75%]  md:w-fit bg-[#46d6ea] border-1 border-[#46d6ea] text-black text-center px-4 py-1 rounded-full "
              onClick={() => navigate("auth/register")}
            >
              Get Started <HiArrowLongRight size={20} className="ml-1" />
            </button>
          </div>
        </div>
        <div className="hidden md:inline-block">
          <img src={IMAGES.BIG_LOGO} />
        </div>
      </section>
      {/* partners section */}
      <section className="flex flex-col items-center justify-center bg-white py-3 my-5  w-full">
        <div>
          <h2 className="text-black font-semibold">
            Connect with leading law firm
          </h2>
        </div>
        {/* <div className="hidden md:flex flex-row items-center justify-between w-full mt-4 px-5 gap-x-5">
          <div className="flex items-center justify-center">
            <img src={IMAGES.LYDIAN} className="h-fit w-fit" />
          </div>
          <div className="flex items-center justify-center">
            <img src={IMAGES.MCDERMOTT} className="h-fit w-fit" />
          </div>
          <div className="flex items-center justify-center">
            <img src={IMAGES.SIMMONS} className="h-fit w-fit" />
          </div>
          <div className="flex items-center justify-center">
            <img src={IMAGES.ALTIUS} className="h-fit w-fit" />
          </div>
          <div className="flex items-center justify-center">
            <img src={IMAGES.CROWELL} className="h-fit w-fit" />
          </div>
          <div className="flex items-center justify-center">
            <img src={IMAGES.AKD} className="h-fit w-fit" />
          </div>
          <div className="flex items-center justify-center">
            <img src={IMAGES.VBB} className="h-fit w-fit" />
          </div>
        </div> */}
        <Carousel className="inline-block mt-3 w-full " indicators={false}>
          <Carousel.Item interval={1500}>
            <div className="flex items-center justify-center h-[150px]">
              <img src={IMAGES.LYDIAN} className="h-fit w-fit" />
            </div>
          </Carousel.Item>
          <Carousel.Item interval={1500}>
            <div className="flex items-center justify-center h-[150px]">
              <img src={IMAGES.MCDERMOTT} className="h-fit w-fit" />
            </div>
          </Carousel.Item>
          <Carousel.Item interval={1500}>
            <div className="flex items-center justify-center h-[150px]">
              <img src={IMAGES.SIMMONS} className="h-fit w-fit" />
            </div>
          </Carousel.Item>
          <Carousel.Item interval={1500}>
            <div className="flex items-center justify-center h-[150px]">
              <img src={IMAGES.ALTIUS} className="h-fit w-fit" />
            </div>
          </Carousel.Item>
          <Carousel.Item interval={1500}>
            <div className="flex items-center justify-center h-[150px]">
              <img src={IMAGES.CROWELL} className="h-fit w-fit" />
            </div>
          </Carousel.Item>
          <Carousel.Item interval={1500}>
            <div className="flex items-center justify-center h-[150px]">
              <img src={IMAGES.AKD} className="h-fit w-fit" />
            </div>
          </Carousel.Item>
          <Carousel.Item interval={1500}>
            <div className="flex items-center justify-center h-[150px]">
              <img src={IMAGES.VBB} className="h-fit w-fit" />
            </div>
          </Carousel.Item>
        </Carousel>
      </section>
      {/* lawyers section */}
      <section
        name="For Candidate"
        className="flex flex-col items-center px-5 mt-3 gap-x-4 gap-y-3 w-full"
      >
        <div className="flex items-center">
          <div className="hidden lg:inline-block pl-5 ">
            <img src={IMAGES.L_SECTION_3} className="ml-5 h-[540px]" />
          </div>
          <div className=" w-full lg:w-auto h-fit lg:h-[600px] pt-3">
            <h1 className="text-4xl font-semibold">How it works for Lawyers</h1>

            <div className="flex gap-x-4 my-3">
              <p className="font-semibold text-xl italic w-fit ">
                <span className="px-2 py-1">1.</span>
              </p>
              <div>
                <p className="w-full md:w-50 font-semibold text-xl">
                  Create your profile
                </p>
                <p className="w-full md:w-[65%]">Lawyers sign up for free</p>
              </div>
            </div>
            <div className="flex gap-x-4 my-3">
              <p className="font-semibold text-xl italic w-fit">
                <span className="bg-[#868BFF] rounded-full px-2 py-1 text-black text-center">
                  2.
                </span>
              </p>
              <div>
                <p className="w-full md:w-50 font-semibold text-xl">
                  Match with top Law Firms
                </p>
                <p className="w-full md:w-[65%]">
                  Our sophisticated AI analyses your profile and only matches
                  you with relevant job opportunities.
                </p>
              </div>
            </div>
            <div className="flex gap-x-4 my-3">
              <p className="font-semibold text-xl italic w-fit ">
                <span className="px-2 py-1">3.</span>
              </p>
              <div>
                <p className="w-full md:w-50 font-semibold text-xl">
                  Apply to your dream job
                </p>
                <p className="w-full md:w-[65%]">
                  You can wait for law firms to contact you or you can apply
                  directly, the choice is yours!{" "}
                </p>
              </div>
            </div>
            <div className="flex gap-x-4 my-3">
              <p className="font-semibold text-xl italic w-fit ">
                <span className="px-2 py-1">4.</span>
              </p>
              <div>
                <p className="w-full md:w-50 font-semibold text-xl">
                  Schedule an interview
                </p>
                <p className="w-full md:w-[65%]">
                  Once matched, the law firm will contact you to schedule an
                  interview.
                </p>
              </div>
            </div>
            <button
              className="flex items-center w-fit bg-[#46d6ea] border-1 border-[#46d6ea] text-black text-center px-4 py-1 rounded-full mt-4"
              onClick={() => navigate("auth/register")}
            >
              Sign up now
            </button>
          </div>
        </div>
        <div className="flex flex-col md:flex-row w-full lg:w-75 items-center justify-between my-3">
          <div className="w-full lg:w-50 mb-3">
            <h1 className="text-2xl font-semibold italic">
              Not{" "}
              <span className="text-[#868BFF] not-italic">
                Actively Looking?{" "}
              </span>
            </h1>
            <p>
              No problem! Thanks to our incognito mode you can be kept informed
              of the interesting opportunities and wait for the right time to
              apply.
            </p>
          </div>
          <div className="flex w-full lg:w-50 items-center justify-center">
            <div className="flex items-center justify-between border-1 border-white rounded-full w-[180px] md:w-[260px]">
              <img
                src={IMAGES.U_ICON}
                className="rounded-full border-1 border-white w-[75px] h-[75px] md:w-[100px] md:h-[100px]"
              />
              <img
                src={IMAGES.L_LADY}
                className="rounded-full  w-[75px] h-[75px] md:w-[100px] md:h-[100px]"
              />
            </div>
          </div>
        </div>
      </section>
      {/* download section */}
      <section className="flex flex-col items-center mt-3 bg-[#182637] py-5  w-full">
        <div className="flex flex-col justify-center  text-center">
          <h1 className="w-full text-4xl font-semibold">
            Download JustLawyers for mobile{" "}
          </h1>
          <p className="mt-2 text-sm">
            Apply to you dream job with the ease of a swipe
          </p>
        </div>
        <div className=" my-3">
          <div className="flex flex-col md:flex-row items-center justify-center gap-3  mb-2">
            <img src={IMAGES.APP_STORE} />
            <img src={IMAGES.G_PLAY} />
          </div>
        </div>
      </section>
      {/* firms section */}
      <section
        name="For Law Firm"
        className="flex items-center justify-center bg-[#364A63] py-5 px-0 md:p-5 w-full"
      >
        <div className="px-0 md:px-5 w-75">
          <h1 className="text-4xl italic">Are you a Law Firm?</h1>
          <p className="mt-2">
            Do you want to up your recruiting game? See our subscription offer
            and become part of Just lawyers!
          </p>

          <button
            className="flex items-center w-fit bg-[#FFC83A] border-1 border-[#FFC83A] text-black text-center px-4 py-1 mt-5 rounded-full"
            // onClick={() => navigate("auth/register")}
          >
            Learn More
          </button>
        </div>
      </section>
      {/* stories  section */}
      <section className="flex flex-col items-center px-2 md:px-5 py-5 w-full">
        <h2 className="w-75 text-xl font-semibold text-center ">
          See our success stories{" "}
        </h2>
        <div className="flex justify-center mt-3">
          <Carousel
            className=" mt-3 w-full md:w-[75%] "
            indicators={false}
            controls={false}
          >
            <Carousel.Item interval={2500} className="px-3 ">
              <LandingPageCard
                color={"#868BFF"}
                quote={"“Just Lawyers is the best”"}
              />
            </Carousel.Item>

            <Carousel.Item interval={2500} className="px-3 ">
              <LandingPageCard
                color={"#FFC83A"}
                quote={"“Just Lawyers is the best”"}
              />
            </Carousel.Item>

            <Carousel.Item interval={2500} className="px-3 ">
              <LandingPageCard
                color={"#46D6EA"}
                quote={"“Just Lawyers is the best”"}
              />
            </Carousel.Item>
          </Carousel>
        </div>
      </section>
      {/* Q&A section */}
      <section
        name="FAQ"
        className="flex flex-col items-center mt-3 bg-[#364A63] py-5 px-0 md:p-5 w-full"
      >
        <h2 className="text-2xl font-semibold">FAQs</h2>
        <Accordion
          //   defaultActiveKey="0"
          className="gap-y-2 bg-transparent w-[80%] lg:w-[60%] mt-3"
        >
          <Accordion.Item eventKey="0" className="my-2">
            <Accordion.Header className="text-[#131F2E] font-semibold ">
              Is JL free for lawyers?
            </Accordion.Header>
            <Accordion.Body>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
              enim ad minim veniam, quis nostrud exercitation ullamco laboris
              nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in
              reprehenderit in voluptate velit esse cillum dolore eu fugiat
              nulla pariatur. Excepteur sint occaecat cupidatat non proident,
              sunt in culpa qui officia deserunt mollit anim id est laborum.
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="1" className="my-2">
            <Accordion.Header className="text-[#131F2E] font-semibold ">
              Are all law firms on JL?
            </Accordion.Header>
            <Accordion.Body>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
              enim ad minim veniam, quis nostrud exercitation ullamco laboris
              nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in
              reprehenderit in voluptate velit esse cillum dolore eu fugiat
              nulla pariatur. Excepteur sint occaecat cupidatat non proident,
              sunt in culpa qui officia deserunt mollit anim id est laborum.
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="2" className="my-2">
            <Accordion.Header className="text-[#131F2E] font-semibold ">
              Are all law firms on JL?
            </Accordion.Header>
            <Accordion.Body>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
              enim ad minim veniam, quis nostrud exercitation ullamco laboris
              nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in
              reprehenderit in voluptate velit esse cillum dolore eu fugiat
              nulla pariatur. Excepteur sint occaecat cupidatat non proident,
              sunt in culpa qui officia deserunt mollit anim id est laborum.
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="3" className="my-2">
            <Accordion.Header className="text-[#131F2E] font-semibold ">
              DoesJL respect GDPR?{" "}
            </Accordion.Header>
            <Accordion.Body>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
              enim ad minim veniam, quis nostrud exercitation ullamco laboris
              nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in
              reprehenderit in voluptate velit esse cillum dolore eu fugiat
              nulla pariatur. Excepteur sint occaecat cupidatat non proident,
              sunt in culpa qui officia deserunt mollit anim id est laborum.
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="4" className="my-2">
            <Accordion.Header className="text-[#131F2E] font-semibold ">
              Is JL free for lawyers?
            </Accordion.Header>
            <Accordion.Body>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
              enim ad minim veniam, quis nostrud exercitation ullamco laboris
              nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in
              reprehenderit in voluptate velit esse cillum dolore eu fugiat
              nulla pariatur. Excepteur sint occaecat cupidatat non proident,
              sunt in culpa qui officia deserunt mollit anim id est laborum.
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      </section>
      {/* <OurMission /> */}
      {/* footer */}
      <Footer />
    </div>
  )
}

export default LandingPage1
