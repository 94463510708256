import React, { useState } from "react"
import styles from "./CandidateData.module.css"
import { Modal, Button } from "react-bootstrap"
import { useNavigate, useLocation } from "react-router-dom"

import { useDispatch, useSelector } from "react-redux"
import { updateInitialData } from "../../app/initialDataSlice"
import { saveData } from "../../app/dataSlice.js"
import { editUser } from "../../app/functions.js"

import { expertiseOptions } from "../../constants/Data.js"
import CreatableSelect from "react-select/creatable"

const CandidateExpertiseModal = ({ show, handleClose }) => {
  const { state } = useLocation()
  const loggedUser = useSelector((state) => state.data.value)
  const initialData = useSelector((state) => state.initialData)
  const dispatch = useDispatch()

  const handleSelectionExpertise = (expertiseOptions) => {
    const arrayOfLabels = expertiseOptions.map((obj) => obj.label)
    dispatch(updateInitialData({ expertise: arrayOfLabels }))
  }

  const save = async () => {
    const data = initialData
    const uid = initialData.uid
    saveData(uid, data)

    await editUser(data, dispatch, saveData)
    handleClose()
  }

  return (
    <Modal show={show} onHide={handleClose} centered>
      <Modal.Header>
        <Modal.Title>Expertise update</Modal.Title>
      </Modal.Header>
      <Modal.Body className={styles.modalbod}>
        <div className={styles.innercontainer}>
          <div className="w-full ">
            <p className="font-bold">Expertise</p>
            <CreatableSelect
              isClearable
              placeholder="Select Type..."
              defaultValue={[]}
              isMulti
              name="type"
              options={expertiseOptions}
              className="basic-multi-select"
              classNamePrefix="select"
              onChange={handleSelectionExpertise}
              value={initialData.expertise?.map((exp) => ({
                label: exp,
                value: exp,
              }))}
            />
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer className="flex items-center justify-between">
        <Button
          variant=""
          className="border-[1px] border-black"
          onClick={handleClose}
        >
          Close
        </Button>
        <Button variant="" className="bg-[#46D6EA] font-bold" onClick={save}>
          Save Changes
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

export default CandidateExpertiseModal
