import { createSlice } from "@reduxjs/toolkit"
import { initialise, login, logout } from "./userRedux/userSlice"

const initialState = {
  uid: null,
  email: "",
  name: "",
  surname: "",
  location: "",
  searchingStatus: "",
  profileImg: "",
  selectedLanguages: [],
  selectedBonusLanguages: [],
  queryLoca: "",
  queryFirmType: "",
  queryExpertise: "",
  queryAnnual: "",
  queryNotMatching: "",
  sliderValue: "",
  cities: [],
  annualRemuneration: "",
  annualRemunerationMin: "",
  annualRemunerationMax: "",
  expertise: [],
  grades: "",
  dateOfGradutation: "",
  university: [],
  master: [],
  cv: "",
  coverLetter: "",
  transcripts: "",
  employmentHistory: [
    {
      lawfirmname: "",
      positiontitle: "",
      employmentend: "",
      employmentstart: "",
      remuneration: "",
      stillEmployed: false,
      notOnTheList: false,
    },
  ],
  tomatch: [],
  nottomatch: [],
  description: "",
  experience: "",
  applied: [],
  matches: [],
  saved: [],
  rejected: [],

  // Here data unique to recruiters:
  companyName: "",
  role: "",
  phone: "",
  firmLogo: "",
  firmFounded: "",
  employeesList: [
    {
      employeeFullName: "",
      employeeTitle: "",
      employeeEmail: "",
    },
  ],
  aboutCompany: "",
  aboutEmployee: "",
  firmLocation: "",
  selectedLocations: [],
  firmWebsite: "",
  firmSize: "",

  companyFounded: "",
  jobTitle: "",
  jobCities: [],
  jobBudget: "",
  minJobBudget: "",
  maxJobBudget: "",
  jobDescription: "",
  jobAdditionalDescription: "",
  jobExperienceMin: 0,
  jobExperienceMax: 7,
  jobExpertise: [],
  jobLanguages: [],
  jobBonusLanguages: [],
  cvName: "",
  clName: "",

  // Below are the previewed items

  selectedJob: {},
}

const initialDataSlice = createSlice({
  name: "initialData",
  initialState,
  reducers: {
    updateInitialData: (state, action) => {
      // Assuming action.payload is an object containing updated data
      return { ...state, ...action.payload }
    },
    resetInitialData: (state) => {
      return initialState // Reset state to initial state
    },
  },
  extraReducers: (builder) =>
    builder
      .addCase(initialise, (state, action) => {
        return action.payload ? { ...state, ...action.payload.userData } : state
      })
      .addCase(login, (state, action) => ({
        ...state,
        ...action.payload.userData,
      }))
      .addCase(logout, () => initialState),
})

export const {
  updateInitialData,
  resetInitialData,
} = initialDataSlice.actions
export default initialDataSlice.reducer
