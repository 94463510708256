import React, { useState } from "react"
import { useLocation, useNavigate } from "react-router-dom"
import { IMAGES } from "../../constants/images.jsx"
import styles from "./CandidateSignupVerify.module.css"

const CandidateSignupVerify = () => {
  const [verified, setVerified] = useState(false)
  const [nextStep, setNextStep] = useState(false)
  const { state } = useLocation()
  const { uid, password, email } = state // Read values passed on state

  const navigate = useNavigate()

  const nextWindow = () => {
    setVerified(!verified)
  }

  const resendEmail = () => {
    // TODO finish this part
    alert("Email resent!")
  }

  const handelContinue = () => {
    console.log("go to filling info")
    setNextStep(!nextStep)
  }

  const handleCompleteProfile = () => {
    navigate("/candidatedata0", {
      state: {
        uid,
        email,
        password,
      },
    })
  }

  const handelStartLooking = () => {
    navigate("/candidatedata4", {
      state: {
        uid,
        email,
        password,
      },
    })
  }
  return (
    <div className="flex justify-center items-center h-lvh py-[100px] text-black overflow-y-auto">
      {!verified ? (
        <>
          <div
            className={styles.boxcontainer}
          >
            <div className="flex flex-column items-center w-full my-3">
              <div className="text-center">
                <h1 className="font-bold text-3xl">Please verify your email</h1>
              </div>
              <div className="text-center my-3">
                <p>
                  You are almost there. We sent an email to <br />{" "}
                  <span>{state.email}</span>
                </p>
                <p className="my-3">
                  Please check you inbox and click on the link in
                  <br /> that email to complete your signup.{" "}
                </p>
                <p>Can’t find the email?</p>
              </div>
              <div className="text-center my-3">
                <button
                  className="bg-[#46D6EA]  py-2 px-5 rounded-md font-bold border-1 border-[#46D6EA] cursor-pointer hover:bg-transparent"
                  onClick={() => nextWindow()}
                >
                  Resend email
                </button>
              </div>
            </div>
          </div>
        </>
      ) : nextStep ? (
        <>
          <div
            className={styles.boxcontainer}
          >
            <div className="flex flex-column items-center w-full my-3">
              <h1 className="text-5xl font-bold">Next Steps</h1>
              <p className="my-3 w-75">
                It's now time to complete your profile to start the matching
                process or start browsing oportunities.{" "}
              </p>
              <button
                className="bg-[#46D6EA]  py-2 rounded-md font-bold  w-[60%] border-1 border-[#46D6EA] cursor-pointer hover:bg-transparent"
                onClick={() => handleCompleteProfile()}
              >
                Complete your profile{" "}
              </button>
              <button
                className="bg-white  py-2 my-3 rounded-md font-bold w-[60%]  border-1 border-[#46D6EA] cursor-pointer hover:bg-[#46d5ea]"
                onClick={() => handelStartLooking()}
              >
                Start looking{" "}
              </button>
            </div>
          </div>
        </>
      ) : (
        <>
          <div
            className={styles.boxcontainer}
          >
            <div className="mt-3">
              <img src={IMAGES.ENVELOPE} className="w-30 h-30" />
            </div>
            <div className="flex flex-column items-center w-[80%] my-3">
              <h1 className="text-5xl font-bold">Verified!</h1>
              <p className="my-3 w-75">
                It's now time to complete your profile to start the matching
                process!
              </p>
              <button
                className="bg-[#46D6EA] py-2 rounded-md font-bold  w-[60%] border-1 border-[#46D6EA] cursor-pointer hover:bg-transparent"
                onClick={() => handelContinue()}
              >
                Click to Continue
              </button>
            </div>
          </div>
        </>
      )}
    </div>
  )
}

export default CandidateSignupVerify
