import { collection } from "firebase/firestore"
import { useMemo } from "react"
import { useCollection } from "react-firebase-hooks/firestore"
import { db } from "../config/firebase"

const useFetchCandidates = () => {
  const [candidatesDocuments, candidatesAreLoading] = useCollection(
    collection(db, "users"),
  )

  const parsedCandidates = useMemo(
    () =>
      candidatesDocuments?.docs.map((candidateDocument) => ({
        id: candidateDocument.id,
        ...candidateDocument.data(),
      })),
    [candidatesDocuments],
  )

  return { candidates: parsedCandidates, candidatesAreLoading }
}

export default useFetchCandidates
