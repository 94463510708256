import React, { useState, useEffect } from "react"
import NavbarRec from "../../utils/NavBarRec"
import { CiLocationOn } from "react-icons/ci"
import { PiSuitcaseLight } from "react-icons/pi"
import { MdOutlineMenu } from "react-icons/md"
import { MdRemoveRedEye } from "react-icons/md"

import { Tab, Tabs, Button } from "react-bootstrap"
import RecruiterMatchModal from "../../components/RecruiterMatchModal.jsx"
import CandidateCard from "../../components/CandidateCard.jsx"
import { useSelector } from "react-redux"

import { useNavigate, useLocation, useParams } from "react-router-dom"
import useFetchJob from "../../hooks/useFetchJob.js"
import {
  collection,
  query,
  where
} from "firebase/firestore"
import { db } from "../../config/firebase"
import { useCollectionData } from "react-firebase-hooks/firestore"
import useFetchMyApplications from "../../hooks/useFetchMyApplications"
import { fetchSavedCandidates } from "../../app/functions.js"

const JobPage = () => {
  const { id } = useParams()

  const { state } = useLocation()
  const { defaultTab } = state
  const [key, setKey] = useState("home")
  const [showModal, setShowModal] = useState(false)
  const [applicantsJobsData, setApplicantsJobsData] = useState([])
  const [matchesJobData, setMatchesJobData] = useState([])
  const [likedCandidatesJobData, setLikedCandidatesJobData] = useState([])
  const [loading, setLoading] = useState(false)
  const [showMatchModal, setShowMatchModal] = useState(false)


  const [potentialCandidates, loadingPotentialCandidates] = useCollectionData(
    query(
      collection(db, "users"),
      // where(id, 'not-in', "rejected")
    ),
  )
  const { applications, loadingApplications } = useFetchMyApplications(id)
  const { matchesApplications, loadingMatchesApplications } = useFetchMyApplications(id, "matches")

  const userData = useSelector((state) => state.user.userData)
  


  const handleShowMatchModal = () => {
    setShowMatchModal(true)
  }

  const handleCloseMatchModal = () => {
    setShowMatchModal(false)
  }

  const [activeTab, setActiveTab] = useState(defaultTab)

  const { job, jobIsLoading } = useFetchJob(id)

  const tabClass = (tabKey) => {
    return tabKey === activeTab
      ? " text-white border-b-[2px] border-[#FFC83A] pb-3 font-bold"
      : ""
  }
  const handleTabSelect = (key) => {
    setActiveTab(key)
  }

  const handleClose = () => setShowModal(false)
  const handleShow = () => setShowModal(true)
  const navigate = useNavigate()

  useEffect( () => {
    getLikedCandidates()
  }, []);

  const getLikedCandidates = async ()=> {
    let data = await fetchSavedCandidates(userData.uid, "saved")
    setLikedCandidatesJobData(data)
  }

  const handelJobOverview = () => {
    navigate(`/joboverview/${job.uid}`)
  }

  const handleReturn = () => {
    navigate("/JobBoardRecruiter")
  }

  

  return (
    <>
      <NavbarRec className="z-10" />

      <div
        className="flex flex-column items-center h-screen text-white overflow-y-auto relative z-0"
        style={{
          height: "calc(100vh - 62px)", // Adjust the height based on your navbar height
        }}
      >
        <div className="flex flex-column items-center justify-center w-full ">
          <div className="flex items-center justify-center w-full bg-[#374B64]/[30%] h-fit md:h-[235px] relative">
            <div className="flex items-center w-[65%] py-4 justify-center absolute top-10">
              <div className="flex flex-col md:flex-row items-center w-full gap-y-2">
                <div className="flex w-full">
                  <div className="flex items-center md:items-start flex-column w-full md:w-[60%]">
                    <div className="flex items-center gap-x-3">
                      <p className="font-bold text-lg">{job?.jobTitle}</p>
                      <p className="flex flex-col md:flex-row pl-2 items-center text-center text-xs">
                        <MdRemoveRedEye className="mr-0 md:mr-2" />
                        56 Views
                      </p>
                    </div>
                    <div>
                      <p className="text-xs my-1">Posted 15 days ago</p>
                      <Button
                        className="flex items-center text-[#1F2F42] bg-[#46D6EA] px-3 py-1 mt-1"
                        onClick={handelJobOverview}
                      >
                        Preview <MdRemoveRedEye className="ml-2" />
                      </Button>
                    </div>
                  </div>
                </div>
                <div className=" flex flex-column w-full md:w-[40%] border-none md:border-l-[1px] md:border-gray-300 pl-5">
                  <p className="flex flex-col md:flex-row items-center">
                    <CiLocationOn className="mr-2" />
                    {job?.jobCities?.map((city, index) => (
                      <span key={index} className="text-sm">
                        {index > 0 && " / "} {city}
                      </span>
                    ))}
                  </p>
                  <p className="flex flex-col md:flex-row items-center">
                    <PiSuitcaseLight className="mr-2" />
                    {/* {initialData.jobViewed.jobExperience} years */}
                    <span className="text-sm">
                      {job?.jobExperienceMin} - {job?.jobExperienceMax} years
                    </span>
                  </p>
                  <p className="flex flex-col md:flex-row items-center">
                    <MdOutlineMenu className="mr-2" />
                    <span className="text-sm">
                      {job?.jobExpertise?.join(" / ")}
                    </span>
                  </p>
                </div>
              </div>
            </div>
          </div>
          {/* search bar */}

          <div className="mt-4 w-[65%] absolute top-[280px]  md:top-[163px] ">
            <Tabs
              defaultActiveKey={defaultTab}
              id="noanim-tab-example"
              // fill
              variant=""
              className="mb-3 border-none w-full md:w-[120%] flex-col md:flex-row "
              onSelect={handleTabSelect}
            >
              {/* potential Matches */}
              <Tab
                eventKey="Potential"
                // title="Potential Matches "
                title={
                  <span className={`pt-4 text-white ${tabClass("Potential")}`}>
                    Potential Candidates
                  </span>
                }
              >
                <div className="flex flex-column mt-2">
                  {/* candidates parts */}
                  <div className="mt-3">
                    <div className="mb-4">
                      <p>
                        <span className="font-bold">
                          {potentialCandidates?.length} candidates
                        </span>{" "}
                        match your criteria
                        {/* This feature will be implemented 30/02/2024 */}
                      </p>
                    </div>
                    {/* candidates cards container */}
                    <div className="grid grid-cols-1 lg:grid-cols-2 gap-x-5 text-black relative">
                      {/* candidate card */}
                      {potentialCandidates?.map((candidate) => (
                        <CandidateCard
                          key={candidate.uid}
                          data={candidate}
                          job={job}
                          origin={"potential"}
                        />
                      ))}
                    </div>
                  </div>
                </div>
              </Tab>
              {/* Applicants */}
              <Tab
                eventKey="Applicants"
                // title="Applicants"
                title={
                  <span className={`pt-4 text-white ${tabClass("Applicants")}`}>
                    Applicants
                  </span>
                }
              >
                <div className="flex flex-column mt-2">
                  {/* candidates parts */}
                  <div className="mt-3">
                    <div className="mb-4">
                      <p>
                        <span className="font-bold">
                          {applications?.length} candidates
                        </span>{" "}
                        match your criteria
                        {/* This functionality will be implemented 28/02/2024 */}
                      </p>
                    </div>
                    {/* candidates cards container */}
                    <div className="grid grid-cols-1 lg:grid-cols-2 gap-x-5 text-black relative">
                      {/* candidate card */}
                      {applications?.map((candidate) => (
                        <CandidateCard
                          key={candidate.uid}
                          data={candidate}
                          job={job}
                          origin={"applicants"}
                        />
                      ))}
                    </div>
                  </div>
                </div>
              </Tab>
              {/* Liked Candidates */}
              <Tab
                eventKey="Liked"
                // title="Liked candidates "
                title={
                  <span className={`pt-4 text-white ${tabClass("Liked")}`}>
                    Liked candidates
                  </span>
                }
              >
                <div className="flex flex-column mt-2">
                  {/* candidates parts */}
                  <div className="mt-3">
                    <div className="mb-4">
                      <p>
                        <span className="font-bold">
                          {likedCandidatesJobData?.length} candidates
                        </span>{" "}
                        match your criteria
                        {/* This functionality will be implemented 28/02/2024 */}
                      </p>
                    </div>
                    {/* candidates cards container */}
                    <div className="grid grid-cols-1 lg:grid-cols-2 gap-x-5 text-black relative">
                      {/* candidate card */}
                      {likedCandidatesJobData?.map((candidate) => (
                        <CandidateCard
                          key={candidate.uid}
                          data={candidate}
                          job={job}
                          origin={"liked"}
                        />
                      ))}
                    </div>
                  </div>
                </div>
              </Tab>
              {/* matches */}
              <Tab
                eventKey="Matches"
                // title="Matches"
                title={
                  <span className={`pt-4 text-white ${tabClass("Matches")}`}>
                    Matches
                  </span>
                }
              >
                <div className="flex flex-column mt-2 relative">
                  {/* candidates parts */}
                  <div className="mt-3">
                    <div className="mb-4">
                      <p>
                        <span className="font-bold">
                          {matchesApplications?.length} candidates
                        </span>{" "}
                        match your criteria
                      </p>
                    </div>
                    {/* candidates cards container */}
                    <div className="grid grid-cols-1 lg:grid-cols-2 gap-x-5 text-black relative gap-y-5">
                      {/* candidate card */}
                      {matchesApplications?.map((candidate) => (
                        <CandidateCard
                          key={candidate.uid}
                          data={candidate}
                          job={job}
                          origin={"matches"}
                        />
                      ))}
                    </div>
                  </div>
                </div>
              </Tab>
            </Tabs>
          </div>
        </div>
      </div>
      <RecruiterMatchModal
        show={showMatchModal}
        handleClose={handleCloseMatchModal}
      />
    </>
  )
}

export default JobPage
