import ProptTypes from "prop-types"
import { useSelector } from "react-redux"
import { Navigate } from "react-router-dom"
import { isAuthenticated, isCandidate } from "../../app/userRedux/userSlice"

const GuestGuard = ({ children }) => {
  const userIsAuthenticated = useSelector(isAuthenticated)
  const userIsCandidate = useSelector(isCandidate)

  if (userIsAuthenticated) {
    if (userIsCandidate) {
      return <Navigate to="/jobboard" replace />
    }

    return <Navigate to="/HomeRecruiter" replace />
  }

  return children
}

GuestGuard.propTypes = {
  children: ProptTypes.node,
}

export default GuestGuard
