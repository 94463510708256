import "bootstrap/dist/css/bootstrap.min.css"
import React, { useEffect, useState } from "react"
import ProgressBar from "react-bootstrap/ProgressBar"
import { useForm } from "react-hook-form"
import { BiMessage } from "react-icons/bi"
import { CiLocationOn } from "react-icons/ci"
import { MdOutlineMenu } from "react-icons/md"
import { PiSuitcaseLight } from "react-icons/pi"
import { useSelector } from "react-redux"
import { fetchJobs } from "../../app/functions.js"
import User from "../../assets/example_images/harvey.jpg"
import CandidateJobCardHorizontal from "../../components/CandidateJobCardHorizontal.jsx"
import { IMAGES } from "../../constants/images.jsx"
import Navbar from "../../utils/NavBar"
import "./HomeCandidate.module.css"
import TinderCard from "react-tinder-card"

const customStyles = {
  control: (provided) => ({
    ...provided,
    backgroundColor: "transparent",
    borderRight: "none",
    border: "none",
  }),
  singleValue: (provided) => ({
    ...provided,
    color: "white", // Change text color here
  }),
  placeholder: (provided) => ({
    ...provided,
    color: "white", // Change placeholder color here
  }),
}

const HomeCandidate = () => {
  const [showModal, setShowModal] = useState(false)
  const [jobsData, setJobsData] = useState([])
  const [loading, setLoading] = useState(false)
  const handleShowModal = () => {
    setShowModal(true)
  }
  const {
    control,
    handleSubmit,
    formState: { errors },
    getValues,
  } = useForm()

  const handleCloseModal = () => {
    setShowModal(false)
  }

  const user = useSelector((state) => state.data.value)
  console.log(user)

  useEffect(() => {
    getJobs()
  }, [])

  const getJobs = async () => {
    //TODO this query will have to fetch a

    setLoading(true)
    let data = await fetchJobs(user)
    setJobsData(data)
    setLoading(false)
  }

  const now = 20
  const [swipedCards, setSwipedCards] = useState([])
  // const onSwipe = (direction) => {
  //   console.log("You swiped: " + direction)
  // }
  const onSwipe = (direction) => {
    switch (direction) {
      case "left":
        console.log("swiped to left")
        break
      case "right":
        console.log("swiped to right")
        break
      case "up":
        console.log("swiped to up")
        // Call the goToJob function when swiped up

        break
      case "down":
        console.log("swiped to down")
        break
      default:
        console.log("Invalid swipe direction")
    }
  }

  const onCardLeftScreen = (index) => {
    console.log(index + " left the screen")
    setSwipedCards([...swipedCards, index])
  }
  const filteredCards = jobsData
    ?.slice(0, 5)
    .filter((_, index) => !swipedCards.includes(index))

  return (
    <>
      <Navbar />
      <div className="flex justify-center text-white overflow-y-auto">
        <div className="flex items-center justify-center my-10 w-screen">
          <div className="flex flex-col lg:flex-row justify-between w-[90%] lg:w-[70%] h-full">
            {/* Leftside section */}
            <section className="flex flex-col w-full lg:w-[35%] px-1 lg:px-0 items-center mt-5">
              {/* upper div */}
              <div className="flex flex-col items-center bg-[#364A63] w-full mb-5 py-3 rounded-lg">
                <div className="mt-2">
                  {user?.profileImg ? (
                    <>
                      <img
                        src={user.profileImg}
                        alt="user image"
                        className="rounded-full border-solid border-2 border-[#B3ffA7] w-[160px] h-[160px]"
                      />
                    </>
                  ) : (
                    <>
                      <img
                        src={User}
                        alt="user image"
                        className="rounded-full border-solid border-2 border-[#B3ffA7] w-[160px] h-[160px]"
                      />
                    </>
                  )}
                </div>
                <div className="flex flex-col items-center my-2 ">
                  <p>
                    {user?.name} {user?.surname}
                  </p>
                  <div className="flex flex-col lg:flex-row items-center mt-2 ">
                    <CiLocationOn size={24} className="mr-1" />
                    {/* <img src={IMAGES.LOCATION} className="w-5 mr-2" /> */}

                    {user?.location ? (
                      <>
                        <p>{user?.location}</p>
                      </>
                    ) : (
                      <>
                        <p>No location added</p>
                      </>
                    )}
                  </div>
                </div>
                <div className="border-solid border-b-[1px] border-white w-[75%] my-3 "></div>
                <div>
                  <div className="flex flex-col items-center lg:items-start px-5">
                    <div className="flex flex-col lg:flex-row items-center mt-2 ">
                      <PiSuitcaseLight size={24} className="mr-1" />
                      {/* <img src={IMAGES.SUITCASE} className="w-5 mr-2" /> */}
                      {user?.experience ? (
                        <>
                          <p>{user?.experience} of experience</p>
                        </>
                      ) : (
                        <>
                          <p>No experience</p>
                        </>
                      )}
                    </div>
                    <div className="flex flex-col lg:flex-row items-center mt-2 ">
                      <BiMessage size={24} className="mr-1" />
                      {/* <img src={IMAGES.MESSAGE} className="w-5 mr-2" /> */}
                      {user?.selectedLanguages ? 
                      (
                        user?.selectedLanguages[0] && 
                        <>{user?.selectedLanguages.join(" | ")}</>
                      ) : (
                        <>
                          <p>No language is added</p>
                        </>
                      )}
                    </div>
                    <div className="flex flex-col lg:flex-row items-center my-2">
                      <MdOutlineMenu size={24} className="mr-1" />
                      {/* <img src={IMAGES.BUGERMENU} className="w-5 mr-2" /> */}
                      {user?.expertise ? 
                      (
                        user?.expertise[0] && 
                        <>{user?.expertise?.join(" | ")}</>
                      ) : (
                        <>
                          <p>No expertise is added</p>
                        </>
                      )}
                    </div>
                  </div>
                </div>
                <div className="flex flex-col items-center my-5">
                  <h2 className="font-semibold mb-3">About Me</h2>
                  <p className="w-[75%] text-start text-sm mt-2">
                    {user?.description ? (
                      <>{user?.description}</>
                    ) : (
                      <>
                        <p>No description is added</p>
                      </>
                    )}
                  </p>
                </div>
              </div>
              {/* lower div */}
              <div className="hidden lg:flex flex-col items-center bg-[#364A63] w-full py-3 rounded-lg">
                <div className="text-[#FFC83A] font-bold w-[85%]">
                  <p>Profile Completion</p>
                  <ProgressBar
                    variant="warning"
                    now={now}
                    className="h-2 my-2"
                  />
                  <p>{now}%</p>
                </div>
                <div className="mt-2 w-[85%]">
                  <div className="flex flex-row justify-between">
                    <p>Add Motivation Letter</p>
                    <p>+ 10 %</p>
                  </div>
                  <div className="flex flex-row justify-between">
                    <p>Add Desired Jobs</p>
                    <p>+ 25 %</p>
                  </div>
                </div>
              </div>
            </section>
            {/* Rightside section */}
            <section className="flex flex-col w-full lg:w-[60%]  items-center">
              {/* upper div */}
              <div className="w-full px-3">
                <div>
                  <h2 className="justify-start mb-4 font-bold text-lg">
                    Search for a job
                  </h2>
                  <div className="text-center ">
                    <p className="font-bold">
                      Welcome to your personalized{" "}
                      <span className="text-[#868BFF]">matchmaking</span>{" "}
                      playground!{" "}
                    </p>
                    <p className="px-3 text-sm">
                      Explore potential opportunities crafted just for you. Let
                      the matching magic begin!
                    </p>
                  </div>
                </div>
              </div>
              {/* lower div */}
              <div className="w-full px-3 mt-3">
                <div>
                  <p>
                    {" "}
                    <span className="font-bold">{jobsData?.length}</span> match
                    your criteria
                  </p>
                </div>
                {/* cards div */}
                {/* <div className=" my-3 h-[600px] overflow-y-auto"> */}
                <div className="  my-3  overflow-y-auto">
                  {/* <JobCard /> */}

                  {jobsData?.slice(0, 5).map((job) => (
                    <CandidateJobCardHorizontal
                      key={job.id}
                      position={job.position}
                      data={job}
                    />
                  ))}
                </div>
                {/* <div className=" flex justify-center mt-3 px-2 md:hidden">
                  {filteredCards.map((job, index) => (
                    <TinderCard
                      onSwipe={onSwipe}
                      onCardLeftScreen={() => onCardLeftScreen(index)}
                      // preventSwipe={["right", "left"]}
                      className="absolute w-[259px]"
                    >
                      <CandidateJobCardHorizontal
                        key={job.id}
                        position={job.position}
                        data={job}
                      />
                    </TinderCard>
                  ))}
                </div> */}
              </div>
            </section>
          </div>
        </div>
      </div>
    </>
  )
}

export default HomeCandidate
