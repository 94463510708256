import { useState, useEffect } from "react";
import { db } from "../config/firebase";
import {
    collection,
    doc,
    onSnapshot,
    query,
    where,
} from "firebase/firestore";

const useCheckIfAnonymous = (employer_id, candidate_id) => {
    const [incognito, setIncognito] = useState(true);

    useEffect(() => {
        // Check if candidate_id is valid before querying Firestore
        if (!candidate_id) {
            setIncognito(true); // Set incognito to true if candidate_id is not valid
            return; // Exit early from useEffect
        }

        const unsubscribe = onSnapshot(
            query(
                collection(db, "conversations"),
                where("employer", "==", doc(db, "employers", employer_id)),
                where("candidate", "==", doc(db, "users", candidate_id))
            ),
            (snapshot) => {
                let hasIncognitoDocuments = false; // Flag to track if any incognito documents are found
                snapshot.docs.forEach((applicationDocument) => {
                    const data = applicationDocument.data();
                    if (data && data.incognito === false) {
                        hasIncognitoDocuments = true;
                    }
                });
                setIncognito(!hasIncognitoDocuments); // Update incognito state based on findings
            },
            (error) => {
                // Handle any errors here
                console.error("Error fetching conversations:", error);
            }
        );

        // Clean up the listener when component unmounts
        return () => unsubscribe();
    }, [employer_id, candidate_id]); // Add any dependencies here

    return { incognito };
};

export default useCheckIfAnonymous;
