import React, { useState } from "react"
import { Route, Routes, useNavigate } from "react-router-dom"
import { BarLoader } from "react-spinners"
import "./App.css"
import AuthenticatedGuard from "./components/routing/AuthenticatedGuard.jsx"
import GuestGuard from "./components/routing/GuestGuard.jsx"
import useInitAuth from "./hooks/useInitAuth.js"
import AdminLogin from "./pages/AdminLogin/AdminLogin.jsx"
import AuthenticationApp from "./pages/AuthenticationApp.jsx"
import CandidateData0 from "./pages/CandidateData/CandidateData0.jsx"
import CandidateData1 from "./pages/CandidateData/CandidateData1.jsx"
import CandidateData2 from "./pages/CandidateData/CandidateData2.jsx"
import CandidateData3 from "./pages/CandidateData/CandidateData3.jsx"
import CandidateData4 from "./pages/CandidateData/CandidateData4.jsx"
import CandidateData5 from "./pages/CandidateData/CandidateData5.jsx"
import CandidateData6 from "./pages/CandidateData/CandidateData6.jsx"
import CandidateJobDetailsPage from "./pages/CandidateJobDetailsPage/CandidateJobDetailsPage.jsx"
import CandidateMatches from "./pages/CandidateMatches/CandidateMatches.jsx"
import CandidateSignupVerify from "./pages/CandidateSignupVerify/CandidateSignupVerify.jsx"
import ChatRoom from "./pages/ChatRoom/ChatRoom.jsx"
import CreateJob from "./pages/CreateJob"
import Home from "./pages/Home/Home.jsx"
import HomeCandidate from "./pages/HomeCandidate/HomeCandidate.jsx"
import HomeRecruiter from "./pages/HomeRecruiter/HomeRecruiter.jsx"
import {
  default as JobBoard,
  default as SearchPage,
} from "./pages/JobBoard/JobBoard.jsx"
import JobBoardRecruiter from "./pages/JobBoardRecruiter/JobBoardRecruiter.jsx"
import JobPage from "./pages/JobBoardRecruiter/JobPage.jsx"
import JobOverview from "./pages/JobOverview/JobOverview.jsx"
import MyJob from "./pages/MyJob/MyJob.jsx"
import RecruiterCandidatesPage from "./pages/RecruiterCandidatesPage/RecruiterCandidatesPage.jsx"
import RecruiterProfile from "./pages/RecruiterProfile/RecruiterProfile.jsx"
import Register from "./pages/Register/Register.jsx"
import UserProfile from "./pages/UserProfile/UserProfile.jsx"
import Navbar from "./utils/NavBar.jsx"
import MessageNotificationModal from "./components/MessageNotificationModal.jsx"
import MatchModal from "./components/MatchModal.jsx"
import FirmLikeCandidateModal from "./components/FirmLikeCandidateModal.jsx"
import RecruiterMatchModal from "./components/RecruiterMatchModal.jsx"
import LandingPage1 from "./pages/LandingPage/LandingPage1.jsx"
import OurMission from "./pages/LandingPage/OurMission.jsx"
import RecruiterCandidatePagePreview from "./pages/RecruiterCandidatePagePreview/RecruiterCandidatePagePreview.jsx"
const App = () => {
  const [showMatchModal, setShowMatchModal] = useState(false)
  const [showMessageModal, setShowMessageModal] = useState(false)
  const [showFirmLikeModal, setShowFirmLikeModal] = useState(false)
  const [showRecruiterMatchModal, setShowRecruiterMatchModal] = useState(false)


  const handleCloseMatchModal = () => {
    setShowMatchModal(false)
  }

  const handleCloseMessageModal = () => {
    setShowMessageModal(false)
  }



  const handleCloseFirmLikeModal = () => {
    setShowFirmLikeModal(false)
  }


  const handleCloseRecruiterMatchModal = () => {
    setShowRecruiterMatchModal(false)
  }

  const navigate = useNavigate()
  const { initialisingAuth } = useInitAuth()

  if (initialisingAuth) {
    return <BarLoader color="#868bff" width="100%" />
  }

  return (
    <>
      <Routes>
        <Route path="auth/*" element={<AuthenticationApp />} />
        <Route
          path="/mymatches"
          element={
            <AuthenticatedGuard>
              <Navbar navigate={navigate} />
              <CandidateMatches />
            </AuthenticatedGuard>
          }
        />
        <Route
          path="/jobboard"
          element={
            <AuthenticatedGuard>
              <Navbar navigate={navigate} />
              <JobBoard />
            </AuthenticatedGuard>
          }
        />
        <Route
          path="/myjobs"
          element={
            <AuthenticatedGuard>
              <Navbar navigate={navigate} />
              <MyJob />
            </AuthenticatedGuard>
          }
        />
        <Route
          path="/search"
          element={
            <AuthenticatedGuard>
              <Navbar navigate={navigate} />
              <SearchPage />
            </AuthenticatedGuard>
          }
        />
        <Route
          path="/userprofile"
          element={
            <AuthenticatedGuard>
              <Navbar navigate={navigate} />
              <UserProfile />
            </AuthenticatedGuard>
          }
        />
        <Route
          index
          element={
            <GuestGuard>
              {/* <LandingPage /> */}
              <LandingPage1 />
            </GuestGuard>
          }
        />
        <Route
          path="/ourmission"
          element={
            <GuestGuard>
              {/* <LandingPage /> */}
              <OurMission />
            </GuestGuard>
          }
        />

        {/* Routes for admin  */}
        <Route path="/admin" element={<AdminLogin />} />

        <Route
          path="/candidateSignupVerify"
          element={
            <GuestGuard>
              <CandidateSignupVerify />
            </GuestGuard>
          }
        />
        <Route
          path="/candidatedata0"
          element={
            <GuestGuard>
              <CandidateData0 />
            </GuestGuard>
          }
        />
        <Route
          path="/candidatedata1"
          element={
            <GuestGuard>
              <CandidateData1 />
            </GuestGuard>
          }
        />
        <Route
          path="/candidatedata2"
          element={
            <GuestGuard>
              <CandidateData2 />
            </GuestGuard>
          }
        />
        <Route
          path="/candidatedata3"
          element={
            <GuestGuard>
              <CandidateData3 />
            </GuestGuard>
          }
        />
        <Route
          path="/candidatedata4"
          element={
            <GuestGuard>
              <CandidateData4 />
            </GuestGuard>
          }
        />
        <Route
          path="/candidatedata5"
          element={
            <GuestGuard>
              <CandidateData5 />
            </GuestGuard>
          }
        />
        <Route
          path="/candidatedata6"
          element={
            <GuestGuard>
              <CandidateData6 />
            </GuestGuard>
          }
        />
        <Route
          path="/candidatejobdetailspage/:jobUid"
          element={
            <AuthenticatedGuard>
              <CandidateJobDetailsPage />
            </AuthenticatedGuard>
          }
        />
        <Route
          path="/register"
          element={
            <GuestGuard>
              <Register />
            </GuestGuard>
          }
        />
        <Route
          path="/home"
          element={
            <AuthenticatedGuard>
              <Home />
            </AuthenticatedGuard>
          }
        />
        <Route
          path="/HomeCandidate"
          element={
            <AuthenticatedGuard>
              <HomeCandidate />
            </AuthenticatedGuard>
          }
        />
        <Route
          path="chatroom"
          element={
            <AuthenticatedGuard>
              <ChatRoom />
            </AuthenticatedGuard>
          }
        />
        <Route
          path="/HomeRecruiter"
          element={
            <AuthenticatedGuard>
              <HomeRecruiter />
            </AuthenticatedGuard>
          }
        />

        <Route
          path="/JobBoardRecruiter"
          element={
            <AuthenticatedGuard>
              <JobBoardRecruiter />
            </AuthenticatedGuard>
          }
        />
        <Route
          path="/createJob"
          element={
            <AuthenticatedGuard>
              <CreateJob />
            </AuthenticatedGuard>
          }
        />
        <Route
          path="/jobcardpage/:id"
          element={
            <AuthenticatedGuard>
              <JobPage />
            </AuthenticatedGuard>
          }
        />
        <Route
          path="/joboverview/:id"
          element={
            <AuthenticatedGuard>
              <JobOverview />
            </AuthenticatedGuard>
          }
        />
        <Route
          path="/RecruiterProfile"
          element={
            <AuthenticatedGuard>
              <RecruiterProfile />
            </AuthenticatedGuard>
          }
        />
        <Route
          path="/jobboard"
          element={
            <AuthenticatedGuard>
              <JobBoard />
            </AuthenticatedGuard>
          }
        />
        <Route
          path="/candidaterecruiter"
          element={
            <AuthenticatedGuard>
              <RecruiterCandidatesPage />
            </AuthenticatedGuard>
          }
        />
        <Route
          path="/recruitercandidatepagepreview/:candidateUid"
          element={
            <AuthenticatedGuard>
              <RecruiterCandidatePagePreview />
            </AuthenticatedGuard>
          }
        />
      </Routes>
      <MatchModal show={showMatchModal} handleClose={handleCloseMatchModal} />
      <MessageNotificationModal
        show={showMessageModal}
        handleClose={handleCloseMessageModal}
      />
      <FirmLikeCandidateModal
        show={showFirmLikeModal}
        handleClose={handleCloseFirmLikeModal}
      />
      <RecruiterMatchModal
        show={showRecruiterMatchModal}
        handleClose={handleCloseRecruiterMatchModal}
      />
    </>
  )
}

export default App
