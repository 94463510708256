import React from "react"
import { IMAGES } from "../constants/images.jsx"

const LandingPageCard = ({ color, quote }) => {
  return (
    <div
      className="bg-white text-black border-t-4 p-4  rounded-md w-fit"
      style={{ borderTopColor: color }}
    >
      <div className="flex flex-col md:flex-row items-center gap-x-2">
        <div>
          <img src={IMAGES.L_LADY} className="w-16 h-16 md:w-10 md:h-10" />
        </div>
        <div className="">
          <p className="font-semibold">Lauren Hensius Cortebeeck</p>
          <p className="text-[#808080] text-xs">
            Henry brothers Law Firm - Brussels, Belgium | 25 May 2024
          </p>
        </div>
      </div>
      <div className="mt-4">
        <h1 className="font-bold text-2xl my-3 italic">{quote}</h1>
        <p className="text-[#162333] mb-4">
          No problem! Thanks to our incognito mode you can be kept informed of
          the interesting opportunities and wait for the right time to apply.
          The first fully automated AI-driven matchmaking platform tailored for
          lawyers and law firms. Thanks to our cutting-edge algorithm, your top
          matches await!
        </p>
      </div>
    </div>
  )
}

export default LandingPageCard
