import {
  ChatContainer,
  MainContainer,
  Message,
  MessageInput,
  MessageList,
  MessageSeparator,
} from "@chatscope/chat-ui-kit-react"
import {
  Timestamp,
  collection,
  doc,
  query,
  setDoc,
  updateDoc,
} from "firebase/firestore"
import { useEffect, useState } from "react"
import { useCollectionData } from "react-firebase-hooks/firestore"
import { useSelector } from "react-redux"
import { DotLoader } from "react-spinners"
import { v4 as uuidv4 } from "uuid"
import { userSelector } from "../../app/userRedux/userSlice"
import { db } from "../../config/firebase"
import { IMAGES } from "../../constants/images.jsx"


function removeTrailingNbsp(message) {
  while (message.endsWith('&nbsp;')) {
      message = message.slice(0, -6); // Remove the last 6 characters (length of "&nbsp;")
      console.log("Removing trail")
  }
  return message;
}

const dateDisplay = (date) => {
  // Convert the timestamp string to a number and create a Date object
  return date.toLocaleTimeString([], {
    hour: "2-digit",
    minute: "2-digit",
  })
}

const Messages = ({ converstationId }) => {
  const user = useSelector(userSelector)
  const [messages, loadingMessages] = useCollectionData(
    query(collection(db, "conversations", converstationId, "messages")),
  )

  const [processedMessages, setProcessedMessages ]= useState([])

  useEffect(() => {
    const unreadMessages =
      messages?.filter(
        (message) => message.read === false && message.sentBy !== user.uid,
      ) ?? []

    unreadMessages.forEach((message) => {
      setProcessedMessages(messages => messages.concat(message.id))
      updateDoc(
        doc(db, "conversations", converstationId, "messages", message.id),
        {
          read: true,
        },
      )
    })
  }, [converstationId, user.uid, messages])

  const handleSendMessage = async (content) => {
    const message = {
      id: uuidv4(),
      date: new Date(),
      message: content,
      sentBy: user.uid,
      read: false,
    }

    await setDoc(
      doc(db, "conversations", converstationId, "messages", message.id),
      message,
    )

    await updateDoc(doc(db, "conversations", converstationId), {
      lastMessageAt: message.date,
      lastMessage: message.message,
    })
  }

  if (loadingMessages) {
    return <DotLoader color="#ffffff" size={20} />
  }

  const renderedMessages = []
  let lastDate = null



  messages
    .sort((a, b) => a.date.toDate() - b.date.toDate())
    .forEach((message) => {
      const currentDate =
        message.date instanceof Timestamp
          ? message.date.toDate()
          : new Date(message.date)

      if (!lastDate || currentDate.getDate() !== lastDate.getDate()) {
        // Add MessageSeparator if the date is different from the previous one
        renderedMessages.push(
          <MessageSeparator
            key={`separator-${currentDate.toDateString()}`}
            content={currentDate.toDateString()}
          />,
        )
        lastDate = currentDate
      }
      if(message.id === processedMessages[0]){
        renderedMessages.push(
          <MessageSeparator
            key={`separator-${currentDate.toDateString()}`}
            content={"New messages"}
          />,
        )
      }
      let messageDisplayed = removeTrailingNbsp(message.message)

      console.log(JSON.stringify(messageDisplayed))
      renderedMessages.push(
        <Message
          key={message.id}
          model={{
            message: messageDisplayed,
            sentTime: message.date.toDate().getTime().toString(),
            direction: message.sentBy === user.uid ? "outgoing" : "incoming",
            position: "single",
          }}
          className="text-right"
        >
          <Message.Footer>{dateDisplay(message.date.toDate())}</Message.Footer>
        </Message>,
      )
    })

    

  return (
    <MainContainer
      className="w-100"
      style={{
        background: "transparent",
      }}
    >
      <ChatContainer
        style={{
          background: "transparent",
        }}
      >
        <MessageList
          style={{
            background: "transparent",
          }}
        >
          {messages.length !== 0 ? (
            <>{renderedMessages}</>

          ) : (
            <>
              <div className="flex items-center justify-center flex-column h-[90%] w-full">
                <div className="flex items-center justify-center flex-column">
                  <img src={IMAGES.JL_WHITE} className="h-7" alt="" />
                  <p className="w-75 text-center mt-1 text-white">
                    Select a chat to start messaging
                  </p>
                </div>
              </div>
              <div className="hidden lg:flex flex-column items-center w-full h-[20%] mb-3 px-3 text-sx gap-y-2">
                <div
                  className="flex flex-col lg:flex-row w-full gap-x-3 gap-y-3"
                  onClick={() =>
                    handleSendMessage(
                      "Hello, what is your availability for an interview?",
                    )
                  }
                >
                  <div className="w-full lg:w-50 bg-[#131F2E] p-2 rounded-md border-[1px] border-gray-200">
                    <p className="text-[#46D6EA]">Ask for an interview</p>
                    <p className="text-[#7C7C7C] text-xs">
                      Request their availabilities for you to schedule an
                      interview
                    </p>
                  </div>
                  <div
                    className="w-full lg:w-50 bg-[#131F2E] p-2 rounded-md border-[1px] border-gray-200"
                    onClick={() =>
                      handleSendMessage("Select a chat to start messaging")
                    }
                  >
                    <p className="text-[#46D6EA]">Frequently Sent Message</p>
                    <p className="text-[#7C7C7C] text-xs">
                      Frequently Sent Message Description
                    </p>
                  </div>
                </div>
                <div
                  className="flex flex-col lg:flex-row w-full gap-x-3 gap-y-3 mt-2"
                  onClick={() =>
                    handleSendMessage("Select a chat to start messaging")
                  }
                >
                  <div className="w-full lg:w-50 bg-[#131F2E] p-2 rounded-md border-[1px] border-gray-200">
                    <p className="text-[#46D6EA]">Frequently Sent Message</p>
                    <p className="text-[#7C7C7C] text-xs">
                      Frequently Sent Message Description
                    </p>
                  </div>
                  <div className="w-full lg:w-50 bg-[#131F2E] p-2 rounded-md border-[1px] border-gray-200">
                    <p className="text-[#46D6EA]">Frequently Sent Message</p>
                    <p className="text-[#7C7C7C] text-xs">
                      Frequently Sent Message Description
                    </p>
                  </div>
                </div>
              </div>
            </>
          )}
        </MessageList>
        <MessageInput
          attachButton={false}
          placeholder="Type message here"
          onSend={handleSendMessage}
        />
      </ChatContainer>
    </MainContainer>
  )
}

export default Messages
