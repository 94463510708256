import { doc } from "firebase/firestore"
import React, { useEffect, useState } from "react"
import Badge from "react-bootstrap/Badge"
import Button from "react-bootstrap/Button"
import { useDocument } from "react-firebase-hooks/firestore"
import { BiMessage } from "react-icons/bi"
import { CgFlagAlt } from "react-icons/cg"
import { CiLocationOn } from "react-icons/ci"
import { IoMdCheckmark } from "react-icons/io"
import { IoBookmark, IoBookmarkOutline } from "react-icons/io5"
import { LuUser2 } from "react-icons/lu"
import { MdOutlineMenu } from "react-icons/md"
import { PiCaretCircleLeftFill, PiSuitcaseLight } from "react-icons/pi"
import { VscGlobe } from "react-icons/vsc"
import { useSelector } from "react-redux"
import { useNavigate, useParams } from "react-router-dom"
import { jobReject, jobSave } from "../../app/functions.js"
import CandidateLawFirmView from "../../components/CandidateLawFirmView.jsx"
import ModalCandidateJobApply from "../../components/modalCandidateJobApply/ModalCandidateJobApply.jsx"
import { firestore } from "../../config/firebase.js"
import { IMAGES } from "../../constants/images"
import Navbar from "../../utils/NavBar"
import useNavigateToConversation from "../../hooks/useNavigateToConversation.js"

const CandidateJobDetailsPage = () => {
  const { jobUid } = useParams()

  const [marked, setMarked] = useState(false)
  const [showFirmPage, setShowFirmPage] = useState(false)
  const [rejected, setRejected] = useState(false)
  const [applied, setApplied] = useState(false)
  const { navigateToConversation } = useNavigateToConversation()

  const renderQuillContent = (savedContent) => {
    return { __html: savedContent }
  }

  const navigate = useNavigate()
  const tooltipContent = "Text to be finalized"
  const handleReturn = () => {
    navigate("/jobboard")
  }
  const [showModal, setShowModal] = useState(false)

  const handleCloseModal = () => {
    setShowModal(false)
  }
  const handleShowModal = () => {
    setShowModal(true)
  }

  const initialData = useSelector((state) => state.initialData)

  const [value, loading, error] = useDocument(doc(firestore, "jobs", jobUid), {
    snapshotListenOptions: { includeMetadataChanges: true },
  })

  useEffect(() => {
    if (value) {
      checkIfMarked()
      checkIfRejected()
    }
  }, [value])

  const checkIfMarked = () => {
    if (value?.data()?.savedby.includes(initialData.uid)) {
      console.log("Marked")
      setMarked(true)
    }
  }

  const checkIfRejected = () => {
    if (value?.data()?.rejectedby?.includes(initialData?.uid)) {
      setRejected(true)
    }
  }

  return (
    <>
      <Navbar />
      {showFirmPage ? (
        <>
          <CandidateLawFirmView setShowFirmPage={setShowFirmPage} />
        </>
      ) : (
        <>
          <div
            className="flex flex-column items-center h-screen text-white overflow-y-auto relative z-0"
            style={{
              height: "calc(100vh - 62px)", // Adjust the height based on your navbar height
            }}
          >
            <div className="flex items-center mt-5 mb-2 mx-3 w-[90%] lg:w-[70%]">
              <PiCaretCircleLeftFill
                size={38}
                className="mr-2 text-blue-400 cursor-pointer duration-75	 hover:scale-125"
                onClick={() => window.history.back()}
              />
              Back
            </div>
            <div className="flex flex-col lg:flex-row mt-2 mx-3 w-[90%] lg:w-[70%] gap-x-5 ">
              {/* left */}
              <div className="w-full lg:w-[70%] bg-white rounded-md text-[#131F2E] h-fit m-0 lg:mr-2 mb-3  ">
                {/* background image */}
                <div className=" relative ">
                  <img
                    src="https://i0.wp.com/linkedinheaders.com/wp-content/uploads/2018/02/mountain-lake-header.jpg?fit=1584%2C396&ssl=1"
                    className="w-full h-[200px] rounded-t-md"
                  />
                  {/* apply button */}
                  {applied == false &&
                    !value?.data().applicants?.includes(initialData.uid) &&
                    !rejected &&
                    !value?.data().rejectedby?.includes(initialData.uid) && (
                      <div
                        className="flex items-center justify-center font-bold w-[138px] -[36px] absolute top-0 right-0 text-[#8186FF] bg-[#8154FF4F] hover:cursor-pointer hover:bg-[#8254ff17] rounded-md"
                        onClick={(e) => {
                          e.stopPropagation() // Stop the propagation here
                          // e.preventDefault();
                          handleShowModal() // Or your button click logic here
                        }}
                      >
                        Apply
                      </div>
                    )}
                  {/* firm logo */}
                  <div className="flex justify-between w-full px-4 relative ">
                    <div className="flex justify-center md:justify-start w-[85%] absolute  -bottom-10">
                      {value?.data().firmLogo == "" ? (
                        <img
                          src={IMAGES.FIRM_LOGO}
                          className="w-24 h-24 border-3 border-white rounded-full "
                        />
                      ) : (
                        <img
                          src={value?.data().firmLogo}
                          className="w-24 h-24 border-3 border-white rounded-full cursor-pointer "
                          onClick={() => setShowFirmPage(true)}
                        />
                      )}
                    </div>
                    <div className="flex justify-center md:justify-end w-[80%] md:w-[90%] absolute top-[190px] md:top-1  ">
                      <div className="flex flex-col-reverse md:flex-row items-center justify-between gap-x-3 text-center ">
                        <div
                          className="flex text-xs items-center justify-center cursor-pointer mt-4 hover:scale-[1.05]"
                          onClick={() =>
                            navigateToConversation(
                              value.data().userid,
                              value?.data().uid,
                            )
                          }
                        >
                          <img
                            src={IMAGES.GREY_MESSAGE}
                            alt="message"
                            className="mr-2"
                          />
                          <p className="hover:font-bold hover:text-black">Ask a question</p>
                        </div>

                        {!rejected &&
                        !value?.data().rejectedby?.includes(initialData.uid) ? (
                          <div className="flex flex-column items-center ">
                            {!value
                              ?.data()
                              .applicants?.includes(initialData.uid) &&
                              applied == false && (
                                <div className="flex items-center justify-between mt-4 gap-x-3 gap-y-1 w-full">
                                  <Button
                                    size="sm"
                                    className="w-full md:w-50 flex justify-center items-center gap-x-2 bg-[#ff787863] border-1 border-[#ff7878] text-[#ff7878] px-3 cursor-pointer hover:bg-transparent hover:border-[#ff7878] hover:text-[#ff7878] "
                                    onClick={(e) => {
                                      e.stopPropagation() // Stop the propagation here
                                      // Your button click logic here
                                      jobReject(
                                        value?.data().uid,
                                        initialData.uid,
                                      )
                                      setRejected(!rejected)
                                      e.preventDefault()
                                      console.log("Rejected")
                                    }}
                                  >
                                    <img
                                      src={IMAGES.RED_CROSS}
                                      width={14}
                                      height={17}
                                    />
                                    Reject
                                  </Button>
                                  {/* <Button
                                    size="sm"
                                    className="w-full md:w-50 flex justify-center items-center gap-x-2 bg-[#cfefca] border-1 border-[#81d674] text-[#81d674] px-3 cursor-pointer hover:bg-transparent hover:border-[#81d674] hover:text-[#81d674] "
                                    onClick={(e) => {
                                      e.stopPropagation() // Stop the propagation here
                                      // e.preventDefault();
                                      handleShowModal() // Or your button click logic here
                                    }}
                                  >
                                    <img
                                      src={IMAGES.GREEN_HEART}
                                      width={14}
                                      height={17}
                                    />
                                    Apply
                                  </Button> */}
                                </div>
                              )}
                          </div>
                        ) : (
                          <div className="flex flex-column items-center text-xs mt-4 ">
                            Job rejected
                          </div>
                        )}

                        {/* <Button
                        size="sm"
                        className="flex items-center gap-x-2 bg-[#ff787863] border-1 border-[#ff7878] text-[#ff7878] px-3 cursor-pointer hover:bg-transparent hover:border-[#ff7878] hover:text-[#ff7878] "
                        onClick={(e) => {
                          e.stopPropagation() // Stop the propagation here
                          // Your button click logic here
                          e.preventDefault()
                          test()
                        }}
                      >
                        <img src={IMAGES.RED_CROSS} width={14} height={17} />
                        Reject
                      </Button>
                      <Button
                        size="sm"
                        className="flex items-center gap-x-2 bg-[#cfefca] border-1 border-[#81d674] text-[#81d674] px-3 cursor-pointer hover:bg-transparent hover:border-[#81d674] hover:text-[#81d674] "
                        onClick={(e) => {
                          e.stopPropagation() // Stop the propagation here
                          // e.preventDefault();
                          handleShowModal() // Or your button click logic here
                        }}
                      >
                        <img src={IMAGES.GREEN_HEART} width={14} height={17} />
                        Apply
                      </Button> */}

                        <ModalCandidateJobApply
                          showModal={showModal}
                          handleClose={handleCloseModal}
                          setApplied={(e) => setApplied(e)}
                          job_id={value?.data().uid}
                        />
                      </div>
                    </div>
                  </div>
                  {/* edit icon */}
                  {/* <div className="absolute text-white top-5 right-4">
                <AiFillEdit size={24} className="cursor-pointer" />
              </div> */}
                </div>

                {/* job info */}
                <div className="px-5 py-2 mt-[30px] md:mt-[50px] h-[250px] md:h-fit">
                  <div className="flex justify-between items-center">
                    <div className="mt-1">
                      <p className="font-bold">
                        {value?.data().jobTitle}
                        {/* Job title */}
                      </p>
                      <p className="text-xs my-2">Posted 1 day ago</p>
                    </div>
                    {marked == false ? (
                      <>
                        <IoBookmarkOutline
                          size={22}
                          className="cursor-pointer "
                          onClick={() => {
                            setMarked(!marked)
                            jobSave(value?.data().uid, initialData.uid)
                          }}
                        />
                      </>
                    ) : (
                      <>
                        <IoBookmark
                          size={22}
                          className="cursor-pointer  text-yellow-300"
                          onClick={() => {
                            setMarked(!marked)
                            jobSave(value?.data().uid, initialData.uid)
                            console.log(marked)
                            console.log(
                              value?.data()?.savedby?.includes(initialData.uid),
                            )
                            console.log("Action taking place")
                          }}
                        />
                      </>
                    )}
                  </div>
                  <div className="flex flex-col md:flex-row gap-2 text-xs mt-3">
                    {/* {jobData.jobExpertise.map((label, index) => (
                  <p className="flex items-center" key={index}>
                    <IoMdCheckmark className="mr-1" />
                    {label}
                  </p>
                ))} */}
                    <Badge pill bg="" className="px-3 py-1 w-fit bg-[#D47DF8]">
                      70% match
                    </Badge>
                    <p className="flex items-center text-[#1B1F87]/50">
                      <IoMdCheckmark className="mr-1" />
                      Position
                    </p>
                    <p className="flex items-center text-[#1B1F87]/50">
                      <IoMdCheckmark className="mr-1" />
                      Expertise
                    </p>
                    <p className="flex items-center text-[#1B1F87]/50">
                      <IoMdCheckmark className="mr-1" />
                      Experience
                    </p>
                  </div>
                </div>
                {/* job more info */}
                <div className="flex  flex-column w-full mt-4 px-5 py-3 border-t-2 border-gray-300">
                  <p className="flex items-center">
                    <CiLocationOn className="mr-2" />
                    {value?.data().jobCities == "" ||
                    value?.data().jobCities == null ? (
                      <>Location not disclosed</>
                    ) : (
                      <>{value?.data().jobCities.join(", ")}</>
                    )}
                  </p>
                  <p className="flex items-center">
                    <PiSuitcaseLight className="mr-2" />
                    {value?.data().jobExperienceMin == "" ||
                    value?.data().jobExperienceMin == null ? (
                      <>0 - 7 years of experience</>
                    ) : (
                      <>
                        {value?.data().jobExperienceMin} -{" "}
                        {value?.data().jobExperienceMax} years of experience
                      </>
                    )}
                  </p>
                  <p className="flex items-center">
                    <MdOutlineMenu className="mr-2" />
                    {value?.data().jobExpertise == "" ||
                    value?.data().jobExpertise == null ? (
                      <>No expertise specified</>
                    ) : (
                      <>{value?.data().jobExpertise}</>
                    )}
                  </p>
                  <p className="flex items-center">
                    <BiMessage className="mr-2" />
                    {value?.data().jobLanguages == "" ||
                    value?.data().jobLanguages == null ? (
                      <>No language provided</>
                    ) : (
                      <>{value?.data().jobLanguages.join(", ")}</>
                    )}
                  </p>
                </div>
                {/* Job Description */}
                <div className="px-5 py-2 border-t-2 border-gray-200 my-2">
                  <h4 className="text-[#131F2E]/50 uppercase my-2 font-bold">
                    full description
                  </h4>
                  <div
                    class="list-disc"
                    dangerouslySetInnerHTML={{
                      __html: value?.data().jobDescription,
                    }}
                  />

                  {/* <p
                 className="text-justify"
                 dangerouslySetInnerHTML={{
                   __html: value?.data().jobAdditionalDescription,
                 }}
               >
               </p> */}
                </div>
                {/* Job Requirement */}
                <div className=" py-2 border-t-2 border-gray-200 my-2 sm:px-5">
                  <h4 className="text-[#131F2E]/50 uppercase my-2 font-bold">
                    REQUIREMENTS
                  </h4>
                  <p className="text-justify">
                    <ul className="list-disc px-4">
                      <li>
                        <strong>Expertise: &nbsp;</strong>
                        {value?.data().jobExpertise}
                      </li>
                      <li>
                        <strong>Minimum experience: &nbsp;</strong>
                        {value?.data().jobExperienceMin}
                      </li>
                      <li>
                        <strong>Maximum experience: &nbsp;</strong>
                        {value?.data().jobExperienceMax}
                      </li>
                      <li>
                        <strong>Required languages: &nbsp;</strong>
                        {value?.data().jobLanguages?.join(", ")}
                      </li>
                      <li>
                        <strong>Bonus languages: &nbsp;</strong>
                        {value?.data().jobBonusLanguages?.join(", ")}
                      </li>
                    </ul>
                  </p>
                </div>
                {/* additional info */}
                <div className="px-5 py-2 border-t-2 border-gray-200 my-2 ">
                  <h4 className="text-[#131F2E]/50 uppercase my-2 font-bold">
                    additional information
                  </h4>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: value?.data().jobAdditionalDescription,
                    }}
                  />
                </div>
              </div>
              {/* right */}
              <div className="w-full lg:w-[30%] m-0 lg:ml-2 bg-gray-600/50 p-4 rounded-md text-xs h-fit">
                <div className="pb-3 border-b-[1px] border-gray-300">
                  <h2 className="font-bold mb-1">More about this law firm</h2>
                </div>
                <div className="pb-3 border-b-[1px] border-gray-300">
                  <p className="flex items-center mt-4">
                    <CgFlagAlt className="mr-1" />

                    {value?.data().companyName}
                  </p>
                  <p className="flex items-center my-3">
                    <LuUser2 className="mr-1" />
                    {value?.data().firmSize}
                  </p>
                  <p className="flex items-center">
                    <VscGlobe className="mr-1" />
                    <a
                      href={value?.data().firmWebsite}
                      target="_blank"
                      className="overflow-x-clip"
                    >
                      {value?.data().firmWebsite}
                    </a>
                  </p>
                </div>
                <div>
                  <h3 className="my-4 uppercase">overview</h3>
                  <div
                    className="text-white"
                    dangerouslySetInnerHTML={{
                      __html: value?.data().aboutCompany,
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  )
}

export default CandidateJobDetailsPage