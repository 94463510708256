import { createSlice } from "@reduxjs/toolkit"
import { initialise, login, logout } from "./userRedux/userSlice"

const initialState = {}

export const dataSlice = createSlice({
  name: "data",
  initialState,
  reducers: {
    saveData: (state, action) => {
      state.value = action.payload
    },
  },
  extraReducers: (builder) =>
    builder
      .addCase(initialise, (state, action) => {
        if (action.payload) {
          state.value = action.payload.userData
        }
      })
      .addCase(login, (state, action) => {
        if (action.payload) {
          state.value = action.payload.userData
        }
      })
      .addCase(logout, () => initialState),
})

// Action creators are generated for each case reducer function
export const { saveData } = dataSlice.actions

export default dataSlice.reducer
