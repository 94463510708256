import { useEffect } from "react"
import { useSelector } from "react-redux"
import { initaliseUser } from "../app/functions"
import { isInitialising } from "../app/userRedux/userSlice"
import { auth } from "../config/firebase"

const useInitAuth = () => {
  const initialising = useSelector(isInitialising)

  useEffect(() => {
    const checkAuthState = async () => {
      await auth.authStateReady()
      await initaliseUser(auth.currentUser)
    }

    checkAuthState()
  }, [])

  return { initialisingAuth: initialising }
}

export default useInitAuth
