import React from "react"
import styles from "./RecruiterCandidatePagePreview.module.css"
import { IMAGES } from "../../constants/images.jsx"
import harvey from "../../assets/example_images/harvey.jpg"
import lawfirm_logo from "../../assets/example_images/law-firm-logojpg.jpg"
import { CiLocationOn } from "react-icons/ci"
import { PiSuitcaseLight } from "react-icons/pi"
import { BiMessage } from "react-icons/bi"
import { MdOutlineMenu } from "react-icons/md"
import { PiCaretCircleLeftFill } from "react-icons/pi"

import { useSelector } from "react-redux"
import { useLocation, useNavigate, useParams } from "react-router-dom"
// import useFetchCandidates from "../../hooks/useFetchCandidates.js"
import { useDocument } from "react-firebase-hooks/firestore"
import { firestore } from "../../config/firebase.js"
import { doc } from "firebase/firestore"
import useCheckIfAnonymous from "../../hooks/useCheckIfAnonymous.js"

const RecruiterCandidatePagePreview = () => {
  // const user = useSelector((state) => state.data.value)
  const navigate = useNavigate()

  const { candidateUid } = useParams()

  const { state } = useLocation()
  const { origin } = state
  const userData = useSelector((state) => state.user.userData)

  const [value, loading, error] = useDocument(
    doc(firestore, "users", candidateUid),
    {
      snapshotListenOptions: { includeMetadataChanges: true },
    },
  )
  // console.log(value?.data())
  const user = value?.data()

  const flipDateString = (dateString) => {
    const parts = dateString.split("-")
    const flippedDateString = parts.reverse().join("-")
    return flippedDateString
  }


  const { incognito } = useCheckIfAnonymous(userData.uid,user?.uid)


  return (
    <div
      className={
        (styles.container, "overflow-y-auto flex items-center justify-center")
      }
    >
      <div
        className={
          (styles.contentsection,
          "flex flex-column items-center justify-center w-[80%] lg:w-[75%]")
        }
      >
        {/* Title */}
        <div
          className="flex items-center text-white mt-5 mb-2 mx-3"
          style={{ width: "115%" }}
        >
          <PiCaretCircleLeftFill
            size={38}
            className="mr-2 text-blue-400 cursor-pointer duration-75	 hover:scale-125"
            onClick={() => window.history.back()}
          />
          Back
        </div>
        {/* Top Bar */}
        <div className={styles.topbar}>
          {/* <img src={harvey} className={styles.imgprofile} /> */}

          {user?.profileImg ? (
            <>
              <img
                src={ ((origin == "matches" || origin == "applicants") || incognito === false) ? user.profileImg  : harvey}
                alt="user image"
                className={styles.imgprofile}
              />
            </>
          ) : (
            <>
              <img
                src={harvey}
                alt="user image"
                className={styles.imgprofile}
              />
            </>
          )}

          {/* Top bar section */}
          <div className={styles.widesection}>
            <div className={styles.topbarsection}>
              <div className="flex justify-between">
                <div>
                  {user?.name == "" || !user?.name ? (
                    <p className="bigText ">Anonymous</p>
                  ) : (
                    <p className="bigText">
                      {(origin == "matches" || origin == "applicants") || incognito === false ? 
                        (
                          user?.name === "" || !user?.name ? <>Anonymous</> :<> {user?.name} {user?.surname}</>
                        ) : (
                          <>Anonymous</> 
                        )
                      }
                    </p>
                  )}
                  {user?.location == undefined ? (
                    <p className="flex flex-col lg:flex-row gap-y-1  items-center smallText">
                      <CiLocationOn size={20} className="mr-2" />
                      Candidate location not added
                    </p>
                  ) : user?.location ? (
                    <p className="flex flex-col lg:flex-row gap-y-1  items-center smallText">
                      <CiLocationOn size={20} className="mr-2" />

                      {user?.location}
                    </p>
                  ) : (
                    <p className="flex flex-col lg:flex-row gap-y-1  items-center smallText">
                      <CiLocationOn size={20} className="mr-2" />
                      No location added
                    </p>
                  )}
                </div>
              </div>
            </div>
            <div className={styles.topbarsection}>
              <text className="flex flex-col lg:flex-row gap-y-1 items-center smallText">
                <PiSuitcaseLight size={20} className="mr-2" />
                {user?.experience ? (
                  <>
                    <p>{user?.experience.label} of experience</p>
                  </>
                ) : (
                  <>
                    <p>No experience </p>
                  </>
                )}
              </text>
              <text className="flex flex-col lg:flex-row gap-y-1 items-center smallText">
                <BiMessage size={20} className="mr-2" />
                {/* {user?.selectedLanguages[0] ? (
                  <>{user?.selectedLanguages?.label.join(" | ")}</>
                ) : ( */}
                <>
                  <p>No language is added</p>
                </>
                {/* )} */}
              </text>

              <text className="flex flex-col lg:flex-row gap-y-1 items-center smallText">
                <MdOutlineMenu size={20} className="mr-2" />
                {user?.expertise ? (
                  <>{user?.expertise.label?.join(" | ")}</>
                ) : (
                  <>
                    <p>No expertise is added</p>
                  </>
                )}
              </text>
            </div>
            <div className={styles.topbarsectionright}>
              <div className="flex">
                <div>
                  <button className={styles.greenbtn}>
                    <text className={styles.thicktextbtn}>
                      •{" "}
                      {user?.searchingStatus
                        ? user?.searchingStatus
                        : "Casually looking"}
                    </text>
                  </button>
                </div>
              </div>

              <text
                className="smallText cursor-pointer"
                onClick={() => navigate("/chatroom")}
              >
                Send a message
              </text>
            </div>
          </div>
        </div>
        {/* Medium section */}
        <div
          className={
            (styles.personalmidsection, "flex flex-col lg:flex-row w-full ")
          }
          style={{ width: "115%" }}
        >
          <div className={styles.largesection}>
            {/* About me section */}
            <div className={styles.abtme}>
              <div className="flex justify-between mb-1 mt-3">
                <h2 className={styles.titletext}>About Candidate</h2>
              </div>
              {user?.description ? (
                <p className="text-white">{user?.description}</p>
              ) : (
                <>
                  <p className="text-white">No description is added</p>
                </>
              )}

              <br />
            </div>
            {/* My Experience section */}
            <div className={styles.experienceSingleSection}>
              <div className="flex items-center justify-between">
                <h2 className={styles.titletext}>Experience</h2>
              </div>
              {user?.employmentHistory ? (
                <>
                  {user.employmentHistory.length == 0 ? (
                    <>
                      <div>no information added</div>
                    </>
                  ) : (
                    <>
                      {user?.employmentHistor?.map((element, index) => (
                        <ExperienceComponent data={element} key={index} />
                      ))}
                    </>
                  )}
                </>
              ) : (
                <>
                  <p className="smallText">no relevant experience is added</p>
                </>
              )}
            </div>
            {/* my Education */}
            <div className={styles.abtme}>
              <div className="flex justify-between mb-4">
                <h2 className={styles.titletext}>Canddate Education</h2>
              </div>
              {user?.university ? (
                <>
                  <div className="flex flex-col lg:flex-row items-start lg:items-center justify-between">
                    <p className="text-white">
                      University Name: {user.university}
                    </p>
                    <p className="text-white"> Grades: {user.grades}</p>
                    <p className="text-white">
                      Date of Graduation:{" "}
                      {flipDateString(user.dateOfGradutation)}
                    </p>
                  </div>
                </>
              ) : (
                <>
                  <p className="text-white">No Education is added </p>
                </>
              )}
              {user?.master && user?.master && Array.isArray(user?.master)? (
                <>
                  <p className="text-white  mt-2 font-bold">
                    Master of Laws (LL.M.) Certificates
                  </p>
                  {user.master?.map((item, index) => (
                    <div className="flex items-center" key={index}>
                      <ul className="px-4 list-disc">
                        <li className="text-white">{item}</li>
                      </ul>
                    </div>
                  ))}
                </>
              ) : (
                <>
                  <p className="text-white">No Master education is added </p>
                </>
              )}

              <br />
            </div>
          </div>
          <div className={styles.leftsection}>
          {false &&
            <div className={styles.documents}>
              <div className="flex items-center justify-between">
                <h2 className={styles.titletext}>Documents</h2>
              </div>
              {user?.employmentHistory ? (
                <>
                  {!user?.name ? (
                    <>
                      <p className="text-white">No Documents found</p>
                    </>
                  ) : (
                    <>
                      <div>
                        {user?.cv !== "" ? (
                          <>
                            <div className={styles.cv_element}>
                              <text className="">{user.name}-CV</text>
                              <a
                                className={styles.buttonView}
                                href={user.cv}
                                target="_blank"
                              >
                                View PDF
                              </a>
                            </div>
                          </>
                        ) : (
                          <>
                            <div className="mt-2 text-white">
                              No CV is uploaded
                            </div>
                          </>
                        )}
                      </div>
                      {/* cover letter */}
                      <div>
                        {user?.coverLetter !== "" ? (
                          <>
                            <div className={styles.cv_element}>
                              <text className="">
                                {user?.name}-Cover Letter
                              </text>
                              <a
                                className={styles.buttonView}
                                href={user.coverLetter}
                                target="_blank"
                              >
                                View PDF
                              </a>
                            </div>
                          </>
                        ) : (
                          <>
                            <div className="mt-2 text-white">
                              No Cover Letter is uploaded
                            </div>
                          </>
                        )}
                      </div>
                      {/* Transcript */}
                      <div>
                        {user?.transcripts !== "" ? (
                          <>
                            <div className={styles.cv_element}>
                              <text className="">{user?.name}-Transcripts</text>
                              <a
                                className={styles.buttonView}
                                href={user.transcripts}
                                target="_blank"
                              >
                                View PDF
                              </a>
                            </div>
                          </>
                        ) : (
                          <>
                            <div className="mt-2 text-white">
                              No Transcripts are uploaded
                            </div>
                          </>
                        )}
                      </div>
                    </>
                  )}
                </>
              ) : (
                <>
                  <p className="smallText"> No Documents uploaded</p>
                </>
              )}
            </div>
            }

            <div className={styles.documents}>
              <div className="flex items-center justify-between">
                <h2 className={styles.titletext}>Expertise</h2>
              </div>

              {user?.expertise?.label?.map((expertise) => (
                <button className={styles.buttonSkill}>{expertise}</button>
              ))}
            </div>
          </div>
        </div>

        <br />
        <br />
      </div>
    </div>
  )
}

const ExperienceComponent = ({ data }) => {
  return (
    <div className={styles.experienceSingleSection}>
      <img className={styles.lawfirm_logo} 
      
      src={lawfirm_logo} 

      />
      <div className={styles.experiencetextcontainer}>
        <text className="mediumText">{data?.position_title}</text>
        <div className="row space-between">
          {data?.employmentstart == "" ? (
            <text>Name of the company</text>
          ) : (
            <text>{data?.lawfirmname.label}</text>
          )}
          <div className="row">
            <text className={styles.graytext}>1 year 2 months</text>
            {data?.employmentstart == "" ? (
              <text className={styles.graytext}>
                Edit this entry to get dates
              </text>
            ) : (
              <text className={styles.graytext}>
                {data?.employmentstart} to {data?.employmentend}
              </text>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

export default RecruiterCandidatePagePreview
