import React, { useState, useEffect } from "react"
import { IMAGES } from "../constants/images.jsx"
import { IoBookmark, IoBookmarkOutline } from "react-icons/io5"
import Badge from "react-bootstrap/Badge"
import { IoMdCheckmark } from "react-icons/io"
import { CiLocationOn } from "react-icons/ci"
import { PiSuitcaseLight } from "react-icons/pi"
import { BiMessage } from "react-icons/bi"
import { MdOutlineMenu } from "react-icons/md"
import { TbMessageCircle } from "react-icons/tb"
import { useNavigate } from "react-router-dom"
import Button from "react-bootstrap/Button"
import ModalCandidateJobApply from "./modalCandidateJobApply/ModalCandidateJobApply.jsx"
import { useSelector, useDispatch } from "react-redux"
import { updateInitialData } from "../app/initialDataSlice.js"
import { format, isToday, isYesterday } from "date-fns"

import CandidateJobDetailsPage from "../pages/CandidateJobDetailsPage/CandidateJobDetailsPage.jsx"
import { jobReject, jobSave } from "../app/functions.js"
import useNavigateToConversation from "../hooks/useNavigateToConversation.js"
import TinderCard from "react-tinder-card"

const CandidateJobCardHorizontal = ({ position, data }) => {
  const initialData = useSelector((state) => state.initialData)
  const [marked, setMarked] = useState(false)
  const [rejected, setRejected] = useState(false)
  // const [candidateJobDetails, setCandidateJobDetails] = useState(false)
  const navigate = useNavigate()
  const [showModal, setShowModal] = useState(false)
  const [applied, setApplied] = useState(false)
  const dispatch = useDispatch()
  const { navigateToConversation } = useNavigateToConversation()

  const isLocationMatch =
    Array.isArray(data?.jobCities) &&
    Array.isArray(initialData?.cities) &&
    initialData?.cities?.some((loc) => data.jobCities?.includes(loc))

  const isExpertiseMatch =
    Array.isArray(data?.jobExpertise) &&
    Array.isArray(initialData?.expertise) &&
    initialData?.expertise?.some((expertise) =>
      data?.jobExpertise?.includes(expertise),
    )

  const handleCloseModal = () => {
    setShowModal(false)
  }
  const handleShowModal = () => {
    dispatch(updateInitialData({ selectedJob: data }))
    setShowModal(true)
  }
  const goToJobPage = () => {
    navigate("/candidatejobdetailspage", { state: { data: data } })
  }

  const goToJob = () => {
    const jobUid = data.uid
    navigate(`/candidatejobdetailspage/${jobUid}`)
  }

  let job_description = data?.jobDescription.split(".")[0]

  const formatDate = (dateString) => {
    const formattedDate = new Date(dateString)

    // Check if the date is today or yesterday
    if (isToday(formattedDate)) {
      return "Today"
    } else if (isYesterday(formattedDate)) {
      return "Yesterday"
    } else {
      // If not today or yesterday, display the actual date
      const options = { year: "numeric", month: "long", day: "numeric" }
      return formattedDate.toLocaleDateString(undefined, options)
    }
  }

  useEffect(() => {
    if (data.savedby) {
      checkIfMarked()
    }
    if (data.rejectedby) {
      checkIfRejected()
    }
  }, [data])

  const checkIfMarked = () => {
    if (data?.savedby?.includes(initialData?.uid)) {
      setMarked(true)
    }
  }
  const checkIfRejected = () => {
    if (data?.rejectedby?.includes(initialData?.uid)) {
      setRejected(true)
    }
  }
  // const onSwipe = (direction) => {
  //   console.log("You swiped: " + direction)
  // }
  const onSwipe = (direction) => {
    switch (direction) {
      case "left":
        console.log("swiped to left")
        jobReject(data.uid, initialData.uid)
        setRejected(!rejected)
        break
      case "right":
        console.log("swiped to right")
        setMarked(!marked)
        jobSave(data.uid, initialData.uid)
        break
      case "up":
        console.log("swiped to up")
        // Call the goToJob function when swiped up
        handleShowModal()

        break
      case "down":
        console.log("swiped to down")
        goToJob()
        break
      default:
        console.log("Invalid swipe direction")
    }
  }

  // const onCardLeftScreen = (index) => {
  //   console.log(index + " left the screen")
  //   setSwipedCards([...swipedCards, index])
  // }

  return (
    <>
      <div className="hidden md:flex flex-col md:flex-row justify-between gap-x-4 bg-white w-full text-[#131F2E] rounded-md px-4 py-3 mb-4 relative">
        {/* left side */}
        <div
          className="w-full md:w-50 px-2 cursor-pointer"
          onClick={() => goToJob()}
        >
          <div className="flex items-center">
            {data?.firmLogo == "" ? (
              <img src={IMAGES.FIRM_LOGO} className="w-10 h-10 rounded-full" />
            ) : (
              <img src={data?.firmLogo} className="w-10 h-10 rounded-full" />
            )}
            <p class="ml-2 font-medium text-sm">
              <>{data?.companyName}</>
            </p>
          </div>

          <div className="mt-2">
            <p className="font-bold">
              {data?.jobTitle == "" ? <>Job title</> : <>{data?.jobTitle}</>}
            </p>
            <p className="text-xs my-2 text-[#131F2E]/50">
              Created: {formatDate(data?.createdAt)}
            </p>
          </div>
          <div className="my-2 border-b-2 border-gray-300">
            <Badge pill bg="" className="px-3 py-1 bg-[#D47DF8]">
              {data?.matchPercentage} match
            </Badge>
            <div className="flex flex-col md:flex-row items-start md:items-center justify-between mt-2 mb-3 px-2 w-[80%] text-[#1B1F87]/50">
              {isLocationMatch && (
                <p className="flex items-center text-xs">
                  <IoMdCheckmark className="mr-1" />
                  Location
                </p>
              )}

              {isExpertiseMatch && (
                <p className="flex items-center text-xs">
                  <IoMdCheckmark className="mr-1" />
                  Expertise
                </p>
              )}

              <p className="flex items-center text-xs">
                <IoMdCheckmark className="mr-1" />
                Experience
              </p>
            </div>
          </div>
          <div>
            <p className="flex items-center mb-1 font-semibold text-sm	">
              <CiLocationOn size={20} className="mr-2" />
              {/* Location of the job */}
              {data?.jobCities == "" || data?.jobCities == null ? (
                <>Location not disclosed</>
              ) : (
                <>{data?.jobCities.join(", ")}</>
              )}
            </p>
          </div>
        </div>
        {/* right side */}
        <div className="w-full md:w-50 px-2 sm:mt-0 md:mt-5">
          <div className="flex ">
            <div className="w-full md:w-75">
              <p className="flex items-center mb-1 font-semibold text-sm">
                <PiSuitcaseLight size={20} className="mr-2" />
                {data?.jobExperienceMin == "" ||
                data?.jobExperienceMin == null ? (
                  <>0 - 7 years of experience</>
                ) : (
                  <>
                    {data?.jobExperienceMin} - {data?.jobExperienceMax} years of
                    experience
                  </>
                )}
              </p>
              <p className="flex items-center mb-1 font-semibold text-sm">
                <BiMessage size={20} className="mr-2" />
                {data?.jobLanguages == "" || data?.jobLanguages == null ? (
                  <>No language provided</>
                ) : (
                  <>{data?.jobLanguages.join(", ")}</>
                )}
              </p>
            </div>

            {applied == false &&
              !data?.applicants?.includes(initialData.uid) &&
              !rejected &&
              !data?.rejectedby?.includes(initialData.uid) && (
                <div
                  className="flex font-bold text-[#8186FF] bg-[#8154FF4F] hover:cursor-pointer hover:bg-[#8254ff17]"
                  style={{
                    width: "138px",
                    height: "36px",
                    position: "absolute",
                    top: "0px",
                    right: "0px",
                    borderStartEndRadius: "5px",
                    borderEndStartRadius: "10px",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                  onClick={(e) => {
                    e.stopPropagation() // Stop the propagation here
                    // e.preventDefault();
                    handleShowModal() // Or your button click logic here
                  }}
                >
                  Apply
                </div>
              )}
          </div>
          <div
            class="list-disc h-[40px] my-2 text-sm pb-2 overflow-y-scroll text-[#131F2E]/70"
            dangerouslySetInnerHTML={{
              __html: job_description,
            }}
          />
          {origin !== "matches" &&
            applied == false &&
            !data?.applicants?.includes(initialData.uid) && (
              <div className=" cursor-pointer ">
                <div className="flex items-center justify-center mt-4 gap-x-3 w-full ">
                  {marked == false && (
                    <Button
                      size="sm"
                      className={
                        rejected
                          ? "w-50 flex justify-center items-center gap-x-2 bg-[#ff787863] border-1 border-[#ff7878] text-[#ff7878] px-3 cursor-pointer hover:bg-transparent hover:border-[#ff7878] hover:text-[#ff7878] font-bold"
                          : "w-50 flex justify-center items-center gap-x-2 bg-[#ff787863] border-1 border-[#ff7878] text-[#ff7878] px-3 cursor-pointer hover:bg-transparent hover:border-[#ff7878] hover:text-[#ff7878] font-bold"
                      }
                      onClick={(e) => {
                        e.stopPropagation() // Stop the propagation here
                        // Your button click logic here
                        jobReject(data.uid, initialData.uid)
                        setRejected(!rejected)

                        e.preventDefault()
                      }}
                    >
                      <img src={IMAGES.RED_CROSS} width={14} height={17} />
                      {!rejected ? "Reject" : "Rejected"}
                    </Button>
                  )}
                  {!rejected && (
                    <Button
                      size="sm"
                      className="w-50 flex justify-center items-center gap-x-2 bg-[#cfefca] border-1 border-[#81d674] text-[#81d674] px-3 cursor-pointer hover:bg-transparent hover:border-[#81d674] hover:text-[#81d674] font-bold"
                      onClick={(e) => {
                        // e.stopPropagation() // Stop the propagation here
                        // // e.preventDefault();
                        // handleShowModal() // Or your button click logic here

                        setMarked(!marked)
                        jobSave(data.uid, initialData.uid)
                      }}
                    >
                      <img src={IMAGES.GREEN_HEART} width={14} height={17} />
                      {/* {!data?.applicants?.includes(initialData.uid) && applied == false ? */}
                      {marked == false ? "Like" : "Liked"}
                    </Button>
                  )}
                </div>

                <div
                  className="flex mt-3 items-center justify-center text-center"
                  onClick={() => {
                    navigateToConversation(data.userid, data.uid)
                  }}
                >
                  <p className="flex items-center mb-1 cursor-pointer text-[#131F2E]/60">
                    <TbMessageCircle
                      size={20}
                      className="mr-2 hover:font-bold hover:text-black"
                    />
                    Ask a question
                  </p>
                </div>
              </div>
            )}

          {(origin == "matches" ||
            applied ||
            data?.applicants?.includes(initialData.uid)) && (
            <div
              className="flex items-center justify-center text-[#3BAF4F] font-bold  "
              onClick={() => {
                navigateToConversation(data.userid, data.uid)
              }}
            >
              <div className="rounded bg-[#81D67461] px-3 py-1 border-solid border-[#3BAF4F] border-1 hover:bg-[#81D67499] mb-[38px]">
                <p className="flex items-center  cursor-pointer">
                  <TbMessageCircle
                    size={20}
                    className="mr-2 hover:font-bold hover:text-black"
                  />
                  Ask a question
                </p>
              </div>
            </div>
          )}

          {/* ) : (
          <div className="flex flex-column items-center ">Job rejected</div>
        )} */}
          <ModalCandidateJobApply
            showModal={showModal}
            handleClose={handleCloseModal}
            setApplied={setApplied}
          />
        </div>
      </div>
      <TinderCard
        onSwipe={onSwipe}
        // onCardLeftScreen={() => onCardLeftScreen(index)}
        // preventSwipe={["right", "left"]}
        className="inline-block md:hidden absolute w-[80%]"
      >
        <div className="flex flex-col md:flex-row justify-between gap-x-4 bg-white w-full text-[#131F2E] rounded-md px-4 py-3 mb-4 relative">
          {/* left side */}
          <div
            className="w-full md:w-50 px-2 cursor-pointer"
            onClick={() => goToJob()}
          >
            <div className="flex items-center">
              {data?.firmLogo == "" ? (
                <img
                  src={IMAGES.FIRM_LOGO}
                  className="w-10 h-10 rounded-full"
                />
              ) : (
                <img src={data?.firmLogo} className="w-10 h-10 rounded-full" />
              )}
              <p class="ml-2 font-medium text-sm">
                <>{data?.companyName}</>
              </p>
            </div>

            <div className="mt-2">
              <p className="font-bold">
                {data?.jobTitle == "" ? <>Job title</> : <>{data?.jobTitle}</>}
              </p>
              <p className="text-xs my-2 text-[#131F2E]/50">
                Created: {formatDate(data?.createdAt)}
              </p>
            </div>
            <div className="my-2 border-b-2 border-gray-300">
              <Badge pill bg="" className="px-3 py-1 bg-[#D47DF8]">
                {data?.matchPercentage} match
              </Badge>
              <div className="flex flex-col md:flex-row items-start md:items-center justify-between mt-2 mb-3 px-2 w-[80%] text-[#1B1F87]/50">
                {isLocationMatch && (
                  <p className="flex items-center text-xs">
                    <IoMdCheckmark className="mr-1" />
                    Location
                  </p>
                )}

                {isExpertiseMatch && (
                  <p className="flex items-center text-xs">
                    <IoMdCheckmark className="mr-1" />
                    Expertise
                  </p>
                )}

                <p className="flex items-center text-xs">
                  <IoMdCheckmark className="mr-1" />
                  Experience
                </p>
              </div>
            </div>
            <div>
              <p className="flex items-center mb-1 font-semibold text-sm	">
                <CiLocationOn size={20} className="mr-2" />
                {/* Location of the job */}
                {data?.jobCities == "" || data?.jobCities == null ? (
                  <>Location not disclosed</>
                ) : (
                  <>{data?.jobCities.join(", ")}</>
                )}
              </p>
            </div>
          </div>
          {/* right side */}
          <div className="w-full md:w-50 px-2 sm:mt-0 md:mt-5">
            <div className="flex ">
              <div className="w-full md:w-75">
                <p className="flex items-center mb-1 font-semibold text-sm">
                  <PiSuitcaseLight size={20} className="mr-2" />
                  {data?.jobExperienceMin == "" ||
                  data?.jobExperienceMin == null ? (
                    <>0 - 7 years of experience</>
                  ) : (
                    <>
                      {data?.jobExperienceMin} - {data?.jobExperienceMax} years
                      of experience
                    </>
                  )}
                </p>
                <p className="flex items-center mb-1 font-semibold text-sm">
                  <BiMessage size={20} className="mr-2" />
                  {data?.jobLanguages == "" || data?.jobLanguages == null ? (
                    <>No language provided</>
                  ) : (
                    <>{data?.jobLanguages.join(", ")}</>
                  )}
                </p>
              </div>

              {applied == false &&
                !data?.applicants?.includes(initialData.uid) &&
                !rejected &&
                !data?.rejectedby?.includes(initialData.uid) && (
                  <div
                    className="flex font-bold text-[#8186FF] bg-[#8154FF4F] hover:cursor-pointer hover:bg-[#8254ff17]"
                    style={{
                      width: "138px",
                      height: "36px",
                      position: "absolute",
                      top: "0px",
                      right: "0px",
                      borderStartEndRadius: "5px",
                      borderEndStartRadius: "10px",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                    onClick={(e) => {
                      e.stopPropagation() // Stop the propagation here
                      // e.preventDefault();
                      handleShowModal() // Or your button click logic here
                    }}
                  >
                    Apply
                  </div>
                )}
            </div>
            <div
              class="list-disc h-[40px] my-2 text-sm pb-2 overflow-y-scroll text-[#131F2E]/70"
              dangerouslySetInnerHTML={{
                __html: job_description,
              }}
            />
            {origin !== "matches" &&
              applied == false &&
              !data?.applicants?.includes(initialData.uid) && (
                <div className=" cursor-pointer ">
                  <div className="flex items-center justify-center mt-4 gap-x-3 w-full ">
                    {marked == false && (
                      <Button
                        size="sm"
                        className={
                          rejected
                            ? "w-50 flex justify-center items-center gap-x-2 bg-[#ff787863] border-1 border-[#ff7878] text-[#ff7878] px-3 cursor-pointer hover:bg-transparent hover:border-[#ff7878] hover:text-[#ff7878] font-bold"
                            : "w-50 flex justify-center items-center gap-x-2 bg-[#ff787863] border-1 border-[#ff7878] text-[#ff7878] px-3 cursor-pointer hover:bg-transparent hover:border-[#ff7878] hover:text-[#ff7878] font-bold"
                        }
                        onClick={(e) => {
                          e.stopPropagation() // Stop the propagation here
                          // Your button click logic here
                          jobReject(data.uid, initialData.uid)
                          setRejected(!rejected)

                          e.preventDefault()
                        }}
                      >
                        <img src={IMAGES.RED_CROSS} width={14} height={17} />
                        {!rejected ? "Reject" : "Rejected"}
                      </Button>
                    )}
                    {!rejected && (
                      <Button
                        size="sm"
                        className="w-50 flex justify-center items-center gap-x-2 bg-[#cfefca] border-1 border-[#81d674] text-[#81d674] px-3 cursor-pointer hover:bg-transparent hover:border-[#81d674] hover:text-[#81d674] font-bold"
                        onClick={(e) => {
                          // e.stopPropagation() // Stop the propagation here
                          // // e.preventDefault();
                          // handleShowModal() // Or your button click logic here

                          setMarked(!marked)
                          jobSave(data.uid, initialData.uid)
                        }}
                      >
                        <img src={IMAGES.GREEN_HEART} width={14} height={17} />
                        {/* {!data?.applicants?.includes(initialData.uid) && applied == false ? */}
                        {marked == false ? "Like" : "Liked"}
                      </Button>
                    )}
                  </div>

                  <div
                    className="flex mt-3 items-center justify-center text-center"
                    onClick={() => {
                      navigateToConversation(data.userid, data.uid)
                    }}
                  >
                    <p className="flex items-center mb-1 cursor-pointer text-[#131F2E]/60">
                      <TbMessageCircle
                        size={20}
                        className="mr-2 hover:font-bold hover:text-black"
                      />
                      Ask a question
                    </p>
                  </div>
                </div>
              )}

            {(origin == "matches" ||
              applied ||
              data?.applicants?.includes(initialData.uid)) && (
              <div
                className="flex items-center justify-center text-[#3BAF4F] font-bold  "
                onClick={() => {
                  navigateToConversation(data.userid, data.uid)
                }}
              >
                <div className="rounded bg-[#81D67461] px-3 py-1 border-solid border-[#3BAF4F] border-1 hover:bg-[#81D67499] mb-[38px]">
                  <p className="flex items-center  cursor-pointer">
                    <TbMessageCircle
                      size={20}
                      className="mr-2 hover:font-bold hover:text-black"
                    />
                    Ask a question
                  </p>
                </div>
              </div>
            )}

            {/* ) : (
          <div className="flex flex-column items-center ">Job rejected</div>
        )} */}
            <ModalCandidateJobApply
              showModal={showModal}
              handleClose={handleCloseModal}
              setApplied={setApplied}
            />
          </div>
        </div>
      </TinderCard>
    </>
  )
}

export default CandidateJobCardHorizontal
