import { useSelector } from "react-redux"
import { userId as selectUserId } from "../app/userRedux/userSlice"
import { db } from "../config/firebase"
import { userSelector } from "../app/userRedux/userSlice"
import { useEffect, useState } from "react"
import {
    collection,
    doc,
    getDoc,
    onSnapshot,
    query,
    where,
  } from "firebase/firestore"

const useFetchMyApplications = (jobId, origin) => {
    const user = useSelector(userSelector)
    const userId = useSelector(selectUserId)
    const [applications, setApplications] = useState()

    
    useEffect(() => {
        const unsubscribe = onSnapshot(
            query(
                collection(db, "applications"),
                user.isEmployer ?
                where("job_id", "==", doc(db, "jobs", jobId))
                :
                where("user_id", "==", doc(db, "users", userId))
            ),
            async (snapshot) => {
    
                const applicationsPromise = await Promise.all(
                    snapshot.docs.map(async (applicationDocument) => {
    
                        // Add your conditional check here
                        if (origin !== "matches" && applicationDocument.data().accepted === true) {
                            return; // Skip this document
                        }else if (origin == "matches" && applicationDocument.data().accepted === false){
                            return
                        }else if(applicationDocument.data().rejected === true){
                            return
                        }
    
                        const jobDoc = await getDoc(
                            user.isEmployer
                                ? applicationDocument.data().user_id
                                : applicationDocument.data().job_id,
                        )
    
                        return {
                            ...jobDoc.data(),
                            applicationData: applicationDocument.data()
                        }
                    }),
                )
                setApplications(applicationsPromise.filter(Boolean)) // Filter out null or undefined elements
            },
        )
    
        return unsubscribe
    }, [user.uid, user.isEmployer, origin])



    if(origin !== "matches"){
        return { applications, loadingApplications: applications === undefined }
    }else{
        return { matchesApplications:applications, loadingMatchesApplications: applications === undefined }

    }
   
}

export default useFetchMyApplications
