import { yupResolver } from "@hookform/resolvers/yup"
import { useState } from "react"
import { useForm } from "react-hook-form"
import * as yup from "yup"
import { registerEmployer } from "../../app/functions.js"
import { IMAGES } from "../../constants/images.jsx"
import CredentialsStep from "./CredentialsStep.jsx"
import FirmStep from "./FirmStep.jsx"
import styles from "./RecruiterRegister.module.css"
import UserStep from "./UserStep.jsx"
import { PiCaretCircleLeftFill } from "react-icons/pi"
import { Link } from "react-router-dom"
import "yup-phone";

const steps = {
  CREDENTIALS: "credentials",
  USER: "user",
  FIRM: "firm",
}
const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/

const validationSchema = yup
  .object()
  .shape({
    email: yup
      .string()
      .email("Please enter a valid email")
      .required("Please enter an email"),
    password: yup
      .string()
      .required("Please enter an password")
      .min(6, "The password must have at least 6 characters"),
    passwordConfirm: yup
      .string()
      .required("Please confirm your password")
      .when(["password"], (password, schema) =>
        password !== ""
          ? schema.equals(password, "Passwords do not match")
          : schema,
      ),
    firstName: yup.string().required("Please enter your first name"),
    lastName: yup.string().required("Please enter last name"),
    // phoneNumber: yup.string().required('Phone number is not valid'),

    role: yup.string().required("Please enter your role in the company"),
    firmName: yup.string().required("Please enter firm name"),
    firmFoundedYear: yup.string().required("Please enter firm founding year"),
    firmLogo: yup
      .mixed()
      .test(
        "required",
        "Please upload a logo",
        (value) => value instanceof FileList && value.length === 1,
      )
      .test("size", "The file is too big! Compress it below 10MB", (value) => {
        if (value instanceof FileList && value.length === 1) {
          return value.item(0).size <= 10485760
        }
        return true
      }),
    firmPrimaryLocation: yup.string().required("Please enter your firm city"),
    firmOtherLocations: yup.array().of(yup.string()),
    firmUrl: yup.string().url().required("Please enter firm website URL"),
    firmSize: yup.string().required("Please select firm size"),
    firmDescription: yup.string().required("Please enter firm bio"),
  })
  .required()

const RecruiterRegister = () => {
  const [activeStep, activateStep] = useState(steps.CREDENTIALS)

  const {
    control,
    formState: { errors, isSubmitting },
    handleSubmit: handleFormSubmit,
    register,
    setError,
    trigger,
    watch,
  } = useForm({
    mode: "onBlur",
    defaultValues: {
      email: "",
      password: "",
      passwordConfirm: "",
      firstName: "",
      lastName: "",
      phoneNumber: "",
      role: "",
      firmName: "",
      firmFoundedYear: "",
      firmLogo: undefined,
      firmPrimaryLocation: "",
      firmOtherLocations: [],
      firmUrl: "",
      firmSize: "",
      firmDescription: "",
    },
    resolver: yupResolver(validationSchema),
  })

  const handleSubmit = handleFormSubmit(async (formData) => {
    const { email, password, ...employerData } = formData

    try {
      await registerEmployer(email, password, employerData)
    } catch (error) {
      if (error.code === "auth/email-already-in-use") {
        setError("email", {
          type: "email_in_use",
          message: "Email already in use",
        })
        activateStep(steps.CREDENTIALS)
      } else {
        throw error
      }
    }
  })

  return (
    <div className={styles.container}>
      <div className={styles.innercontainer}>
        {/* <div className={styles.boxcontainerBtn}>
          <Link to="/" className="flex items-center text-white">
            <PiCaretCircleLeftFill
              size={38}
              className="mr-2 text-blue-400 cursor-pointer duration-75 hover:scale-110"
            />
            <p className=""> Back to landing page</p>
          </Link>
        </div> */}
        <div className="flex flex-column justify-center items-center bg-white border-5 border-t-yellow-300 rounded-md p-3">
          <div>
            <img
              src={IMAGES.JLFULL}
              className="w-18 h-10 mb-0"
              alt="JustLawyers logo"
            />
            <h3 className="w-75 flex text-center items-center font-bold uppercase text-[#738397] justify-center">
              Employer
            </h3>
          </div>
          {/* <div className="my-3 text-center">
          <h2 className="font-bold text-4xl mb-2">Welcome to Just Lawyer</h2>
          <p>Create and account to get Started</p>
        </div> */}
          <div className="flex flex-column w-[80%]">
            <div className="flex flex-column">
              <form onSubmit={handleSubmit}>
                {activeStep === steps.CREDENTIALS && (
                  <CredentialsStep
                    errors={errors}
                    onNext={() => {
                      activateStep(steps.USER)
                    }}
                    register={register}
                    trigger={trigger}
                  />
                )}
                {activeStep === steps.USER && (
                  <UserStep
                    errors={errors}
                    onNext={() => {
                      activateStep(steps.FIRM)
                    }}
                    onPrev={() => {
                      activateStep(steps.CREDENTIALS)
                    }}
                    register={register}
                    trigger={trigger}
                  />
                )}
                {activeStep === steps.FIRM && (
                  <FirmStep
                    control={control}
                    errors={errors}
                    isSubmitting={isSubmitting}
                    onPrev={() => {
                      activateStep(steps.USER)
                    }}
                    register={register}
                    watch={watch}
                  />
                )}
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default RecruiterRegister
