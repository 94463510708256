import { Form } from "react-bootstrap"
import { MdOutlineKeyboardArrowLeft } from "react-icons/md"
import 'react-phone-number-input/style.css'
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'


const UserStep = ({ errors, onNext, onPrev, register, trigger }) => {
  const handlePrevStepClick = () => {
    onPrev()
  }

  const handleNextStepClick = async () => {
    const isValid = await trigger([
      "firstName",
      "lastName",
      "phoneNumber",
      "role",
    ])

    if (isValid) {
      onNext()
    }
  }

  return (
    <>
      <Form.Group className="mb-3" controlId="firstName">
        <Form.Label className="font-bold">First name*</Form.Label>
        <Form.Control
          required={true}
          type="text"
          placeholder="Enter your name"
          {...register("firstName")}
        />
        {errors.firstName && (
          <p className="text-red-400">{errors.firstName.message}</p>
        )}
      </Form.Group>
      <Form.Group className="mb-3" controlId="lastName">
        <Form.Label className="font-bold">Last name*</Form.Label>
        <Form.Control
          type="text"
          placeholder="Enter your name"
          {...register("lastName")}
        />
        {errors.lastName && (
          <p className="text-red-400">{errors.lastName.message}</p>
        )}
      </Form.Group>
      <Form.Group className="mb-3" controlId="phoneNumber">
        <Form.Label className="font-bold">Phone number*</Form.Label>
        <div className="w-full"> {/* Container with 100% width */}
          <PhoneInput
            country={'be'}
            placeholder="Enter your phone number"
            {...register("phoneNumber")}
            onChange={e=> console.log(typeof e)}
          />
        </div>
        {errors.phoneNumber && (
          <p className="text-red-400">{errors.phoneNumber.message}</p>
        )}
      </Form.Group>
      <Form.Group className="mb-3" controlId="role">
        <Form.Label className="font-bold">Role in the Company*</Form.Label>
        <Form.Select {...register("role")}>
          <option value="">Select Role</option>
          <option value="owner">COO</option>
          <option value="managing partner">Managing Partner</option>
          <option value="partner">Partner</option>
          <option value="hr">HR</option>
          <option value="other">other</option>
        </Form.Select>
        {errors.role && <p className="text-red-400">{errors.role.message}</p>}
      </Form.Group>
      <div className="flex mt-5 mb-3">
        <button
          type="button"
          className="flex-2 px-4 py-1 rounded-md flex items-center cursor-pointer hover:bg-gray-300 hover:text-white"
          onClick={handlePrevStepClick}
        >
          <MdOutlineKeyboardArrowLeft className="mr-2" /> Back
        </button>
        <p className="flex-1 text-center  py-1 ">1/2</p>
        <button
          type="button"
          className="flex-2 bg-[#868BFF] border-1 border-[#868BFF] px-4 py-1 rounded-md flex items-center cursor-pointer hover:bg-transparent hover:text-[#868BFF]"
          onClick={handleNextStepClick}
        >
          Next
        </button>
      </div>
    </>
  )
}

export default UserStep
