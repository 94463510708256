import React, { useState, useEffect } from "react"
import { Modal, Button } from "react-bootstrap"

import styles from "./CandidateData.module.css"
import { useNavigate, useLocation } from "react-router-dom"
import { IMAGES } from "../../constants/images.jsx"
import download_icon from "../../assets/icons/Upload.svg"
import { storage } from "../../config/firebase.js"

import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage"

// import DropdownMultiselect from "react-multiselect-dropdown-bootstrap"
import { languagesOptions, citiesOptions } from "../../constants/Data.js"
import Select from "react-select"
import { OverlayTrigger, Tooltip } from "react-bootstrap"
import CreatableSelect from "react-select/creatable"
import { useDispatch, useSelector } from "react-redux"
import { updateInitialData } from "../../app/initialDataSlice"

export default function Content1() {
  const dispatch = useDispatch()
  const initialData = useSelector((state) => state.initialData)

  const [profileImg, setProfileImg] = useState("")
  const [percentPI, setPercentPI] = useState(0)
  const [fileReady, setFileReady] = useState(true)
  const [filePicked, setIsFilePicked] = useState(false)
  const [uploadedFile, setUploadedFile] = useState(false)
  const { state } = useLocation()
  const loggedUser = useSelector((state) => state.data.value)
  const activeClass =
    "bg-[#868BFF] py-2 rounded-md w-[45%] border-1 border-[#868BFF] cursor-pointer hover:bg-transparent"
  const casualClass =
    "py-2 rounded-md w-[45%] border-1 border-[#868BFF] cursor-pointer hover:bg-[#868BFF]"

  const handleChangePI = (event) => {
    event.preventDefault()
    setFileReady(false)
    const file = event.target.files[0]
    if (!file) {
      return
    } else {
      if (event.target.files[0].size > 10485760) {
        alert("The file is too big! Compress it below 10MB")
      } else {
        // setProfileImg(event.target.files[0])
        setIsFilePicked(true)
        const storageRef = ref(
          storage,
          `profileImgs/${event.target.files[0].size}${file.name}`,
        )
        const uploadTask = uploadBytesResumable(storageRef, file)

        uploadTask.on(
          "state_changed",
          (snapshot) => {
            const progress = Math.round(
              (snapshot.bytesTransferred / snapshot.totalBytes) * 100,
            )
            // console.log(event.target.files[0])
            // console.log(profileImg)
            setPercentPI(progress)
          },
          (error) => {
            alert(error)
          },
          () => {
            getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
              setUploadedFile(downloadURL)
              setProfileImg(downloadURL)
              dispatch(updateInitialData({ profileImg: downloadURL }))
              setFileReady(true)
            })
          },
        )
      }
    }
  }

  const handleChangeLanguages = (selectedOptions) => {
    const newLanguages = selectedOptions
      ? selectedOptions.map((option) => option.label)
      : []
    dispatch(updateInitialData({ selectedLanguages: newLanguages }))
  }
  const handleChangeBonusLanguages = (selectedOptions) => {
    const newLanguages = selectedOptions
      ? selectedOptions.map((option) => option.label)
      : []
    dispatch(updateInitialData({ selectedBonusLanguages: newLanguages }))
  }

  const nextWindow = (e) => {
    navigate("/candidatedata5")
  }

  const navigate = useNavigate()

  const tooltipContent =
    "Select your job-seeking status: Actively looking or Casually looking. When you (potentially) match with a job, law firms will see your current status. You can change this whenever you want."

  return (
    <div className={styles.innercontainer}>
      <div className="text-start my-2">
        <p>
          Fill our your profile to unlock personalized matches tailored just for
          you !
        </p>
        <p className=" my-2">Let's start with personal details</p>
        <p className=" my-2 text-gray-500">
          Don't worry! Law firms won't have access to this information unless
          you give your permission.
        </p>
      </div>
      <p className={styles.p}>First Name*</p>

      <input
        className={styles.input}
        placeholder="Type first name"
        value={initialData.name}
        onChange={(e) => dispatch(updateInitialData({ name: e.target.value }))}
      />

      <p className={styles.p}>Last Name*</p>
      <input
        className={styles.input}
        placeholder="Type last name"
        value={initialData.surname}
        onChange={(e) =>
          dispatch(updateInitialData({ surname: e.target.value }))
        }
      />
      <div className="w-full mt-2">
        <label>City</label>
        <CreatableSelect
          isClearable
          className="basic-single"
          classNamePrefix="select"
          defaultValue={""}
          name="location"
          options={citiesOptions}
          onChange={(selectedOption) => {
            const newLocation = selectedOption ? selectedOption.label : ""
            dispatch(updateInitialData({ location: newLocation }))
          }}
          value={
            initialData.location
              ? {
                  label: initialData.location,
                  value: initialData.location,
                }
              : null
          }
        />
      </div>

      <div className="w-full mt-2">
        <label>Languages</label>
        <CreatableSelect
          isClearable
          defaultValue={[]}
          isMulti
          name="languages"
          options={languagesOptions}
          className="basic-multi-select"
          classNamePrefix="select"
          value={initialData.selectedLanguages.map((lang) => ({
            label: lang,
            value: lang,
          }))}
          onChange={handleChangeLanguages}
        />
      </div>
      <div className="flex flex-column w-100">
        <p className="font-bold">Bonus Languages</p>
        <div className="w-full mb-2">
          <CreatableSelect
            isClearable
            className="basic-multi-select"
            classNamePrefix="select"
            defaultValue={[]}
            isMulti
            placeholder="Select Languages"
            name="languages"
            options={languagesOptions}
            value={
              initialData.selectedBonusLanguages
                ? initialData.selectedBonusLanguages.map((lang) => ({
                    label: lang,
                    value: lang,
                  }))
                : null
            }
            onChange={handleChangeBonusLanguages}
          />
        </div>
      </div>

      <p className={styles.p}>Profile Image*</p>
      <div className="flex justify-between w-100 my-1">
        <label
          for="profileImg"
          className="flex items-center justify-center bg-[#374b64] rounded-md text-white py-2 cursor-pointer w-100"
        >
          <img src={download_icon} className="mr-3" />
          Click to upload profile Image | {percentPI} "% done"
        </label>
        <input
          id="profileImg"
          type="file"
          name="profileImg"
          className="invisible w-[10px]"
          onChange={handleChangePI}
          accept=".png, .jpg, .jpeg"
        />
      </div>
      <div className="w-full">
        <p className={styles.p}>About me</p>
        <textarea
          className={styles.input}
          placeholder="Write a description"
          value={initialData.description}
          onChange={(e) =>
            dispatch(updateInitialData({ description: e.target.value }))
          }
        />
      </div>
      <div className="mt-3 w-full">
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <p className="text-start">What is your status? &#8205; </p>
          <OverlayTrigger
            placement="right"
            overlay={
              <Tooltip placement="right" className="in" id="tooltip-right">
                {tooltipContent}
              </Tooltip>
            }
          >
            <img src={IMAGES.QUESTIONMARK} height={17.5} width={17.5} />
          </OverlayTrigger>
        </div>
        <div className="flex justify-between mt-2">
          <button
            className={
              initialData.searchingStatus === "actively looking"
                ? activeClass
                : casualClass
            }
            onClick={() => {
              dispatch(
                updateInitialData({
                  searchingStatus: "actively looking",
                }),
              )
            }}
          >
            Actively looking
          </button>
          <button
            className={
              initialData.searchingStatus === "actively looking"
                ? casualClass
                : activeClass
            }
            onClick={() => {
              dispatch(
                updateInitialData({
                  searchingStatus: "casually looking",
                }),
              )
            }}
          >
            Casually looking
          </button>
        </div>
      </div>
    </div>
  )
}
