import React from 'react';
import { Modal, Button } from 'react-bootstrap';

const RejectModal = ({ show, onHide, onReject }) => {
  return (
    <Modal show={show} onHide={onHide} centered>
      <Modal.Header closeButton>
        <Modal.Title className="text-black">Confirmation</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p className="text-gray-700">Are you sure you would like to reject this candidate?</p>
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="outline-secondary"
          onClick={onHide}
          className="mr-2 bg-blue-500 hover:bg-blue-700 text-white"
        >
          No
        </Button>
        <Button
          variant="danger"
          onClick={()=>onReject()}
          className="bg-red-500 hover:bg-red-700 text-white"
        >
          Yes
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default RejectModal;
