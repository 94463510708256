import { collection, where } from "firebase/firestore"
import { useMemo } from "react"
import { useCollection } from "react-firebase-hooks/firestore"
import { db } from "../config/firebase"

const useFetchLikedJobs = (uid) => {
  const [jobsDocuments, jobsAreLoading] = useCollection(
    collection(db, "users"),
    where("savedby", "array-contains", uid )
  )

  const parsedJobs = useMemo(
    () =>
        jobsDocuments?.docs.map((jobsDocument) => ({
        id: jobsDocument.id,
        ...jobsDocument.data(),
      })),
    [jobsDocuments],
  )

  return { jobsLiked: parsedJobs, jobsAreLoading }
}

export default useFetchLikedJobs
