import { createSlice } from "@reduxjs/toolkit"

const initialState = {
  initialising: true,
  userState: false,
  adminState: false,
  userData: null,
}

export const userSlice = createSlice({
  name: "user",
  initialState,

  reducers: {
    userstate: (state) => {
      state.userState = true
    },
    adminState: (state) => {
      state.adminState = true
    },
    saveUser: (state, action) => {
      state.userData = action.payload
    },
    initialise: (state, action) => {
      state.initialising = false
      state.userData = action.payload?.authData ?? initialState.userData
    },
    login: (state, action) => {
      state.userData = action.payload.authData
    },
    logout: (state) => {
      state.userData = null
    },
  },
})

export const { userstate, adminState, login, logout, initialise } =
  userSlice.actions

export const userState = (state) => state.user.userState
const userData = (state) => state.user.userData

export const isInitialising = (state) => state.user.initialising

export const isGuest = (state) => userData(state) === null
export const isAuthenticated = (state) => userData(state) !== null

export const isCandidate = (state) => Boolean(!userData(state)?.isEmployer)
export const isEmployer = (state) => Boolean(userData(state)?.isEmployer)

export const userSelector = (state) => userData(state)
export const userId = (state) => userData(state).uid

export default userSlice.reducer
