import { isToday, isYesterday } from "date-fns"
import React, { useEffect, useState } from "react"
import Button from "react-bootstrap/Button"
import { BiMessage } from "react-icons/bi"
import { CiLocationOn } from "react-icons/ci"
import { IoMdCheckmark } from "react-icons/io"
import { IoBookmark, IoBookmarkOutline } from "react-icons/io5"
import { MdOutlineMenu } from "react-icons/md"
import { PiSuitcaseLight } from "react-icons/pi"
import { TbMessageCircle } from "react-icons/tb"
import { useDispatch, useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"
import { jobReject, jobSave } from "../app/functions.js"
import { updateInitialData } from "../app/initialDataSlice.js"
import { IMAGES } from "../constants/images.jsx"
import useNavigateToConversation from "../hooks/useNavigateToConversation.js"
import ModalCandidateJobApply from "./modalCandidateJobApply/ModalCandidateJobApply.jsx"
import TinderCard from "react-tinder-card"

const CandidateJobBoardCard = ({ job, origin, onMessage }) => {
  const initialData = useSelector((state) => state.initialData)
  const [marked, setMarked] = useState(false)
  const [rejected, setRejected] = useState(false)
  const navigate = useNavigate()
  const [showModal, setShowModal] = useState(false)
  const [applied, setApplied] = useState(false)
  const [candidateToBeMessaged, setCandidateToBeMessaged] = useState(null)

  const dispatch = useDispatch()

  const { navigateToConversation } = useNavigateToConversation()

  const isLocationMatch =
    Array.isArray(job?.jobCities) &&
    Array.isArray(initialData?.cities) &&
    initialData?.cities?.some((loc) => job.jobCities?.includes(loc))

  const isExpertiseMatch =
    Array.isArray(job?.jobExpertise) &&
    Array.isArray(initialData?.expertise) &&
    initialData?.expertise?.some((expertise) =>
      job?.jobExpertise?.includes(expertise),
    )

  const handleCloseModal = () => {
    console.log("Closing")
    setShowModal(false)
  }
  const handleShowModal = () => {
    dispatch(updateInitialData({ selectedJob: job }))
    setShowModal(true)
  }

  const goToJob = () => {
    const jobUid = job.uid
    navigate(`/candidatejobdetailspage/${jobUid}`)
  }

  let job_description = job?.jobDescription?.split(".")[0]

  const formatDate = (dateString) => {
    const formattedDate = new Date(dateString)

    // Check if the date is today or yesterday
    if (isToday(formattedDate)) {
      return "Today"
    } else if (isYesterday(formattedDate)) {
      return "Yesterday"
    } else {
      // If not today or yesterday, display the actual date
      const options = { year: "numeric", month: "long", day: "numeric" }
      return formattedDate.toLocaleDateString(undefined, options)
    }
  }

  useEffect(() => {
    if (job.savedby) {
      checkIfMarked()
    }
    if (job.rejectedby) {
      checkIfRejected()
    }
  }, [job])

  const checkIfMarked = () => {
    if (job?.savedby?.includes(initialData?.uid)) {
      setMarked(true)
    }
  }
  const checkIfRejected = () => {
    if (job?.rejectedby?.includes(initialData?.uid)) {
      setRejected(true)
    }
  }

  const onSwipe = (direction) => {
    switch (direction) {
      case "left":
        console.log("swiped to left")
        jobReject(job.uid, initialData.uid)
        setRejected(!rejected)
        break
      case "right":
        console.log("swiped to right")
        setMarked(!marked)
        jobSave(job.uid, initialData.uid)
        break
      case "up":
        console.log("swiped to up")
        // Call the goToJob function when swiped up
        handleShowModal()

        break
      case "down":
        console.log("swiped to down")
        goToJob()
        break
      default:
        console.log("Invalid swipe direction")
    }
  }
  const springConfig = {
    tension: 500, // Adjust the tension of the spring animation
    friction: 100, // Adjust the friction of the spring animation
    mass: 2, // Adjust the mass of the spring animation
  }

  return (
    <>
      <div 
      class=" hidden  bg-white  rounded-md px-4 py-3  text-sm mb-3 md:flex flex-col justify-between text-[#19293D] cursor-pointer " 
      >
        {/* image and info */}
        <div 
        className="flex flex-col justify-between " 
        onClick={() => goToJob()}>
          <div className="flex justify-between ">
            <div className="flex items-center">
              {job?.firmLogo == "" ? (
                <img
                  src={IMAGES.FIRM_LOGO}
                  className="w-10 h-10 rounded-full"
                  alt=""
                />
              ) : (
                <img
                  src={job?.firmLogo}
                  className="w-10 h-10 rounded-full"
                  alt=""
                />
              )}
              <p class="ml-2 font-medium">
                <>{job?.companyName}</>
              </p>
            </div>

            {applied == false &&
              !job?.applicants?.includes(initialData.uid) &&
              !rejected &&
              !job?.rejectedby?.includes(initialData.uid) && (
                <div
                  className="flex font-bold text-[#8186FF] bg-[#8154FF4F] hover:cursor-pointer hover:bg-[#8254ff17]"
                  style={{
                    width: "138px",
                    height: "36px",
                    position: "relative",
                    top: "-1rem",
                    right: "-1.5rem",
                    borderStartEndRadius: "5px",
                    borderEndStartRadius: "10px",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                  onClick={(e) => {
                    e.stopPropagation() // Stop the propagation here
                    // e.preventDefault();
                    handleShowModal() // Or your button click logic here
                  }}
                >
                  Apply
                </div>
              )}
          </div>

          <div onClick={() => goToJob()}>
            <div className="border-b-2 border-gray-300 mt-2">
              <p className="font-bold">
                {job?.jobTitle === "" ? <>Job title</> : <>{job?.jobTitle}</>}
              </p>
              <p className="text-xs my-2 text-[#131F2E]/50">
                Created: {formatDate(job?.createdAt)}
              </p>

              {/* <Badge pill bg="" className="px-3 py-1 bg-[#D47DF8]">
                {data?.matchPercentage} match
              </Badge> */}

              <div className="flex items-center justify-between mt-2 mb-3 px-2 text-[#1B1F87]/50">
                {isLocationMatch && (
                  <p
                    className="flex items-center"
                    style={{ fontSize: "xx-small" }}
                  >
                    <IoMdCheckmark className="mr-1" />
                    Location
                  </p>
                )}

                {isExpertiseMatch && (
                  <p
                    className="flex items-center"
                    style={{ fontSize: "xx-small" }}
                  >
                    <IoMdCheckmark className="mr-1" />
                    Expertise
                  </p>
                )}

                <p
                  className="flex items-center"
                  style={{ fontSize: "xx-small" }}
                >
                  <IoMdCheckmark className="mr-1" />
                  Experience
                </p>
              </div>
            </div>
            <div className="my-3 font-bold">
              <p className="flex items-center mb-1">
                <CiLocationOn size={20} className="mr-2" />

                {job?.jobCities == "" || job?.jobCities == null ? (
                  <>Location not disclosed</>
                ) : (
                  <>{job?.jobCities.join(", ")}</>
                )}
              </p>
              <p className="flex items-center mb-1">
                <PiSuitcaseLight size={20} className="mr-2" />
                {job?.jobExperienceMin == "" ||
                job?.jobExperienceMin == null ? (
                  <>0 - 7 years of experience</>
                ) : (
                  <>
                    {job?.jobExperienceMin} - {job?.jobExperienceMax} years of
                    experience
                  </>
                )}
              </p>
              <p className="flex items-center mb-1">
                <BiMessage size={20} className="mr-2" />
                {job?.jobLanguages == "" || job?.jobLanguages == null ? (
                  <>No language provided</>
                ) : (
                  <>{job?.jobLanguages.join(", ")}</>
                )}
              </p>
              <p className="flex items-center mb-1">
                <MdOutlineMenu size={20} className="mr-2" />
                {job?.jobExpertise == "" || job?.jobExpertise == null ? (
                  <>No expertise specified</>
                ) : (
                  <>{job?.jobExpertise.join(", ")}</>
                )}
              </p>
            </div>
            <div
              class="list-disc h-[120px] mb-2 overflow-y-scroll text-[#131F2E]/70"
              dangerouslySetInnerHTML={{
                __html: job_description,
              }}
            />
          </div>
        </div>

        {origin !== "matches" &&
          applied == false &&
          !job?.applicants?.includes(initialData.uid) && (
            <div className=" cursor-pointer ">
              <div className="flex items-center justify-center mt-4 gap-x-3 w-full ">
                {marked == false && (
                  <Button
                    size="sm"
                    className={
                      rejected
                        ? "w-50 flex justify-center items-center gap-x-2 bg-[#ff787863] border-1 border-[#ff7878] text-[#ff7878] px-3 cursor-pointer hover:bg-transparent hover:border-[#ff7878] hover:text-[#ff7878] font-bold"
                        : "w-50 flex justify-center items-center gap-x-2 bg-[#ff787863] border-1 border-[#ff7878] text-[#ff7878] px-3 cursor-pointer hover:bg-transparent hover:border-[#ff7878] hover:text-[#ff7878] font-bold"
                    }
                    onClick={(e) => {
                      e.stopPropagation() // Stop the propagation here
                      // Your button click logic here
                      jobReject(job.uid, initialData.uid)
                      setRejected(!rejected)
                      e.preventDefault()
                    }}
                  >
                    <img src={IMAGES.RED_CROSS} width={14} height={17} />
                    {!rejected ? "Reject" : "Rejected"}
                  </Button>
                )}
                {!rejected && (
                  <Button
                    size="sm"
                    className="w-50 flex justify-center items-center gap-x-2 bg-[#cfefca] border-1 border-[#81d674] text-[#81d674] px-3 cursor-pointer hover:bg-transparent hover:border-[#81d674] hover:text-[#81d674] font-bold"
                    onClick={(e) => {
                      // e.stopPropagation() // Stop the propagation here
                      // // e.preventDefault();
                      // handleShowModal() // Or your button click logic here

                      setMarked(!marked)
                      jobSave(job.uid, initialData.uid)
                    }}
                  >
                    <img src={IMAGES.GREEN_HEART} width={14} height={17} />
                    {/* {!job?.applicants?.includes(initialData.uid) && applied == false ? */}
                    {marked == false ? "Like" : "Liked"}
                  </Button>
                )}
              </div>

              <div
                className="flex mt-3 items-center justify-center text-center"
                onClick={() => {
                  navigateToConversation(job.userid, job.uid)
                }}
              >
                <p className="flex items-center mb-1 cursor-pointer text-[#131F2E]/60 hover:font-bold hover:text-black">
                  <TbMessageCircle size={20} className="mr-2" />
                  Ask a question
                </p>
              </div>
            </div>
          )}

        {(origin == "matches" ||
          applied ||
          job?.applicants?.includes(initialData.uid)) && (
          <div
            className="flex items-center justify-center text-[#3BAF4F] font-bold  "
            onClick={() => {
              navigateToConversation(job.userid, job.uid)
            }}
          >
            <div className="rounded bg-[#81D67461] px-3 py-1 border-solid border-[#3BAF4F] border-1 hover:bg-[#81D67499] mb-[38px]">
              <p className="flex items-center  cursor-pointer hover:font-bold hover:text-black">
                <TbMessageCircle size={20} className="mr-2" />
                Ask a question
              </p>
            </div>
          </div>
        )}

        <ModalCandidateJobApply
          showModal={showModal}
          handleClose={handleCloseModal}
          setApplied={(e) => setApplied(e)}
          job_id={job?.uid}
        />
      </div>
      <TinderCard
        onSwipe={onSwipe}
        // onCardLeftScreen={() => onCardLeftScreen(index)}
        // preventSwipe={["right", "left"]}
        springConfig={springConfig}
        className="inline-block md:hidden absolute w-[90%]"
      >
        <div class=" bg-white  rounded-md px-4 py-3  text-sm mb-3 flex flex-col justify-between text-[#19293D] cursor-pointer ">
          {/* image and info */}
          <div className="flex flex-col justify-between ">
            <div className="flex justify-between ">
              <div className="flex items-center">
                {job?.firmLogo == "" ? (
                  <img
                    src={IMAGES.FIRM_LOGO}
                    className="w-10 h-10 rounded-full"
                    alt=""
                  />
                ) : (
                  <img
                    src={job?.firmLogo}
                    className="w-10 h-10 rounded-full"
                    alt=""
                  />
                )}
                <p class="ml-2 font-medium">
                  <>{job?.companyName}</>
                </p>
              </div>

              {applied == false &&
                !job?.applicants?.includes(initialData.uid) &&
                !rejected &&
                !job?.rejectedby?.includes(initialData.uid) && (
                  <div
                    className="flex font-bold text-[#8186FF] bg-[#8154FF4F] hover:cursor-pointer hover:bg-[#8254ff17]"
                    style={{
                      width: "138px",
                      height: "36px",
                      position: "relative",
                      top: "-1rem",
                      right: "-1.5rem",
                      borderStartEndRadius: "5px",
                      borderEndStartRadius: "10px",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                    onClick={(e) => {
                      e.stopPropagation() // Stop the propagation here
                      // e.preventDefault();
                      handleShowModal() // Or your button click logic here
                    }}
                  >
                    Apply
                  </div>
                )}
            </div>

            <div onClick={() => goToJob()}>
              <div className="border-b-2 border-gray-300 mt-2">
                <p className="font-bold">
                  {job?.jobTitle === "" ? <>Job title</> : <>{job?.jobTitle}</>}
                </p>
                <p className="text-xs my-2 text-[#131F2E]/50">
                  Created: {formatDate(job?.createdAt)}
                </p>

                {/* <Badge pill bg="" className="px-3 py-1 bg-[#D47DF8]">
                {data?.matchPercentage} match
              </Badge> */}

                <div className="flex items-center justify-between mt-2 mb-3 px-2 text-[#1B1F87]/50">
                  {isLocationMatch && (
                    <p
                      className="flex items-center"
                      style={{ fontSize: "xx-small" }}
                    >
                      <IoMdCheckmark className="mr-1" />
                      Location
                    </p>
                  )}

                  {isExpertiseMatch && (
                    <p
                      className="flex items-center"
                      style={{ fontSize: "xx-small" }}
                    >
                      <IoMdCheckmark className="mr-1" />
                      Expertise
                    </p>
                  )}

                  <p
                    className="flex items-center"
                    style={{ fontSize: "xx-small" }}
                  >
                    <IoMdCheckmark className="mr-1" />
                    Experience
                  </p>
                </div>
              </div>
              <div className="my-3 font-bold">
                <p className="flex items-center mb-1">
                  <CiLocationOn size={20} className="mr-2" />

                  {job?.jobCities == "" || job?.jobCities == null ? (
                    <>Location not disclosed</>
                  ) : (
                    <>{job?.jobCities.join(", ")}</>
                  )}
                </p>
                <p className="flex items-center mb-1">
                  <PiSuitcaseLight size={20} className="mr-2" />
                  {job?.jobExperienceMin == "" ||
                  job?.jobExperienceMin == null ? (
                    <>0 - 7 years of experience</>
                  ) : (
                    <>
                      {job?.jobExperienceMin} - {job?.jobExperienceMax} years of
                      experience
                    </>
                  )}
                </p>
                <p className="flex items-center mb-1">
                  <BiMessage size={20} className="mr-2" />
                  {job?.jobLanguages == "" || job?.jobLanguages == null ? (
                    <>No language provided</>
                  ) : (
                    <>{job?.jobLanguages.join(", ")}</>
                  )}
                </p>
                <p className="flex items-center mb-1">
                  <MdOutlineMenu size={20} className="mr-2" />
                  {job?.jobExpertise == "" || job?.jobExpertise == null ? (
                    <>No expertise specified</>
                  ) : (
                    <>{job?.jobExpertise.join(", ")}</>
                  )}
                </p>
              </div>
              <div
                class="list-disc h-[120px] mb-2 overflow-y-scroll text-[#131F2E]/70"
                dangerouslySetInnerHTML={{
                  __html: job_description,
                }}
              />
            </div>
          </div>

          {origin !== "matches" &&
            applied == false &&
            !job?.applicants?.includes(initialData.uid) && (
              <div className=" cursor-pointer ">
                <div className="flex items-center justify-center mt-4 gap-x-3 w-full ">
                  {marked == false && (
                    <Button
                      size="sm"
                      className={
                        rejected
                          ? "w-50 flex justify-center items-center gap-x-2 bg-[#ff787863] border-1 border-[#ff7878] text-[#ff7878] px-3 cursor-pointer hover:bg-transparent hover:border-[#ff7878] hover:text-[#ff7878] font-bold"
                          : "w-50 flex justify-center items-center gap-x-2 bg-[#ff787863] border-1 border-[#ff7878] text-[#ff7878] px-3 cursor-pointer hover:bg-transparent hover:border-[#ff7878] hover:text-[#ff7878] font-bold"
                      }
                      onClick={(e) => {
                        e.stopPropagation() // Stop the propagation here
                        // Your button click logic here
                        jobReject(job.uid, initialData.uid)
                        setRejected(!rejected)
                        e.preventDefault()
                      }}
                    >
                      <img src={IMAGES.RED_CROSS} width={14} height={17} />
                      {!rejected ? "Reject" : "Rejected"}
                    </Button>
                  )}
                  {!rejected && (
                    <Button
                      size="sm"
                      className="w-50 flex justify-center items-center gap-x-2 bg-[#cfefca] border-1 border-[#81d674] text-[#81d674] px-3 cursor-pointer hover:bg-transparent hover:border-[#81d674] hover:text-[#81d674] font-bold"
                      onClick={(e) => {
                        // e.stopPropagation() // Stop the propagation here
                        // // e.preventDefault();
                        // handleShowModal() // Or your button click logic here

                        setMarked(!marked)
                        jobSave(job.uid, initialData.uid)
                      }}
                    >
                      <img src={IMAGES.GREEN_HEART} width={14} height={17} />
                      {/* {!job?.applicants?.includes(initialData.uid) && applied == false ? */}
                      {marked == false ? "Like" : "Liked"}
                    </Button>
                  )}
                </div>

                <div
                  className="flex mt-3 items-center justify-center text-center"
                  onClick={() => {
                    navigateToConversation(job.userid, job.uid)
                  }}
                >
                  <p className="flex items-center mb-1 cursor-pointer text-[#131F2E]/60 hover:font-bold hover:text-black">
                    <TbMessageCircle size={20} className="mr-2" />
                    Ask a question
                  </p>
                </div>
              </div>
            )}

          {(origin == "matches" ||
            applied ||
            job?.applicants?.includes(initialData.uid)) && (
            <div
              className="flex items-center justify-center text-[#3BAF4F] font-bold  "
              onClick={() => {
                navigateToConversation(job.userid, job.uid)
              }}
            >
              <div className="rounded bg-[#81D67461] px-3 py-1 border-solid border-[#3BAF4F] border-1 hover:bg-[#81D67499] mb-[38px]">
                <p className="flex items-center  cursor-pointer hover:font-bold hover:text-black">
                  <TbMessageCircle size={20} className="mr-2" />
                  Ask a question
                </p>
              </div>
            </div>
          )}

          <ModalCandidateJobApply
            showModal={showModal}
            handleClose={handleCloseModal}
            setApplied={(e) => setApplied(e)}
            job_id={job?.uid}
          />
        </div>
      </TinderCard>
    </>
  )
}

export default CandidateJobBoardCard
