import React, { useEffect, useState } from "react"
import { DotLoader } from "react-spinners"
import {
  candidateAccept,
  fetchFilteredCandidates,
} from "../../app/functions.js"
import CandidateCard from "../../components/CandidateCard.jsx"
import Layout from "../../components/Layout.jsx"
import WhichJobModal from "../../components/modals/WhichJobModal.jsx"
import useFetchCandidates from "../../hooks/useFetchCandidates.js"
import useNavigateToConversation from "../../hooks/useNavigateToConversation.js"
import SearchBar from "../../components/SearchBar.jsx"

const RecruiterCandidatesPage = () => {
  const [candidateToBeLiked, setCandidateToBeLiked] = useState(null)
  const [candidateToBeMessaged, setCandidateToBeMessaged] = useState(null)
  const { candidates, candidatesAreLoading } = useFetchCandidates()
  const [candidatesData, setCandidatesData] = useState([])
  const [loading, setLoading] = useState(false)
  const { navigateToConversation } = useNavigateToConversation()

  const handleLike = (candidateId) => () => {
    setCandidateToBeLiked(candidateId)
  }

  const handleMessage = (candidateId) => () => {
    setCandidateToBeMessaged(candidateId)
  }

  // useEffect(() => {
  //   getUsersFiltered()
  // }, [loading])

  const getUsersFiltered = async (values) => {
    setLoading(true)

    const data = await fetchFilteredCandidates(values)

    setCandidatesData(data)
    setLoading(false)
  }

  return (
    <>
      <Layout>
        <div className="flex flex-column  text-white overflow-y-auto relative z-0 ">
          <div className="flex flex-column items-center my-5 mx-3 w-[90%] md:w-75 gap-x-5 ">
            <div className="mb-2 mt-4 text-start flex-start w-full lg:w-[70%] ">
              <h1 className="font-bold">Search for a job</h1>
              <SearchBar
                onSearch={getUsersFiltered}
                onClear={getUsersFiltered}
              />
            </div>
            <div className="flex flex-col justify-center gap-y-2 p-0 w-full lg:w-[70%] mt-4">
              <p>
                <span className="font-bold">{candidatesData?.length}</span>{" "}
                match your criteria
              </p>
              <div className="grid grid-cols-1 lg:grid-cols-2  gap-x-5 text-black relative w-full mt-4 align-center">
                {loading ? (
                  <DotLoader color="#868bff" />
                ) : (
                  candidatesData.map((candidate) => (
                    <CandidateCard
                      key={candidate.id}
                      data={candidate}
                      origin="candidateboard"
                      onLike={handleLike(candidate.id)}
                      onMessage={handleMessage(candidate.id)}
                    />
                  ))
                )}
              </div>
            </div>
          </div>
        </div>
      </Layout>
      <WhichJobModal
        show={Boolean(candidateToBeLiked)}
        onClose={() => setCandidateToBeLiked(null)}
        onJobSelect={async (jobId) => {
          await candidateAccept(jobId, candidateToBeLiked)
        }}
      >
        Select the job you want to like the candidate for:
      </WhichJobModal>
      <WhichJobModal
        show={Boolean(candidateToBeMessaged)}
        onClose={() => setCandidateToBeMessaged(null)}
        onJobSelect={(jobId) => {
          navigateToConversation(candidateToBeMessaged, jobId)
        }}
      >
        Select the job you want to message the candidate about:
      </WhichJobModal>
    </>
  )
}

export default RecruiterCandidatesPage
