import { initializeApp } from "firebase/app"
import { getAuth } from "firebase/auth"
import { getFirestore, arrayUnion } from "firebase/firestore"
import firebase from "firebase/compat/app"
import { getStorage } from "firebase/storage"

import "firebase/compat/firestore"

const firebaseConfig = {
  apiKey: "AIzaSyAwYI0MINBIIximIhqQozg4L2yDUFHrsmY",
  authDomain: "dex-legal-recruitment.firebaseapp.com",
  projectId: "dex-legal-recruitment",
  storageBucket: "dex-legal-recruitment.appspot.com",
  messagingSenderId: "189855251028",
  appId: "1:189855251028:web:991924b5da3f04c6c14bc1",
}
// Initialize Firebase
const app = firebase.initializeApp(firebaseConfig)

// const firestore = firebase.firestore()
const firestore = getFirestore(app)
// fire storage
const storage = getStorage(app)
const auth = getAuth(app)

const db = firebase.firestore()

export { auth, db, storage, arrayUnion, firestore }
