import MultiRangeSlider from "multi-range-slider-react"
import React from "react"
import { Form } from "react-bootstrap"
import { Controller } from "react-hook-form"
import CreatableSelect from "react-select/creatable"
import {
  citiesOptions,
  belgiumCitiesOptions,
  expertiseOptions,
  languagesOptions,
} from "../../constants/Data.js"

const JobInfoStep = ({ errors, onNext, register, trigger, control, watch }) => {
  const [jobExperience, jobBudget] = watch(["jobExperience", "jobBudget"])

  const handleNextStepClick = async () => {
    const isValid = await trigger([
      "jobTitle",
      "jobCities",
      "jobLanguages",
      "jobExpertise",
      "jobExperience",
      "jobBudget",
    ])

    if (isValid) {
      onNext()
    }
  }

  return (
    <div className="w-100 h-full">
      <p className="font-bold">Add a New Job</p>
      <p>
        Please specify the details of the open position that will be visible
        to candidate
      </p>
      {/* Title */}
      <Form.Group className="flex flex-column w-100">
        <Form.Label className="font-bold">Title of the position*</Form.Label>
        <Form.Control
          type="text"
          placeholder="Job Title"
          className="mb-2"
          {...register("jobTitle")}
        />
        {errors.jobTitle && (
          <p className="text-red-600">{errors.jobTitle.message}</p>
        )}
      </Form.Group>

      {/* City */}

      <div className="flex flex-column w-100">
        <p className="font-bold">City*</p>
        <div className="w-full mb-2">
          <Controller
            name="jobCities"
            control={control}
            render={({ field }) => {
              const selectedOptions = belgiumCitiesOptions.filter((cityOption) =>
                field.value.includes(cityOption.value),
              )
              return (
                <CreatableSelect
                  {...field}
                  onChange={(values) => {
                    field.onChange(values.map((value) => value.value))
                  }}
                  value={selectedOptions}
                  isClearable
                  isMulti
                  options={belgiumCitiesOptions}
                  className="basic-multi-select"
                  classNamePrefix="select"
                />
              )
            }}
          />
          {errors.jobCities && (
            <p className="text-red-600">{errors.jobCities.message}</p>
          )}
        </div>
      </div>
      {/* Languages */}
      <div className="flex flex-column w-100">
        <p className="font-bold">Required Languages*</p>
        <div className="w-full mb-2">
          <Controller
            name="jobLanguages"
            control={control}
            render={({ field }) => {
              const selectedOptions = languagesOptions.filter(
                (languageOption) => field.value.includes(languageOption.value),
              )

              return (
                <CreatableSelect
                  {...field}
                  onChange={(values) => {
                    field.onChange(values.map((value) => value.value))
                  }}
                  value={selectedOptions}
                  isClearable
                  isMulti
                  options={languagesOptions}
                  className="basic-multi-select"
                  classNamePrefix="select"
                />
              )
            }}
          />
          {errors.jobLanguages && (
            <p className="text-red-600">{errors.jobLanguages.message}</p>
          )}
        </div>
      </div>
      {/* Optional languages */}
      <div className="flex flex-column w-100">
        <p className="font-bold">Bonus Languages</p>
        <div className="w-full mb-2">
          <Controller
            name="jobBonusLanguages"
            control={control}
            render={({ field }) => {
              const selectedOptions = languagesOptions.filter(
                (languageOption) => field.value.includes(languageOption.value),
              )

              return (
                <CreatableSelect
                  {...field}
                  onChange={(values) => {
                    field.onChange(values.map((value) => value.value))
                  }}
                  value={selectedOptions}
                  isClearable
                  isMulti
                  options={languagesOptions}
                  className="basic-multi-select"
                  classNamePrefix="select"
                />
              )
            }}
          />
        </div>
      </div>
      {/* Expertise */}
      <div>
        <div className=" my-2">
          <p className="font-bold">Expertise*</p>
          <Controller
            name="jobExpertise"
            control={control}
            render={({ field }) => {
              const selectedOptions = expertiseOptions.filter(
                (expertiseOption) =>
                  field.value.includes(expertiseOption.value),
              )

              return (
                <CreatableSelect
                  {...field}
                  onChange={(values) => {
                    field.onChange(values.map((value) => value.value))
                  }}
                  value={selectedOptions}
                  isClearable
                  isMulti
                  options={expertiseOptions}
                  className="basic-multi-select"
                  classNamePrefix="select"
                />
              )
            }}
          />
          {errors.jobExpertise && (
            <p className="text-red-600">{errors.jobExpertise.message}</p>
          )}
        </div>
      </div>

      <Form.Group className="flex flex-column w-100 mt-2">
        <p className="font-bold mb-2">Years of experience *</p>
        <div>
          <Controller
            name="jobExperience"
            control={control}
            render={({ field: { onChange, value, ...field } }) => {
              const handleChange = (value) => {
                onChange({ min: value.minValue, max: value.maxValue })
              }

              return (
                <MultiRangeSlider
                  {...field}
                  onInput={handleChange}
                  min={0}
                  max={7}
                  step={1}
                  label={false}
                  ruler={false}
                  minValue={value.min}
                  maxValue={value.max}
                  className="border-none shadow-none z-0"
                  thumbLeftColor="blue"
                  thumbRightColor="blue"
                  barInnerColor="blue"
                />
              )
            }}
          />

          <div className="flex items-center justify-between">
            <p className="text-xs">
              Minimum Experience{" "}
              <span className="font-bold">{jobExperience.min} Years</span>
            </p>
            <p className="text-xs">
              Maximum Experience{" "}
              <span className="font-bold">{jobExperience.max} Years</span>
            </p>
          </div>
          {errors.jobExperience && (
            <p className="text-red-600">{errors.jobExperience.message}</p>
          )}
        </div>
      </Form.Group>

      {/* Budget */}

      <Form.Group className="flex flex-column w-100 mt-2">
        <p className="font-bold mb-2">Budget*</p>

        <div>
          <Controller
            name="jobBudget"
            control={control}
            render={({ field: { onChange, value } }) => {
              const handleChange = (value) => {
                onChange({ min: value.minValue, max: value.maxValue })
              }

              return (
                <MultiRangeSlider
                  onInput={handleChange}
                  min={0}
                  max={250}
                  step={10}
                  stepOnly
                  label={false}
                  ruler={false}
                  minValue={value.min}
                  maxValue={value.max}
                  className="border-none shadow-none z-0"
                  thumbLeftColor="blue"
                  thumbRightColor="blue"
                  barInnerColor="blue"
                />
              )
            }}
          />

          <div className="flex items-center justify-between">
            <p className="text-xs">
              Minimum Budget{" "}
              <span className="font-bold">
                {jobBudget.min}
                K€
              </span>
            </p>
            <p className="text-xs">
              Maximum Budget{" "}
              <span className="font-bold">
                {jobBudget.max}
                K€
              </span>
            </p>
          </div>
          {errors.jobBudget && (
            <p className="text-red-600">{errors.jobBudget.message}</p>
          )}
        </div>
      </Form.Group>

      {/* button */}
      <div class="flex items-center justify-between w-full mt-4 relative">
        <p class="text-center w-full">1/2</p>
        <button
          class="bg-[#46D6EA] py-2 px-4 rounded-md font-bold border border-[#46D6EA] cursor-pointer hover:bg-transparent absolute right-0"
          onClick={handleNextStepClick}
        >
          Next
        </button>
      </div>

    </div>
  )
}

export default JobInfoStep
