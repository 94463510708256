import React from "react"
import { IMAGES } from "../../constants/images.jsx"
import NavbarLanding from "../../utils/NavBarLanding.jsx"
import Footer from "../../utils/Footer.jsx"
const OurMission = () => {
  return (
    <div
      name="our mission"
      className="flex flex-col items-center justify-center bg-[#131F2E] h-full w-full text-white"
    >
      <NavbarLanding />
      <div className="flex flex-col items-center justify-center mt-5">
        <h1 className="font-semibold text-3xl">
          Our <span className="text-[#868BFF]">Mission</span>
        </h1>
        <p className="text-justify w-full lg:w-[30%] mt-3 text-sm px-4">
          At JustLawyers we empower candidates and law firms by giving back
          control over their recruitment process. No more unnecessary
          intermediaries, just direct connections between legal professionals.{" "}
        </p>
      </div>
      <div className="flex flex-col md:flex-row items-center gap-x-5 mt-5 w-[90%] md:w-75">
        <div className="flex flex-col w-full md:w-50 p-3 h-fit md:h-[510px] lg:h-[300px]">
          <div className="flex items-center gap-x-2 ">
            <div className="bg-[#868BFF] rounded-full p-2">
              <img src={IMAGES.M_CHARACTER} className="w-4 h-4" />
            </div>
            <span className="font-semibold">For Candidates</span>
          </div>
          <div className="mt-2 ">
            <p className="text-justify text-sm">
              Lawyers on the other hand, face the frustration of vague
              communication from recruiters, wasting valuable time and energy.
              At JustLawyers we’re on a mission to change that. We're determined
              to transform job hunting and staying in touch with the legal
              market into an enjoyable experience.
            </p>
          </div>
        </div>
        <div className="flex flex-col w-full lg:w-50 p-3 h-fit md:h-[510px] lg:h-[300px]">
          <div className="flex items-center gap-x-2 ">
            <div className="bg-[#FFC83A] rounded-full p-2">
              <img src={IMAGES.M_FIRM} className="w-4 h-4" />
            </div>
            <span className="font-semibold">For Law Firms </span>
          </div>
          <div className="mt-2">
            <p className="text-justify text-sm">
              Law firms are often confronted with excessive recruitment fees. By
              using cutting-edge AI technology, we're bypassing intermediaries
              to provide direct and transparent access to job opportunities. Our
              platform not only saves firms significant resources but also
              empowers lawyers to navigate their career paths with clarity and
              confidence.
            </p>
          </div>
        </div>
      </div>
      <div className="flex flex-col items-center justify-center text-black bg-[#868BFF] mt-2 w-full gap-y-3 py-3">
        <h3 className="font-semibold text-2xl">Our motto? </h3>
        <h3 className="font-semibold text-2xl italic">
          No Recruiters. Just Lawyers.{" "}
        </h3>
      </div>
      <Footer />
    </div>
  )
}

export default OurMission
