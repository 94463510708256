import { useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"
import { fetchAndCreateConversation } from "../app/functions"
import { userSelector } from "../app/userRedux/userSlice"

const useNavigateToConversation = () => {
  const navigate = useNavigate()
  const user = useSelector(userSelector)

  const handleNavigate = async (conversationPartnerId, jobId) => {
    const candidateId = user.isEmployer ? conversationPartnerId : user.uid
    const employerId = user.isEmployer ? user.uid : conversationPartnerId

    const conversation = await fetchAndCreateConversation(
      candidateId,
      employerId,
      jobId,
    )

    navigate("/chatroom", {
      state: { conversationId: conversation.id },
    })
  }

  return {
    navigateToConversation: handleNavigate,
  }
}

export default useNavigateToConversation
