import React, { useState } from "react"
import classNames from "classnames"
import { AiOutlinePlus } from "react-icons/ai"
import { Link, NavLink, useNavigate } from "react-router-dom"
import { signOutUser } from "../app/functions.js"
import RecruiterDropDown from "../components/RecruiterDropDown"
import { IMAGES } from "../constants/images"
import styles from "./Navbar.module.css"
import { Navbar, Container, Offcanvas } from "react-bootstrap"
import NotificationDropdown from "../components/NotificationDropdown.jsx"
const NavBarItem = ({ children, to }) => {
  return (
    <NavLink
      to={to}
      className={({ isActive }) =>
        classNames(styles.link, { [styles.active]: isActive })
      }
    >
      {children}
    </NavLink>
  )
}

const NavbarRec = () => {
  const [showMenu, setShowMenu] = useState(false)

  const handleMenuOpen = () => {
    setShowMenu(true)
  }

  const handleMenuClose = () => {
    setShowMenu(false)
  }

  const handleItemClick = () => {
    setShowMenu(false) // Close the menu when an item is clicked
  }

  const navigate = useNavigate()

  const handleEditUser = () => {
    // Add logic for handling Edit User action
    console.log("Edit User clicked")
  }

  const handleSignout = async () => {
    await signOutUser()
  }

  return (
    <>
      <div className={styles.navbar}>
        <Link to="/HomeRecruiter" className={styles.logo}>
          <img
            className="logo_css cursor-pointer"
            src={IMAGES.JLFULL}
            alt="Logo"
          />
        </Link>
        <div className={styles.links}>
          <NavBarItem to="/HomeRecruiter">Dashboard</NavBarItem>
          <NavBarItem to="/chatroom">Messages</NavBarItem>
          <NavBarItem to="/JobBoardRecruiter">My Jobs</NavBarItem>
          <NavBarItem to="/candidaterecruiter">Candidate Board</NavBarItem>
          <NavBarItem to="/RecruiterProfile">My Profile</NavBarItem>
          <Link to="/createJob" className={styles.link_newJob}>
            <AiOutlinePlus className="mr-2" /> New Job
          </Link>

          <div className="justify-center align-items-center flex">
            <NotificationDropdown />
            <RecruiterDropDown
              handleEditUser={handleEditUser}
              handleSignout={handleSignout}
              navigate={navigate}
            />
          </div>
        </div>
      </div>
      <Navbar
        expand="lg"
        className="inline-block custom:hidden bg-body-tertiary mb-3 w-full"
      >
        <Container fluid>
          <Navbar.Brand href="/">
            <Link to="/homeCandidate" className={styles.logo}>
              <img
                className="logo_css cursor-pointer"
                src={IMAGES.JLFULL}
                alt="logo"
              />
            </Link>
          </Navbar.Brand>
          <Navbar.Toggle
            aria-controls={`offcanvasNavbar-expand`}
            onClick={handleMenuOpen}
            className="bg-white"
          />
          <Navbar.Offcanvas
            id={`offcanvasNavbar-expand`}
            aria-labelledby={`offcanvasNavbarLabel-expand`}
            placement="end"
            onHide={handleMenuClose}
            show={showMenu}
          >
            <Offcanvas.Header closeButton className="flex justify-center">
              <Offcanvas.Title
              // id={`offcanvasNavbarLabel-expand`}
              >
                <Link
                  to="/homeCandidate"
                  className={styles.logo}
                  onClick={handleItemClick}
                >
                  <img
                    className="logo_css cursor-pointer"
                    src={IMAGES.JLFULL}
                    alt="logo"
                  />
                </Link>
              </Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body className="">
              <div className="flex flex-col-reverse justify-center items-center gap-y-3">
                <NavBarItem to="/HomeRecruiter" onClick={handleItemClick}>
                  Dashboard
                </NavBarItem>
                <NavBarItem to="/chatroom" onClick={handleItemClick}>
                  Messages
                </NavBarItem>
                <NavBarItem to="/JobBoardRecruiter" onClick={handleItemClick}>
                  My Jobs
                </NavBarItem>
                <NavBarItem to="/candidaterecruiter" onClick={handleItemClick}>
                  Candidate Board
                </NavBarItem>
                <NavBarItem to="/RecruiterProfile" onClick={handleItemClick}>
                  My Profile
                </NavBarItem>
                <Link
                  to="/createJob"
                  className={styles.link_newJob}
                  onClick={handleItemClick}
                >
                  <AiOutlinePlus className="mr-2" /> New Job
                </Link>
                <div>
                  <RecruiterDropDown
                    handleEditUser={handleEditUser}
                    handleSignout={handleSignout}
                    navigate={navigate}
                  />
                </div>
              </div>
            </Offcanvas.Body>
          </Navbar.Offcanvas>
        </Container>
      </Navbar>
    </>
  )
}

export default NavbarRec
