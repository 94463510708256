import React from "react"
import { Button, Form, Modal } from "react-bootstrap"
import { DotLoader } from "react-spinners"
import useFetchMyJobs from "../../hooks/useFetchMyJobs"

const WhichJobModal = ({ children, show, onClose, onJobSelect }) => {
  const { jobs, jobsAreLoading } = useFetchMyJobs()

  const handleChooseJob = (jobId) => () => {
    onJobSelect(jobId)
    onClose()
  }

  return (
    <Modal show={show} onHide={onClose}>
      <Modal.Header closeButton>
        <Modal.Title>Choose a job</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {jobsAreLoading ? (
          <DotLoader color="#868bff" />
        ) : jobs.length === 0 ? (
          <>You'll need to create a job first!</>
        ) : (
          <>
            <p>{children}</p>
            <Form>
              <Form.Group>
                {jobs.map((job) => (
                  <Button
                    key={job.uid}
                    onClick={handleChooseJob(job.uid)}
                    className="text-black px-5 hover:bg-transparent bg-[red] "
                  >
                    {job.jobTitle}
                  </Button>
                ))}
              </Form.Group>
            </Form>
          </>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button
          className="font-bold bg-[#46D6EA] text-black px-5 hover:bg-transparent "
          onClick={onClose}
        >
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

export default WhichJobModal
