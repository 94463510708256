import React, { useState } from "react"
import styles from "./CandidateData.module.css"
import { Modal, Button } from "react-bootstrap"
import { useNavigate, useLocation } from "react-router-dom"
import { IMAGES } from "../../constants/images.jsx"
import download_icon from "../../assets/icons/Upload.svg"
import { storage } from "../../config/firebase.js"
import { useDispatch, useSelector } from "react-redux"
import { updateInitialData } from "../../app/initialDataSlice"
import { saveData } from "../../app/dataSlice.js"
import { editUser } from "../../app/functions.js"
import { unisList, llmOptions } from "../../constants/Data.js"
import Select from "react-select"
import CreatableSelect from "react-select/creatable"

const grades = ["50-65%", "65-75%", "75-85%", "85-100%"]

const CandidateEducationModal = ({ show, handleClose }) => {
  const { state } = useLocation()
  const loggedUser = useSelector((state) => state.data.value)
  const initialData = useSelector((state) => state.initialData)
  const dispatch = useDispatch()

  const handleChangeLlmList = (selectedOptions) => {
    const newLlmList = selectedOptions
      ? selectedOptions.map((option) => option.label)
      : []
    dispatch(updateInitialData({ master: newLlmList }))
  }

  const save = async () => {
    const data = initialData
    const uid = initialData.uid
    saveData(uid, data)

    await editUser(data, dispatch, saveData)
    handleClose()
  }
  return (
    <Modal show={show} onHide={handleClose} centered>
      <Modal.Header>
        <Modal.Title>Education info</Modal.Title>
      </Modal.Header>
      <Modal.Body className={styles.modalbod}>
        <div className={styles.innercontainer}>
          <div className="w-full mt-2">
            <label className="font-bold">University</label>
            <CreatableSelect
              isClearable
              placeholder="Select university..."
              className="basic-single"
              classNamePrefix="select"
              defaultValue={""}
              name="university"
              // options={unisOptions}
              options={llmOptions}
              // onChange={changeUniversity}
              // value={initialData.university?.map((university) => ({
              //   label: university,
              //   value: university,
              // }))}
              onChange={(selectedOption) => {
                const newUniversity = selectedOption ? selectedOption.label : ""
                dispatch(updateInitialData({ university: newUniversity }))
              }}
              value={
                initialData.university
                  ? {
                      label: initialData.university,
                      value: initialData.university,
                    }
                  : null
              }
            />
          </div>

          {/* ------------------------ Average Grades ------------------------*/}

          <p className={styles.p}>Average Grades</p>
          <div className="flex justify-between w-full my-2">
            {grades.map((grade, index) => (
              <button
                key={`btn${index + 1}`}
                className={
                  initialData.grades === grade
                    ? "rounded-full border-1 border-gray-200 px-3 py-2 bg-[#46D6EA]"
                    : "rounded-full border-1 border-gray-200 px-3 py-2 hover:bg-[#46D6EA]"
                }
                onClick={(e) => {
                  // handleBtnClick(`btn${index + 1}`);
                  dispatch(updateInitialData({ grades: grade }))
                  // handleBtnData(e);
                }}
                value={grade}
                id={`btn${index + 1}`}
              >
                {grade}
              </button>
            ))}
          </div>

          {/* ------------------------ (Expected) Date of graduation ------------------------*/}
          <p className={styles.p}>Date of graduation (Expected)</p>

          <input
            type="date"
            className={styles.input}
            onChange={(e) =>
              dispatch(updateInitialData({ dateOfGradutation: e.target.value }))
            }
            value={initialData.dateOfGradutation}
          />

          {/* ------------------------ Master of Laws (LL.M.) ------------------------*/}

          <div className="w-full mt-2">
            <label className="font-bold">Master of law (LLM)</label>
            <CreatableSelect
              isClearable
              placeholder="Select LLM..."
              defaultValue={[]}
              isMulti
              name="university"
              options={llmOptions}
              className="basic-multi-select"
              classNamePrefix="select"
              onChange={handleChangeLlmList}
              value={initialData.master?.map((university) => ({
                label: university,
                value: university,
              }))}
            />
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer className="flex items-center justify-between">
        <Button
          variant=""
          className="border-[1px] border-black"
          onClick={handleClose}
        >
          Close
        </Button>
        <Button variant="" className="bg-[#46D6EA] font-bold" onClick={save}>
          Save Changes
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

export default CandidateEducationModal
