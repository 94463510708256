import React, { useState } from "react"
import styles from "./Home.module.css"

import { signOut, getAuth } from "firebase/auth"
import { useAuthState } from "react-firebase-hooks/auth"

import { useNavigate, useLocation } from "react-router-dom"

const Home = () => {
  const [selected, setSelected] = useState("Home")

  const auth = getAuth()
  const [user] = useAuthState(auth)

  const navigate = useNavigate()

  const signoutuser = (auth) => {
    signOut(auth)
      .then(() => {
        console.log("user signed out")
        navigate(`/`)
      })
      .catch((error) => {
        console.log("error", error)
      })
  }

  const handleSelect = (selectedItem) => {
    setSelected(selectedItem)
  }

  return (
    <>
      <div className={styles.navbar}>
        <div className={styles.logo}>Logo</div>
        <div className={styles.links}>
          <div
            className={selected === "Home" ? styles.selected : styles.link}
            onClick={() => handleSelect("Home")}
          >
            Home
          </div>
          <div
            className={selected === "MyMatches" ? styles.selected : styles.link}
            onClick={() => handleSelect("MyMatches")}
            
          >
            My Matches
          </div>
          <div
            className={selected === "JobBoards" ? styles.selected : styles.link}
            onClick={() => handleSelect("JobBoards")}
          >
            Job Boards
          </div>
          <div
            className={selected === "MyAccount" ? styles.selected : styles.link}
            onClick={() => handleSelect("MyAccount")}
          >
            My Account
          </div>
        </div>
      </div>
      <div className={styles.content}>
        <div className={styles.section}>
          <p className={styles.section1h1} onClick={signoutuser(auth)}>
            Welcome back to DEX!
          </p>

          <p className={styles.section1h2}>
            Find your prefect match and start swiping.
          </p>

          <div className={styles.nextHalf}>updates News</div>
        </div>
        <div className={styles.section}>
          <img src="your_image_url_here" alt="Some Image" />
        </div>
      </div>

      {/* --------------------- {to show case the navbar sticky } ---------------------*/}
      {/* <div className={styles.content}>
        <div className={styles.section}>
          <p className={styles.section1h1}>Welcome back to DEX!</p>

          <p className={styles.section1h2}>
            Find your prefect match and start swiping.
          </p>

          <div className={styles.nextHalf}>updates News</div>
        </div>
        <div className={styles.section}>
          <img src="your_image_url_here" alt="Some Image" />
        </div>
      </div> */}
    </>
  )
}

export default Home
