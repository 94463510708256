import React, { useEffect, useState } from "react"
import styles from "./UserProfile.module.css"
import { IMAGES } from "../../constants/images.jsx"
import harvey from "../../assets/example_images/harvey.jpg"
import lawfirm_logo from "../../assets/example_images/law-firm-logojpg.jpg"
import { CiLocationOn } from "react-icons/ci"
import { PiSuitcaseLight } from "react-icons/pi"
import { BiMessage } from "react-icons/bi"
import { MdOutlineMenu } from "react-icons/md"
import { BiSearch } from "react-icons/bi"
import { useSelector, useDispatch } from "react-redux"
import { AiFillEdit } from "react-icons/ai"
import { Modal, Button } from "react-bootstrap"
import download_icon from "../../assets/icons/Upload.svg"

// import { signoutuser } from "../../app/functions.js"
import { useNavigate } from "react-router-dom"
import userEvent from "@testing-library/user-event"

import { doc, updateDoc } from "firebase/firestore"
import { db, storage } from "../../config/firebase.js"
// import { storage } from "./firebaseConfig"
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage"
import CandiateInfoModal from "../../components/modals/CandiateInfoModal.jsx"
import CandidateAboutModal from "../../components/modals/CandidateAboutModal.jsx"
import CandidateEducationModal from "../../components/modals/CandidateEducationModal.jsx"
import CandidateDocsModal from "../../components/modals/CandidateDocsModal.jsx"
import CandidateExpertiseModal from "../../components/modals/CandidateExpertiseModal.jsx"
import CandidateExperienceModal from "../../components/modals/CandidateExperienceModal.jsx"
// window.location.reload()

const UserProfile = () => {
  const user = useSelector((state) => state.data.value)

  const dispatch = useDispatch()

  const [clickedCV, setClickedCV] = useState(false)
  const [clickedCL, setClickedCL] = useState(false)
  const [clickedTrans, setClickedTrans] = useState(false)
  const [userInfo, setUserInfo] = useState(false)
  const [userStatus, setUserStatus] = useState(false)
  const [userAbout, setUserAbout] = useState(false)
  const [userNewName, setUserNewName] = useState("")
  const [userNewSurname, setUserNewSurname] = useState("")
  const [userNewLocation, setUserNewLocation] = useState("")
  const [profileP, setProfileP] = useState("")
  const [newDescription, setNewDescription] = useState("")
  const [percentPP, setPercentPP] = useState(0)
  const [fileReady, setFileReady] = useState(true)
  const [filePicked, setIsFilePicked] = useState(false)
  const [uploadedFile, setUploadedFile] = useState(false)
  const [userProfilePhoto, setUserProfilePhoto] = useState(false)

  //
  const [show, setShow] = useState(false)
  const [showAbout, setShowAbout] = useState(false)
  const [showEdu, setShowEdu] = useState(false)
  const [showDocs, setShowDocs] = useState(false)
  const [showExpertise, setShowExpertise] = useState(false)
  const [showExperience, setShowExperience] = useState(false)

  const handleClose = () => setShow(false)
  const handleShow = () => setShow(true)
  const handleCloseAbout = () => setShowAbout(false)
  const handleShowAbout = () => setShowAbout(true)
  const handleCloseEdu = () => setShowEdu(false)
  const handleShowEdu = () => setShowEdu(true)
  const handleCloseDocs = () => setShowDocs(false)
  const handleShowDocs = () => setShowDocs(true)
  const handleCloseExpertise = () => setShowExpertise(false)
  const handleShowExpertise = () => setShowExpertise(true)
  const handleCloseExperience = () => setShowExperience(false)
  const handleShowExperience = () => setShowExperience(true)
  //

  const navigate = useNavigate()

  // const signout = () => {
  //   console.log("signed out")
  //   navigate("/")
  //   signoutuser(dispatch)
  // }
  const flipDateString = (dateString) => {
    const parts = dateString.split("-")
    const flippedDateString = parts.reverse().join("-")
    return flippedDateString
  }

  const changeUserInfo = () => {
    setUserInfo(!userInfo)
  }

  const changeUserProfilePhoto = () => {
    setUserProfilePhoto(!userProfilePhoto)
  }

  const changeUserStatus = () => {
    setUserStatus(!userStatus)
  }
  const changeUserAbout = () => {
    setUserAbout(!userAbout)
  }
  const handleChangePP = (event) => {
    setFileReady(false)
    const file = event.target.files[0]
    if (!file) {
      return
    } else {
      if (event.target.files[0].size > 10485760) {
        alert("The file is too big! Compress it below 10MB")
      } else {
        // setProfileP(event.target.files[0])
        setIsFilePicked(true)
        const storageRef = ref(
          storage,
          `profileImgs/${event.target.files[0].size}${file.name}`,
        )
        const uploadTask = uploadBytesResumable(storageRef, file)

        uploadTask.on(
          "state_changed",
          (snapshot) => {
            const progress = Math.round(
              (snapshot.bytesTransferred / snapshot.totalBytes) * 100,
            )
            console.log(progress)
            setPercentPP(progress)
          },
          (error) => {
            alert(error)
          },

          () => {
            getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
              setProfileP(downloadURL)
              setFileReady(true)
              updateUserProfilePhoto(downloadURL)
            })
          },
        )
      }
    }
  }

  const updateUserInfo = (e) => {
    e.preventDefault()
    // console.log(user)

    try {
      updateDoc(doc(db, "users", user.uid), {
        name: userNewName,
        location: userNewLocation,
        surname: userNewSurname,
        // profileImg: profileP,
      })
    } catch (error) {
      console.log(error)
    }

    setUserInfo(!userInfo)
    // window.location.reload()
  }

  const updateUserProfilePhoto = (downloadURL) => {
    try {
      updateDoc(doc(db, "users", user.uid), {
        profileImg: downloadURL,
      })
    } catch (error) {
      console.log(error)
    }
    setUserProfilePhoto(!userProfilePhoto)
  }

  const updateUserStatus = (e) => {
    e.preventDefault()
    // console.log(user.searchingStatus)

    if (user?.searchingStatus === "casually looking") {
      try {
        updateDoc(doc(db, "users", user.uid), {
          searchingStatus: "activily looking",
        })
      } catch (error) {
        console.log(error)
      }
    } else if (user?.searchingStatus === "activily looking") {
      try {
        updateDoc(doc(db, "users", user.uid), {
          searchingStatus: "casually looking",
        })
      } catch (error) {
        console.log(error)
      }
    } else if (!user.searchingStatus) {
      try {
        updateDoc(doc(db, "users", user.uid), {
          searchingStatus: "activily looking",
        })
      } catch (error) {
        console.log(error)
      }
    }
    setUserStatus(!userStatus)
    // window.location.reload()
  }

  const handleUploadAbout = (e) => {
    e.preventDefault()
    try {
      updateDoc(doc(db, "users", user.uid), {
        description: newDescription,
      })
    } catch (error) {
      console.log(error)
    }
    setUserAbout(!userAbout)
    // window.location.reload()
  }

  const experienceList = [
    {
      company_name: "AKD Benelux",
      position_name: "Legal Assistante",
      time: "1 year 2 months",
      dates: "October 2022- December 2022",
      description:
        "Passionate legal professional dedicated to delivering exceptional results in the field of [specific area of law, e.g., corporate law, family law, criminal law]. Bringing a wealth of experience and a commitment to excellence, I thrive in dynamic legal environments.",
    },
    {
      company_name: "Latham & Watkins",
      position_name: "Legal Assistante",
      time: "1 year 2 months",
      dates: "October 2022- December 2022",
      description:
        "Passionate legal professional dedicated to delivering exceptional results in the field of [specific area of law, e.g., corporate law, family law, criminal law]. Bringing a wealth of experience and a commitment to excellence, I thrive in dynamic legal environments.",
    },
  ]

  return (
    <div
      className={
        (styles.container, "overflow-y-auto flex items-center justify-center")
      }
    >
      <div
        className={
          (styles.contentsection,
          "flex flex-column items-center justify-center w-[80%] lg:w-[75%] mt-5")
        }
      >
        {/* Title */}
        <h2 className={(styles.titletext, "text-left w-full text-white ")}>
          My Profile
        </h2>
        {/* Top Bar */}
        <div className={styles.topbar}>
          {/* <img src={harvey} className={styles.imgprofile} /> */}
          {userProfilePhoto ? (
            <>
              <div className="flex flex-col z-20 bg-gray-200 w-[20%] h-75">
                <div className="flex justify-between w-100 my-2">
                  <label
                    for="profileP"
                    className="flex items-center justify-center bg-[#374b64] rounded-md text-white py-2 cursor-pointer w-100"
                    onClick={() => {
                      console.log("load")
                    }}
                  >
                    <img src={download_icon} className="mr-3" />
                    Click to upload new Profile image | {percentPP} "% done"
                  </label>
                  <input
                    id="profileP"
                    type="file"
                    name="profileP"
                    className="invisible w-[10px]"
                    onChange={handleChangePP}
                  />
                </div>
              </div>
            </>
          ) : (
            <>
              {user?.profileImg ? (
                <>
                  <img
                    src={user.profileImg}
                    alt="user image"
                    className={styles.imgprofile}
                  />
                </>
              ) : (
                <>
                  <img
                    src={harvey}
                    alt="user image"
                    className={styles.imgprofile}
                  />
                </>
              )}
            </>
          )}

          {/* Top bar section */}
          <div className={styles.widesection}>
            <div className={styles.topbarsection}>
              <div className="flex justify-between">
                {userInfo ? (
                  <>
                    <div className="flex flex-col z-20 bg-gray-200">
                      <p>input fields</p>
                      <div>
                        <label>New user name</label>
                        <input
                          type="text"
                          onChange={(e) => setUserNewName(e.target.value)}
                        />
                        <label>New user surname</label>
                        <input
                          type="text"
                          onChange={(e) => setUserNewSurname(e.target.value)}
                        />
                        <label>New user location</label>
                        <input
                          type="text"
                          onChange={(e) => setUserNewLocation(e.target.value)}
                        />
                      </div>
                      {/* <div className="flex justify-between w-100 my-2">
                        <label
                          for="profileP"
                          className="flex items-center justify-center bg-[#374b64] rounded-md text-white py-2 cursor-pointer w-100"
                          onClick={() => {
                            console.log("load")
                          }}
                        >
                          <img src={download_icon} className="mr-3" />
                          Click to upload new Profile image | {percentPP} "%
                          done"
                        </label>
                        <input
                          id="profileP"
                          type="file"
                          name="profileP"
                          className="invisible w-[10px]"
                          onChange={handleChangePP}
                        />
                      </div> */}
                      <div>
                        <button
                          className="bg-white px-2 py-1"
                          onClick={updateUserInfo}
                        >
                          update
                        </button>
                      </div>
                    </div>
                  </>
                ) : (
                  <>
                    <div>
                      {user?.name == "" ? (
                        <p className="bigText lowercase">{user?.email}</p>
                      ) : (
                        <p className="bigText">
                          {user?.name} {user?.surname}
                        </p>
                      )}
                      {user?.location == undefined ? (
                        <p className="flex flex-col lg:flex-row gap-y-1  items-center smallText">
                          <CiLocationOn size={20} className="mr-2" />
                          Edit your location
                        </p>
                      ) : user?.location ? (
                        <p className="flex flex-col lg:flex-row gap-y-1  items-center smallText">
                          <CiLocationOn size={20} className="mr-2" />

                          {user?.location}
                        </p>
                      ) : (
                        <p className="flex flex-col lg:flex-row gap-y-1  items-center smallText">
                          <CiLocationOn size={20} className="mr-2" />
                          No location added
                        </p>
                      )}
                    </div>
                  </>
                )}
                {/*  
                 <div>
                   <p className="bigText">
                     {user?.name} {user?.surname}
                   </p>
                 <p className="flex items-center smallText">
                    <CiLocationOn size={20} className="mr-2" />
                    {user?.location}
                   </p>
                 </div> */}
              </div>
            </div>
            <div className={styles.topbarsection}>
              <text className="flex flex-col lg:flex-row gap-y-1 items-center smallText">
                <PiSuitcaseLight size={20} className="mr-2" />
                {user?.experience ? (
                  <>
                    <p>{user?.experience} of experience</p>
                  </>
                ) : (
                  <>
                    <p>No experience </p>
                  </>
                )}
              </text>
              <text className="flex flex-col lg:flex-row gap-y-1 items-center smallText">
                <BiMessage size={20} className="mr-2" />
                {user?.selectedLanguages ? 
                (
                  user?.selectedLanguages[0] && 
                  <>{user?.selectedLanguages.join(" | ")}</>
                ) : (
                  <>
                    <p>No language is added</p>
                  </>
                )}
              </text>

              <text className="flex flex-col lg:flex-row gap-y-1 items-center smallText">
                <MdOutlineMenu size={20} className="mr-2" />
                {user?.expertise ?
                (
                  user?.expertise[0] &&
                  <>{user?.expertise?.join(" | ")}</>
                ) : (
                  <>
                    <p>No expertise is added</p>
                  </>
                )}
              </text>
            </div>
            <div className={styles.topbarsectionright}>
              <div className="flex">
                {userStatus ? (
                  <>
                    <div>
                      <button
                        className="bg-gray-200"
                        onClick={updateUserStatus}
                      >
                        Switch Status
                      </button>
                    </div>
                  </>
                ) : (
                  <>
                    <div>
                      <button
                        className={styles.greenbtn}
                        // onClick={() => console.log(user)}
                      >
                        <text className={styles.thicktextbtn}>
                          •{" "}
                          {user?.searchingStatus
                            ? user?.searchingStatus
                            : "Casually looking"}
                        </text>
                      </button>
                    </div>
                  </>
                )}
                {/* <div>
                  <button
                    className={styles.greenbtn}
                    onClick={() => console.log(user)}
                  >
                    <text className={styles.thicktextbtn}>
                      • {user?.searchingStatus}
                    </text>
                  </button>
                </div> */}
              </div>

              <text
                className="smallText cursor-pointer"
                onClick={() => navigate("/chatroom")}
              >
                Send a message
              </text>
              {/* <div>
                <button
                  className="bg-gray-400 text-black px-4 py-1 rounded-full border border-solid border-gray-400 hover:bg-gray-200 hover:border-gray-200"
                  onClick={() => signout()}
                >
                  Sign Out
                </button>
              </div> */}
            </div>
            <AiFillEdit
              size={22}
              className="text-white mr-4 cursor-pointer absolute top-[15px] right-[15px] lg:relative"
              onClick={handleShow}
            />
            {/*  */}
            <CandiateInfoModal show={show} handleClose={handleClose} />
            {/*  */}
          </div>
        </div>
        {/* Medium section */}
        <div
          className={
            (styles.personalmidsection, "flex flex-col lg:flex-row w-full ")
          }
          style={{ width: "115%" }}
        >
          <div className={styles.largesection}>
            {/* About me section */}
            <div className={styles.abtme}>
              <div className="flex justify-between mb-1 mt-3">
                <h2 className={styles.titletext}>About Me</h2>
                <AiFillEdit
                  size={22}
                  className="text-white cursor-pointer "
                  onClick={handleShowAbout}
                />
                <CandidateAboutModal
                  show={showAbout}
                  handleClose={handleCloseAbout}
                />
              </div>
              {userAbout ? (
                <>
                  <div className="flex flex-col ">
                    <textarea
                      placeholder="Write a description"
                      onChange={(e) => setNewDescription(e.target.value)}
                    />
                    <div>
                      <button
                        className="bg-gray-200 mt-2"
                        onClick={handleUploadAbout}
                      >
                        update
                      </button>
                    </div>
                  </div>
                </>
              ) : user?.description ? (
                <p className="text-white">{user?.description}</p>
              ) : (
                <>
                  <p className="text-white">No description is added</p>
                </>
              )}

              <br />
            </div>
            {/* My Experience section */}
            <div className={styles.experienceSingleSection}>
              <div className="flex items-center justify-between">
                <h2 className={styles.titletext}>Experience</h2>
                <AiFillEdit
                  size={22}
                  className="text-white cursor-pointer "
                  onClick={handleShowExperience}
                />
                <CandidateExperienceModal
                  show={showExperience}
                  handleClose={handleCloseExperience}
                />
              </div>
              {user?.employmentHistory ? (
                <>
                  {user.employmentHistory.length == 0 ? (
                    <>
                      <div>information to be filled</div>
                    </>
                  ) : (
                    <>
                      {user?.employmentHistory?.map((element, index) => (
                        <ExperienceComponent data={element} key={index} />
                      ))}
                    </>
                  )}
                </>
              ) : (
                <>
                  <p className="smallText">
                    Edit this field to provide data on your relevant experience
                  </p>
                </>
              )}
            </div>
            {/* my Education */}
            <div className={styles.abtme}>
              <div className="flex justify-between mb-4">
                <h2 className={styles.titletext}>My Education</h2>
                <AiFillEdit
                  size={22}
                  className="text-white  cursor-pointer "
                  onClick={handleShowEdu}
                />
                <CandidateEducationModal
                  show={showEdu}
                  handleClose={handleCloseEdu}
                />
              </div>
              {user?.university ? (
                <>
                  <div className="flex flex-col lg:flex-row items-start lg:items-center justify-between">
                    <p className="text-white">
                      University Name: {user.university}
                    </p>
                    <p className="text-white"> Grades: {user.grades}</p>
                    <p className="text-white">
                      Date of Graduation:{" "}
                      {flipDateString(user.dateOfGradutation)}
                    </p>
                  </div>
                </>
              ) : (
                <>
                  <p className="text-white">No Education is added </p>
                </>
              )}
              {user?.master ? (
                <>
                  <p className="text-white  mt-2 font-bold">
                    Master of Laws (LL.M.) Certificates
                  </p>
                  {user.master?.map((item, index) => (
                    <div className="flex items-center" key={index}>
                      <ul className="px-4 list-disc">
                        <li className="text-white">{item}</li>
                      </ul>
                    </div>
                  ))}
                </>
              ) : (
                <>
                  <p className="text-white">No Master education is added </p>
                </>
              )}

              <br />
            </div>
          </div>
          <div className={styles.leftsection}>
            <div className={styles.documents}>
              <div className="flex items-center justify-between">
                <h2 className={styles.titletext}>Documents</h2>
                <AiFillEdit
                  size={22}
                  className="text-white  cursor-pointer "
                  onClick={handleShowDocs}
                />
                <CandidateDocsModal
                  show={showDocs}
                  handleClose={handleCloseDocs}
                />
              </div>
              {user?.employmentHistory ? (
                <>
                  {!user?.name ? (
                    <>
                      <p className="text-white">No Documents found</p>
                    </>
                  ) : (
                    <>
                      <div>
                        {user?.cv !== "" ? (
                          <>
                            <div className={styles.cv_element}>
                              <text className="">{user.name}-CV</text>
                              <a
                                className={styles.buttonView}
                                href={user.cv}
                                target="_blank"
                              >
                                View PDF
                              </a>
                            </div>
                            {/* {clickedCV && (
                              <>
                                <div>
                                  <img src={user.cv} alt="user CV" />
                                </div>
                              </>
                            )} */}
                          </>
                        ) : (
                          <>
                            <div className="mt-2 text-white">
                              No CV is uploaded
                            </div>
                          </>
                        )}
                      </div>
                      {/* cover letter */}
                      <div>
                        {user?.coverLetter !== "" ? (
                          <>
                            <div className={styles.cv_element}>
                              <text className="">
                                {user?.name}-Cover Letter
                              </text>
                              <a
                                className={styles.buttonView}
                                href={user.coverLetter}
                                target="_blank"
                              >
                                View PDF
                              </a>
                            </div>
                            {/* {clickedCL && (
                              <>
                                <div>
                                  <img
                                    src={user?.coverLetter}
                                    alt="user Cover letter"
                                  />
                                </div>
                              </>
                            )} */}
                          </>
                        ) : (
                          <>
                            <div className="mt-2 text-white">
                              No Cover Letter is uploaded
                            </div>
                          </>
                        )}
                      </div>
                      {/* Transcript */}
                      <div>
                        {user?.transcripts !== "" ? (
                          <>
                            <div className={styles.cv_element}>
                              <text className="">{user?.name}-Transcripts</text>
                              <a
                                className={styles.buttonView}
                                href={user.transcripts}
                                target="_blank"
                              >
                                View PDF
                              </a>
                            </div>
                            {/* {clickedTrans && (
                              <>
                                <div>
                                  <img
                                    src={user?.transcripts}
                                    alt="user transcript"
                                  />
                                </div>
                              </>
                            )} */}
                          </>
                        ) : (
                          <>
                            <div className="mt-2 text-white">
                              No Transcripts are uploaded
                            </div>
                          </>
                        )}
                      </div>
                    </>
                  )}
                </>
              ) : (
                <>
                  <p className="smallText"> No Documents uploaded</p>
                </>
              )}
            </div>

            <div className={styles.documents}>
              <div className="flex items-center justify-between">
                <h2 className={styles.titletext}>Expertise</h2>
                <AiFillEdit
                  size={22}
                  className="text-white cursor-pointer "
                  onClick={handleShowExpertise}
                />
                <CandidateExpertiseModal
                  show={showExpertise}
                  handleClose={handleCloseExpertise}
                />
              </div>
              {user?.expertise?.map((expertise) => (
                <button className={styles.buttonSkill}>{expertise}</button>
              ))}
            </div>
            {/* <button onClick={handleShowModal}>Open Candidate Data Modal</button> */}
            {/* Some more content here */}
          </div>
        </div>

        <br />
        <br />
      </div>
    </div>
  )
}
const ExperienceComponent = ({ data }) => {
  return (
    <div className={styles.experienceSingleSection}>
      <img className={styles.lawfirm_logo} src={lawfirm_logo} />
      <div className={styles.experiencetextcontainer}>
        <text className="mediumText">{data?.position_title}</text>
        <div className="row space-between">
          {data?.employmentstart == "" ? (
            <text>Name of the company</text>
          ) : (
            <text>{data?.lawfirmname.label}</text>
          )}
          <div className="row">
            <text className={styles.graytext}>1 year 2 months</text>
            {data?.employmentstart == "" ? (
              <text className={styles.graytext}>
                Edit this entry to get dates
              </text>
            ) : (
              <text className={styles.graytext}>
                {data?.employmentstart} to {data?.employmentend}
              </text>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}
export default UserProfile
