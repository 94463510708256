import {
  collection,
  doc,
  getDoc,
  onSnapshot,
  query,
  where,
} from "firebase/firestore"
import { useEffect, useState } from "react"
import { useSelector } from "react-redux"
import { userSelector } from "../app/userRedux/userSlice"
import { db } from "../config/firebase"

const useFetchConversations = () => {
  const user = useSelector(userSelector)
  const [conversations, setConversations] = useState()

  useEffect(() => {
    const whereStatement = user.isEmployer
      ? where("employer", "==", doc(db, "employers", user.uid))
      : where("candidate", "==", doc(db, "users", user.uid))

    const unsubscribe = onSnapshot(
      query(collection(db, "conversations"), whereStatement),
      async (snapshot) => {
        const conversations = await Promise.all(
          snapshot.docs.map(async (conversationDocument) => {
            const conversationPartnerDoc = await getDoc(
              user.isEmployer
                ? conversationDocument.data().candidate
                : conversationDocument.data().employer,
            )

            const jobDoc = await getDoc(conversationDocument.data().job)

            return {
              ...conversationDocument.data(),
              aboutJob: jobDoc.exists()
                ? {
                    ...jobDoc.data(),
                  }
                : null,
              conversationPartner: {
                isEmployer: user.isEmployer,
                ...conversationPartnerDoc.data(),
              },
            }
          }),
        )
        setConversations(conversations)
      },
    )

    return unsubscribe
  }, [user.uid, user.isEmployer])

  return { conversations, loadingConversations: conversations === undefined }
}

export default useFetchConversations
