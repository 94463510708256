import {
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  signOut,
} from "firebase/auth"
import {
  arrayRemove,
  collection,
  doc,
  endAt,
  getDoc,
  getDocs,
  onSnapshot,
  orderBy,
  query,
  setDoc,
  startAt,
  updateDoc,
  where,
} from "firebase/firestore"
import { getDownloadURL, ref, uploadBytes } from "firebase/storage"
import { v4 as uuidv4 } from "uuid"
import { arrayUnion, auth, db, storage } from "../config/firebase.js"
import { store } from "./store.js"
import { initialise, login, logout } from "./userRedux/userSlice.js"
const apiUrl = window.REACT_APP_API_URL;



export const testNode = async () => {
  try {
    let name = "Jamarcus"
    const response = await fetch(apiUrl + "/test", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ name }),
    });

    if (!response.ok) {
      throw new Error("Network response was not ok");
    }

    const data = await response.json();

    console.log("New user ID:", data.id);
  } catch (error) {
    console.log(error)
  }
}

export const signInOrLogin = async (user, dispatch, saveData) => {
  try {
    const response = await fetch(apiUrl + "/signin-or-login", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(user),
    });

    if (!response.ok) {
      throw new Error("Network response was not ok");
    }

    const data = await response.json();
    dispatch(saveData(data));
  } catch (error) {
    console.log(error);
    alert(error.message);
  }
};


export const signInOrLoginEmployer = async (employer, dispatch, saveData) => {
  try {
    const response = await fetch(apiUrl + "/signin-or-login-employer", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(employer),
    });

    if (!response.ok) {
      throw new Error("Network response was not ok");
    }

    const data = await response.json();
    dispatch(saveData(data));
  } catch (error) {
    console.log(error);
    alert(error.message);
  }
};


export const fetchJobs = async (initialData) => {
  try {
    const response = await fetch(apiUrl + "/fetch-jobs", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });

    if (!response.ok) {
      throw new Error("Network response was not ok");
    }

    const data = await response.json();
    console.log("Jobs fetched:", data);
    return data;
  } catch (error) {
    console.error(error);
  }
};

export const fetchFilteredCandidates = async (filters = {}) => {
  const { jobCities, jobExpertise, jobLanguages } = filters;

  if (!jobCities && !jobExpertise && !jobLanguages) {
    console.error('Error: At least one of "jobCities", "jobExpertise", or "jobLanguages" must be provided.');
    return false;
  }

  try {
    const response = await fetch(apiUrl + "/fetch-filtered-candidates", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ jobCities, jobExpertise, jobLanguages }),
    });

    if (!response.ok) {
      throw new Error("Network response was not ok");
    }

    const data = await response.json();
    console.log("Filtered candidates fetched:", data);
    return data;
  } catch (error) {
    console.error("Error fetching filtered candidates:", error);
    throw error;
  }
};

export const fetchFilteredJobs = async (filters = {}) => {
  const { jobTitle, jobCities, jobExpertise, jobLanguages } = filters;

  try {
    const response = await fetch(apiUrl + "/fetch-filtered-jobs", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ jobTitle, jobCities, jobExpertise, jobLanguages }),
    });

    if (!response.ok) {
      throw new Error("Network response was not ok");
    }

    const data = await response.json();
    console.log("Filtered jobs fetched:", data);
    return data;
  } catch (error) {
    console.error("Error fetching filtered jobs:", error);
    throw error;
  }
};



export const fetchAppliedJobs = async (type, user_id) => {
  try {
    const response = await fetch(apiUrl + "/fetch-applied-jobs", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ type, user_id }),
    });

    if (!response.ok) {
      throw new Error("Network response was not ok");
    }

    const data = await response.json();
    console.log("Applied jobs fetched:", data);
    return data;
  } catch (error) {
    console.error("Error fetching applied jobs:", error);
    throw error;
  }
};

export const fetchSavedOrRejectedJobs = async (user_id, type) => {
  try {
    const response = await fetch(apiUrl + "/fetch-saved-or-rejected-jobs", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ user_id, type }),
    });

    if (!response.ok) {
      throw new Error("Network response was not ok");
    }

    const data = await response.json();
    console.log("Saved or rejected jobs fetched:", data);
    return data;
  } catch (error) {
    console.error("Error fetching saved or rejected jobs:", error);
    throw error;
  }
};


export const fetchSavedCandidates = async (user_id, type) => {
  try {
    const response = await fetch(apiUrl + "/fetch-saved-candidates", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ user_id, type }),
    });

    if (!response.ok) {
      throw new Error("Network response was not ok");
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error fetching saved candidates:", error);
    throw error;
  }
};


export const initaliseUser = async (user) => {
  if (user !== null) {
    const { authData, userData } = await fetchUserData(user)

    store.dispatch(initialise({ authData, userData }))
  } else {
    store.dispatch(initialise())
  }
}

export const signOutUser = async () => {
  await signOut(auth)

  store.dispatch(logout())
}

export const signInUser = async (email, password) => {
  const credentials = await signInWithEmailAndPassword(auth, email, password)

  const { authData, userData } = await fetchUserData(credentials.user)

  store.dispatch(login({ authData, userData }))
}

export const registerEmployer = async (email, password, employerData) => {
  const credentials = await createUserWithEmailAndPassword(
    auth,
    email,
    password,
  )

  await setDoc(doc(db, "profile", credentials.user.uid), {
    isEmployer: true,
  })

  const firmLogo = employerData.firmLogo[0]
  const storageRef = ref(storage, `/firmLogos/${firmLogo.size}${firmLogo.name}`)

  await uploadBytes(storageRef, firmLogo)

  const logoUrl = await getDownloadURL(storageRef)

  const employer = {
    name: employerData.firstName,
    surName: employerData.lastName,
    phone: employerData.phoneNumber,
    role: employerData.role,
    companyName: employerData.firmName,
    firmFounded: employerData.firmFoundedYear,
    firmLogo: logoUrl,
    firmLocation: employerData.firmPrimaryLocation,
    selectedLocations: employerData.firmOtherLocations,
    firmWebsite: employerData.firmUrl,
    firmSize: employerData.firmSize,
    aboutCompany: employerData.firmDescription,
  }

  await setDoc(doc(db, "employers", credentials.user.uid), employer)

  store.dispatch(
    login({
      authData: { ...credentials.user.toJSON(), isEmployer: true },
      userData: employer,
    }),
  )
}
const fetchUserData = async (user) => { //Not added in backend yet
  const [userProfileDoc, candidateDoc, employerDoc] = await Promise.all([
    getDoc(doc(db, "profile", user.uid)),
    getDoc(doc(db, "users", user.uid)),
    getDoc(doc(db, "employers", user.uid)),
  ])

  const authData = userProfileDoc.exists()
    ? {
        ...user.toJSON(),
        ...userProfileDoc.data(),
      }
    : {
        ...user.toJSON(),
        isEmployer: employerDoc.exists(),
      }

  const userData = candidateDoc.exists()
    ? candidateDoc.data()
    : employerDoc.data()

  return { authData, userData }
}


export const editUser = async (user, dispatch, saveData) => {
  try {
    const response = await fetch(apiUrl + "/edit-user", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ user }),
    });

    if (!response.ok) {
      throw new Error("Network response was not ok");
    }

    const updatedUser = await response.json();
    dispatch(saveData(updatedUser));

    console.log("User edited successfully");
  } catch (error) {
    console.error("Error editing user:", error);
    throw error;
  }
};


export const createJob = async (jobData) => {
  try {
    const employerData = store.getState().data.value
    const user = store.getState().user.userData

    const response = await fetch(apiUrl + "/create-job", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ jobData, user, employerData }),
    });

    if (!response.ok) {
      throw new Error("Network response was not ok");
    }

  } catch (error) {
    console.error("Error creating job:", error);
    throw error;
  }
};

export const fetchMyJobs = async (user_id) => {
  try {
    const response = await fetch(apiUrl + "/fetch-my-jobs", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ user_id }),
    });

    if (!response.ok) {
      throw new Error("Network response was not ok");
    }

    const myJobs = await response.json();
    return myJobs;
  } catch (error) {
    console.error("Error fetching user's jobs:", error);
    throw error;
  }
};

export const editJob = async (job) => {
  try {
    const response = await fetch(apiUrl + "/edit-job", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ job }),
    });

    if (!response.ok) {
      throw new Error("Network response was not ok");
    }

    console.log("Job edited successfully");
  } catch (error) {
    console.error("Error editing job:", error);
    throw error;
  }
};


export const jobApply = async (
  job_id,
  user_id,
  cv,
  coverLetter,
  transcripts,
  employer_id
) => {
  try {
    const response = await fetch('http://localhost:3000/job-apply', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        job_id,
        user_id,
        cv,
        coverLetter,
        transcripts,
        employer_id,
      }),
    });
    if (!response.ok) {
      throw new Error("Network response was not ok");
    }
    sendNotification(employer_id, job_id, "APPLIED");

  } catch (error) {
    console.error('Error applying for job:', error);
    throw new Error('Error applying for job');
  }
};


export const jobSave = async (job_id, user_id) => {
  try {
    const response = await fetch('http://localhost:3000/job-save', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        job_id,
        user_id,
      }),
    });
    if (!response.ok) {
      throw new Error("Network response was not ok");
    }
    // Handle success if needed
  } catch (error) {
    console.error('Error saving job:', error);
    throw new Error('Error saving job');
  }
};

export const candidateSave = async (employer_id, candidate_id, job_id) => {
  try {
    const response = await fetch('http://localhost:3000/candidate-save', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        employer_id,
        candidate_id,
        job_id,
      }),
    });
    if (!response.ok) {
      throw new Error("Network response was not ok");
    }
    // Handle success if needed
  } catch (error) {
    console.error('Error saving candidate:', error);
    throw new Error('Error saving candidate');
  }
};

export const jobReject = async (job_id, user_id) => {
  try {
    const response = await fetch('http://localhost:3000/job-reject', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        job_id,
        user_id,
      }),
    });
    if (!response.ok) {
      throw new Error("Network response was not ok");
    }
    // Handle success if needed
  } catch (error) {
    console.error('Error rejecting job:', error);
    throw new Error('Error rejecting job');
  }
};


export const candidateReject = async (job_id, user_id, employer_id, application_id) => {
  try {
    const response = await fetch('http://localhost:3000/candidate-reject', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        job_id,
        user_id,
        employer_id,
        application_id,
      }),
    });
    if (!response.ok) {
      throw new Error("Network response was not ok");
    }
        sendNotification(user_id, job_id, "REJECTED_CANDIDATE");

  } catch (error) {
    console.error('Error rejecting candidate:', error);
    throw new Error('Error rejecting candidate');
  }
};



const getMatchScore = async (data, userData) => {
  try {
    const requestData = {
      to_match:
        "This is the user data: " +
        JSON.stringify(userData) +
        "This is the job data: " +
        JSON.stringify(data),
    }

    // Make the API call
    const response = await fetch(
      "https://dex-backend-0dfc.onrender.com/api/getChatCompletion",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(requestData),
      },
    )

    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`)
    } else {
      // Parse the JSON response
      const result = await response.json()
      console.log("Successful response:", result)

      // Access the result from the server
      return result.result;

    }
  } catch (error) {
    console.error("Error making API call:", error)
    // Handle the error as needed in your React component
  }
}

export const candidateAccept = async (job_id, user_id) => {
  try {
    const response = await fetch('http://localhost:3000/candidate-accept', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        job_id,
        user_id,
      }),
    });
    if (!response.ok) {
      throw new Error("Network response was not ok");
    }

    sendNotification(user_id,  job_id, "MATCHED")

  } catch (error) {
    console.error('Error accepting candidate:', error);
    throw new Error('Error accepting candidate');
  }
};




export const fetchApplicantsJobs = async (job_id, type) => {
  try {
    const response = await fetch('http://localhost:3000/fetch-applicants', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        job_id,
        type,
      }),
    });
    if (!response.ok) {
      throw new Error("Network response was not ok");
    }
    const userDataArray = await response.json();
    return userDataArray;
  } catch (error) {
    console.error('Error fetching applicants for job:', error);
    throw new Error('Error fetching applicants for job');
  }
};


export const checkJobStatus = async (job_id, user_id, collection_name) => {
  try {
    const response = await fetch('http://localhost:3000/check-job-status', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        job_id,
        user_id,
        collection_name,
      }),
    });
    if (!response.ok) {
      throw new Error("Network response was not ok");
    }
    const jobStatus = await response.json();
    return jobStatus;
  } catch (error) {
    console.error('Error checking job status:', error);
    throw new Error('Error checking job status');
  }
};
export const getPotentialCandidates = async () => {
  try {
    const response = await fetch('http://localhost:3000/get-potential-candidates', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({}),
    });
    if (!response.ok) {
      throw new Error("Network response was not ok");
    }
    const candidatesArray = await response.json();
    return candidatesArray;
  } catch (error) {
    console.error('Error fetching potential candidates:', error);
    throw new Error('Error fetching potential candidates');
  }
};

export const checkIfJob = async (job_id, candidate_id) => {
  try {
    const response = await fetch('http://localhost:3000/check-if-job', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        job_id,
        candidate_id,
      }),
    });
    if (!response.ok) {
      throw new Error("Network response was not ok");
    }
    const jobExists = await response.json();
    return jobExists;
  } catch (error) {
    console.error('Error checking if job exists:', error);
    throw new Error('Error checking if job exists');
  }
};


export const fetchUsers = async () => {
  try {
    const response = await fetch('http://localhost:3000/fetch-users', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({}),
    });
    if (!response.ok) {
      throw new Error("Network response was not ok");
    }
    const usersArray = await response.json();
    return usersArray;
  } catch (error) {
    console.error('Error fetching users:', error);
    throw new Error('Error fetching users');
  }
};

export const fetchAndCreateConversation = async (
  candidateId,
  employerId,
  jobId
) => {
  try {
    const response = await fetch('http://localhost:3000/fetch-create-conversation', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        candidateId,
        employerId,
        jobId,
      }),
    });
    if (!response.ok) {
      throw new Error("Network response was not ok");
    }
    const conversationData = await response.json();
    return conversationData;
  } catch (error) {
    console.error('Error fetching/creating conversation:', error);
    throw new Error('Error fetching/creating conversation');
  }
};


export const toggleIncognito = async (conversationId, incognito, userUid) => {
  try {
    const response = await fetch('http://localhost:3000/toggle-incognito', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        conversationId,
        incognito,
        userUid,
      }),
    });
    if (!response.ok) {
      throw new Error("Network response was not ok");
    }
  } catch (error) {
    console.error('Error toggling incognito:', error);
    throw new Error('Error toggling incognito');
  }
};


export const sendNotification = async (user_id, reference, type) => {
  try {
    const notification_id = uuidv4()
    const notification = {
      notification_id,
      user_id,
      reference,
      type,
      date: new Date().toISOString(),
      read: false
    }

    await setDoc(doc(db, "notifications", notification_id), notification)
  } catch (error) {
    console.log(error)
  }
}