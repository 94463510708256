import React from "react"
import { useNavigate } from "react-router-dom"
import styles from "./AdminLogin.module.css"
import { useLinkedIn } from "react-linkedin-login-oauth2"
import { signInWithEmailAndPassword } from "firebase/auth"
import { auth } from "../../config/firebase"
import { getFirestore, doc, setDoc } from "firebase/firestore"
import { adminState } from "../../app/userRedux/userSlice"
import { useDispatch } from "react-redux"

const AdminLogin = () => {
  const navigate = useNavigate()
  const [email, setEmail] = React.useState("")
  const [password, setPassword] = React.useState("")
  const dispatch = useDispatch()

  const { linkedInLogin } = useLinkedIn({
    clientId: "78h58wpqedub9p",
    redirectUri: `${window.location.origin}/linkedin`, // for Next.js, you can use `${typeof window === 'object' && window.location.origin}/linkedin`
    onSuccess: (code) => {
      console.log(code)
    },
    onError: (error) => {
      console.log(error)
    },
  })

  const handleLoginwithEmail = (e) => {
    e.preventDefault()
    signInWithEmailAndPassword(auth, email, password)
      .then((userCredential) => {
        const user = userCredential.user
        const db = getFirestore()

        setDoc(doc(db, "users", user.uid), {
          email: user.email,
        })
          .then(() => {
            console.log("User added to Firestore database")
          })
          .catch((error) => {
            console.error("Error adding user to Firestore database: ", error)
          })

        console.log("logged in")
        navigate("/candidatedata1")
        dispatch(adminState(true))
      })
      .catch((error) => {
        alert(error.message)
      })
  }

  return (
    <>
      {/* Top Bar */}
      <div className={styles.container}>
        <div className="logo">Admin Login</div>
      </div>
      {/* Box Container */}
      <div className={styles.boxcontainer}>
        <p>Logo</p>
        {/* UserSignUp */}
        <div className={styles.innercontainer}>
          <p>Log in</p>
          <form>
            <input
              className={styles.input}
              type="text"
              placeholder="Email"
              onChange={(e) => setEmail(e.target.value)}
            />
            <input
              className={styles.input}
              type="password"
              placeholder="Password"
              onChange={(e) => setPassword(e.target.value)}
            />
          </form>
          <div style={{ marginLeft: "10px" }}>
            <button
              onClick={
                handleLoginwithEmail
                // navigate("/candidatedata1")
              }
              className={styles.button}
            >
              Login
            </button>
          </div>
          <p>-----------------or-----------------</p>
          <div style={{ marginLeft: "10px" }}>
            <button className={styles.buttonlinkedin} onClick={linkedInLogin}>
              Login in with Linkedin
            </button>
          </div>
        </div>
        <div
          className={styles.createAccount}
          onClick={() => navigate("/register")}
        >
          <p style={{ textDecorationColor: "black" }}>Create an account?</p>
        </div>
      </div>
    </>
  )
}

export default AdminLogin
