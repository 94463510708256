import { yupResolver } from "@hookform/resolvers/yup"
import React, { useState } from "react"
import { useForm } from "react-hook-form"
import { PiCaretCircleLeftFill } from "react-icons/pi"
import { Link, useNavigate } from "react-router-dom"
import * as yup from "yup"
import { createJob } from "../../app/functions.js"
import JobDescriptionStep from "./JobDescriptionStep.jsx"
import JobInfoStep from "./JobInfoStep.jsx"

const steps = {
  JOB_INFO: "jobInfo",
  JOB_DESC: "jobDesc",
}

const validationSchema = yup
  .object()
  .shape({
    jobTitle: yup.string().required("Please enter a job title"),
    jobCities: yup
      .array()
      .of(yup.string())
      .min(1, "Please enter your job required minimum 1 city")
      .required(),
    jobLanguages: yup
      .array()
      .of(yup.string())
      .min(1, "Please enter your job required minimum 1 language")
      .required(),
    jobBonusLanguages: yup.array().of(yup.string()),
    jobExpertise: yup
      .array()
      .of(yup.string())
      .min(1, "Please enter your job required minimum 1 expert")
      .required(),
    jobExperience: yup
      .object()
      .shape({
        min: yup.number().required(),
        max: yup.number().required(),
      })
      .required("Please enter a job budget range"),
    jobBudget: yup
      .object()
      .shape({
        min: yup.number().required(),
        max: yup.number().required(),
      })
      .required("Please enter a job budget range"),
    jobDescription: yup.string().required("Please enter a job description"),
    jobAdditionalDescription: yup
      .string()
      .required("Please enter an additional job description"),
  })
  .required()

const CreateJob = () => {
  const navigate = useNavigate()
  const [activeStep, activateStep] = useState(steps.JOB_INFO)

  const {
    control,
    formState: { errors },
    handleSubmit: handleFormSubmit,
    register,
    trigger,
    watch,
  } = useForm({
    mode: "onBlur",
    defaultValues: {
      jobTitle: "",
      jobCities: [],
      jobLanguages: [],
      jobBonusLanguages: [],
      jobExpertise: [],
      jobExperience: { min: 0, max: 7 },
      jobBudget: { min: 0, max: 250 },
      jobDescription: "",
      jobAdditionalDescription: "",
    },
    resolver: yupResolver(validationSchema),
  })

  const handleSubmit = handleFormSubmit(async (formData) => {
    await createJob(formData)

    navigate("/JobBoardRecruiter")
  })

  return (
    <div className="flex flex-column justify-center items-center   py-4 p-x-[200px] text-black overflow-y-auto">
      <div className="mb-3    sm:w-10/12 md:w-1/2 lg:w-1/2 xl:w-1/2 mx-auto">
        <Link to="/JobBoardRecruiter" className="flex items-center text-white">
          <PiCaretCircleLeftFill
            size={38}
            className="mr-2 text-blue-400 cursor-pointer duration-75	 hover:scale-125"
          />
          <p className="text-xl font-bold"> Back to My jobs</p>
        </Link>
      </div>
      <div className="flex flex-column  bg-white rounded-lg p-4  w-11/12  sm:w-10/12 md:w-1/2 lg:w-1/2 xl:w-1/2 mx-auto">
        <div className="flex flex-column items-start my-3 px-4">
          
          <form onSubmit={handleSubmit} className="w-100 h-full">
            {activeStep === steps.JOB_INFO && (
              <JobInfoStep
                control={control}
                errors={errors}
                onNext={() => {
                  activateStep(steps.JOB_DESC)
                }}
                register={register}
                trigger={trigger}
                watch={watch}
              />
            )}

            {activeStep === steps.JOB_DESC && (
              <JobDescriptionStep
                control={control}
                errors={errors}
                onPrev={() => {
                  activateStep(steps.JOB_INFO)
                }}
              />
            )}
          </form>
        </div>
      </div>
    </div>
  )
}

export default CreateJob
