import { collection, query, where } from "firebase/firestore"
import { useCollectionData } from "react-firebase-hooks/firestore"
import { useSelector } from "react-redux"
import { userId as selectUserId } from "../app/userRedux/userSlice"
import { db } from "../config/firebase"

const useFetchMyJobs = () => {
  const userId = useSelector(selectUserId)

  const [jobs, jobsAreLoading] = useCollectionData(
    query(collection(db, "jobs"), where("userid", "==", userId)),
  )

  return {
    jobs,
    jobsAreLoading,
  }
}

export default useFetchMyJobs
