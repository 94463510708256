import React from "react"
import { CgFlagAlt } from "react-icons/cg"
import { CiLocationOn } from "react-icons/ci"
import { FaUserGroup } from "react-icons/fa6"
import { VscGlobe } from "react-icons/vsc"
import { useDispatch, useSelector } from "react-redux"
import { Link, useNavigate } from "react-router-dom"
import { DotLoader } from "react-spinners"
import RecruiterProfileJobCard from "../../components/RecruiterProfileJobCard.jsx"
import { IMAGES } from "../../constants/images.jsx"
import useFetchMyJobs from "../../hooks/useFetchMyJobs.js"
import NavbarRec from "../../utils/NavBarRec"

const RecruiterProfile = () => {
  const dispatch = useDispatch()
  const employer = useSelector((state) => state.data.value)
  const navigate = useNavigate()

  const { jobs, jobsAreLoading } = useFetchMyJobs()

  // const signout = () => {
  //   console.log("signed out")
  //   navigate("/")
  //   signoutuser(dispatch)
  // }
  // console.log(employer)
  return (
    <>
      <NavbarRec />
      <div className="flex flex-column items-center text-white overflow-y-auto">
        <div className="flex flex-column my-5 w-[90%] lg:w-[75%] rounded-md">
          {/* upper part */}
          <section className="flex flex-col lg:flex-row h-fit items-center justify-between bg-[#364A63] rounded-md lg:rounded-r-md lg:rounded-l-full  my-4">
            <div className="flex flex-col lg:flex-row items-center gap-3">
              <div className="h-2 lg:hidden"></div>
              <div className="">
                {employer?.firmLogo ? (
                  <>
                    <img
                      src={employer.firmLogo}
                      alt="firm logo"
                      className="w-24 h-24 lg:w-32 lg:h-32 rounded-full border-2 border-yellow-400"
                    />
                  </>
                ) : (
                  <>
                    <img
                      src={IMAGES.FIRM_LOGO}
                      alt="firm logo"
                      className="w-24 h-24 lg:w-32 lg:h-32 rounded-full border-2 border-yellow-400"
                    />
                  </>
                )}
              </div>
              <div className="flex flex-col justify-center items-center lg:justify-start lg:items-start ">
                {/* <h3>{employer?.email}</h3> */}
                <h3 className="font-bold">{employer?.companyName}</h3>
                <p className="flex items-center mt-2">
                  <CiLocationOn size={22} className="mr-2" />
                  {employer?.firmLocation ? (
                    <>
                      <span>{employer.firmLocation}</span>
                    </>
                  ) : (
                    <>
                      <span>Company location not added</span>
                    </>
                  )}
                </p>
              </div>
            </div>
            <div className="flex items-center justify-center  lg:border-l-2 lg:border-r-2 lg:border-gray-300   h-20 mx-5">
              <div className="flex items-center justify-center mt-3 px-5">
                <p className="flex flex-column items-center justify-center text-center  mx-3 ">
                  <FaUserGroup size={22} className="mb-2" />
                  {employer?.firmSize ? (
                    <>
                      <span>{employer.firmSize}</span>
                    </>
                  ) : (
                    <>
                      <span>Company size</span>
                    </>
                  )}
                </p>
              </div>
            </div>
            <div className="flex flex-column justify-center items-center lg:items-start gap-y-2 h-28 mx-5 w-ful lg:w-[20%]">
              <div className="mt-3">
                <p className="flex items-center mb-2 ">
                  <CgFlagAlt className="mr-1" />
                  <span className="text-sm">Founded in &nbsp;</span>
                  {employer?.firmFounded}
                </p>
                <Link
                  to={employer?.firmWebsite ? employer.firmWebsite : "#"}
                  target="_blank"
                  className="flex items-center py-1 px-3 bg-black w-fit	rounded-md text-[#46D0E4] mb-2"
                >
                  <VscGlobe size={22} className="mr-2" />
                  Website
                </Link>
                {/* <button
                  className="bg-gray-400 text-black px-4 py-1 rounded-full border border-solid border-gray-400 hover:bg-gray-200 hover:border-gray-200"
                  onClick={() => signout()}
                >
                  Sign Out
                </button> */}
              </div>
            </div>
          </section>
          {/* lower part */}
          <section className="flex  gap-x-3 mt-3">
            {/* left side */}
            <div className="bg-[#364A63] w-full rounded-md">
              <div className="p-4">
                {/* upper */}
                <div>
                  <div className="flex items-center justify-between mb-1">
                    <h3 className="font-bold">About Us</h3>
                    {/* <AiFillEdit size={24} className="cursor-pointer" /> */}
                  </div>

                  <div
                    dangerouslySetInnerHTML={{ __html: employer?.aboutCompany }}
                  />
                </div>
                <div className="border-solid border-b-[1px] border-[#131F2E] my-3 "></div>

                <div className="mt-5">
                  <div className="flex items-center justify-between mb-5">
                    <h3 className="font-bold">Posted Jobs</h3>
                    {/* <AiFillEdit size={24} className="cursor-pointer" /> */}
                  </div>
                  {jobsAreLoading ? (
                    <DotLoader color="#868bff" />
                  ) : (
                    jobs.map((job) => (
                      <div className=" mt-4 h-fit">
                        <RecruiterProfileJobCard key={job.uid} jobData={job} />
                      </div>
                    ))
                  )}
                </div>
              </div>
            </div>
            {/* right side */}
            {/* <div className="w-[30%]  "> */}
            {/* upper part */}
            {/* <div className="bg-[#364A63] rounded-md p-3 mb-2"> */}
            {/* <div className="flex items-center justify-between">
                  <h3 className="font-bold">Documents</h3>
                  <AiFillEdit size={24} className="cursor-pointer" />
                </div> */}
            {/* documents cards */}
            {/* <div>
                  <div className="mt-3">
                    <div className="text-xs flex justify-between ">
                      <p className="flex items-center">
                        <HiOutlineDocument
                          size={22}
                          className="bg-gray-300 text-black p-[2px] rounded mr-2"
                        />
                        Legal information
                      </p>
                      <button className="bg-[#46D6EA] px-2 py-1 text-black rounded-md">
                        View PDF
                      </button>
                    </div>
                    <div className="border-solid border-b-[1px] border-[#131F2E] my-3 "></div>
                  </div>
                  <div className="mt-3">
                    <div className="text-xs flex justify-between ">
                      <p className="flex items-center">
                        <HiOutlineDocument
                          size={22}
                          className="bg-gray-300 text-black p-[2px] rounded mr-2"
                        />
                        Related Document
                      </p>
                      <button className="bg-[#46D6EA] px-2 py-1 text-black rounded-md">
                        View PDF
                      </button>
                    </div>
                    <div className="border-solid border-b-[1px] border-[#131F2E] my-3 "></div>
                  </div>
                  <div className="mt-3">
                    <div className="text-xs flex justify-between ">
                      <p className="flex items-center">
                        <HiOutlineDocument
                          size={22}
                          className="bg-gray-300 text-black p-[2px] rounded mr-2"
                        />
                        Related Document
                      </p>
                      <button className="bg-[#46D6EA] px-2 py-1 text-black rounded-md">
                        View PDF
                      </button>
                    </div> */}
            {/* <div className="border-solid border-b-[1px] border-[#131F2E] my-3 "></div> */}
            {/* </div>
                </div>
              </div> */}
            {/* lower part */}
            {/* <div className="bg-[#364A63] rounded-md p-3 mt-3">
                <div className="flex items-center justify-between">
                  <h3 className="font-bold">Awards</h3>
                  <AiFillEdit size={24} className="cursor-pointer" />
                </div> */}
            {/* awards cards */}
            {/* <div className="my-3">
                  <p className="text-black text-sm w-fit py-1 pl-2 pr-3 bg-gray-300 rounded-full flex items-center mb-2">
                    <img
                      src={IMAGES.TICK}
                      alt="tick icon"
                      className="w-3 h-3 mr-2"
                    />
                    Great Communication
                  </p>
                  <p className="text-black text-sm w-fit py-1 pl-2 pr-3 bg-gray-300 rounded-full flex items-center mb-2">
                    <img
                      src={IMAGES.TICK}
                      alt="tick icon"
                      className="w-3 h-3 mr-2"
                    />
                    Organized
                  </p>
                  <p className="text-black text-sm w-fit py-1 pl-2 pr-3 bg-gray-300 rounded-full flex items-center mb-2">
                    <img
                      src={IMAGES.TICK}
                      alt="tick icon"
                      className="w-3 h-3 mr-2"
                    />
                    Critical Thinking
                  </p>
                  <p className="text-black text-sm w-fit py-1 pl-2 pr-3 bg-gray-300 rounded-full flex items-center mb-2">
                    <img
                      src={IMAGES.TICK}
                      alt="tick icon"
                      className="w-3 h-3 mr-2"
                    />
                    Problem Solving
                  </p>
                </div> */}
            {/* </div> */}
            {/* </div> */}
          </section>
        </div>
      </div>
    </>
  )
}

export default RecruiterProfile
