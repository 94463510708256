import { doc } from "firebase/firestore"
import React from "react"
import { useDocument } from "react-firebase-hooks/firestore"
import { CgFlagAlt } from "react-icons/cg"
import { CiLocationOn } from "react-icons/ci"
import { FaUserGroup } from "react-icons/fa6"
import { PiCaretCircleLeftFill } from "react-icons/pi"
import { VscGlobe } from "react-icons/vsc"
import { useDispatch, useSelector } from "react-redux"
import { Link, useNavigate, useParams } from "react-router-dom"
import { firestore } from "../config/firebase.js"
import { IMAGES } from "../constants/images.jsx"
import RecruiterProfileJobCard from "./RecruiterProfileJobCard.jsx"

const CandidateLawFirmView = ({ setShowFirmPage }) => {
  const initialData = useSelector((state) => state.initialData)
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { jobUid } = useParams()
  const [value, loading, error] = useDocument(doc(firestore, "jobs", jobUid), {
    snapshotListenOptions: { includeMetadataChanges: true },
  })

  // console.log(value?.data())

  const handleReturn = () => {
    // dispatch(updateInitialData({ selectedJob: data }))
    // navigate("/jobboard")
    setShowFirmPage(false)
  }
  return (
    <div
      className="flex flex-column items-center h-screen text-white overflow-y-auto relative z-0"
      style={{
        height: "calc(100vh - 62px)", // Adjust the height based on your navbar height
      }}
    >
      <div className="flex flex-column my-5  w-[75%]">
        {/* upper part */}
        <div className="flex items-center">
          <PiCaretCircleLeftFill
            size={38}
            className="mr-2 text-blue-400 cursor-pointer duration-75	 hover:scale-125"
            onClick={handleReturn}
          />
          <p className="text-xl font-bold"> Back to job page</p>
        </div>
        <section className="flex flex-col lg:flex-row h-fit items-center justify-between bg-[#364A63] rounded-md lg:rounded-r-md lg:rounded-l-full  my-4">
          <div className="flex flex-col lg:flex-row items-center gap-2">
            <div>
              {value?.data().firmLogo ? (
                <>
                  <img
                    src={value?.data().firmLogo}
                    alt="firm logo"
                    className="w-32 h-32 rounded-full border-2 border-yellow-400 "
                  />
                </>
              ) : (
                <>
                  <img
                    src={IMAGES.FIRM_LOGO}
                    alt="firm logo"
                    className="w-32 h-32 rounded-full border-2 border-yellow-400 "
                  />
                </>
              )}
            </div>
            <div className="flex flex-col justify-center items-center lg:justify-start lg:items-start ">
              {/* <h3>{initialData?.selectedJob??.email}</h3> */}
              <h3 className="font-bold ">{value?.data().companyName}</h3>
              <p className="flex items-center mt-2">
                <CiLocationOn size={22} className="mr-2" />
                {value?.data().firmLocation ? (
                  <>
                    <span>{value?.data().firmLocation}</span>
                  </>
                ) : (
                  <>
                    <span>Company location not added</span>
                  </>
                )}
              </p>
            </div>
          </div>
          <div className="flex items-center justify-center  lg:border-l-2 lg:border-r-2 lg:border-gray-300   h-20 mx-5">
            <div className="flex items-center justify-center mt-3 px-5">
              <p className="flex flex-column items-center justify-center text-center  mx-3 ">
                <FaUserGroup size={22} className="mb-2" />
                {value?.data().firmSize ? (
                  <>
                    <span>{value?.data().firmSize}</span>
                  </>
                ) : (
                  <>
                    <span>Company size</span>
                  </>
                )}
              </p>
            </div>
          </div>
          <div className="flex flex-column justify-center items-center lg:items-start gap-y-2 h-28 mx-5 w-ful lg:w-[20%]">
            <div className="mt-3">
              <p className="flex items-center justify-center lg:justify-start mb-2 ">
                <CgFlagAlt className="mr-1" />
                <span className="text-sm">Founded in &nbsp;</span>
                {value?.data().firmFounded}
              </p>
              <Link
                to={value?.data().firmWebsite ? value?.data().firmWebsite : "#"}
                target="_blank"
                className="flex items-center py-1 px-3 bg-black w-fit	rounded-md text-[#46D0E4] mb-2"
              >
                <VscGlobe size={22} className="mr-2" />
                Website
              </Link>
            </div>
          </div>
        </section>
        {/* lower part */}
        <section className="flex  gap-x-3 mt-3">
          {/* left side */}
          <div className="bg-[#364A63] w-full rounded-md">
            <div className="p-4">
              {/* upper */}
              <div>
                <div className="flex items-center justify-between mb-1">
                  <h3 className="font-bold">About Us</h3>
                </div>

                <div
                  dangerouslySetInnerHTML={{
                    __html: value?.data().aboutCompany,
                  }}
                />
              </div>
              <div className="border-solid border-b-[1px] border-[#131F2E] my-3 "></div>

              <div className="mt-5">
                <div className="flex items-center justify-between mb-5">
                  <h3 className="font-bold">Posted Jobs</h3>
                </div>
                {value?.data().jobs?.map((job, index) => (
                  <div className=" mt-4 h-20">
                    <RecruiterProfileJobCard key={index} jobData={job} />
                  </div>
                ))}
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  )
}

export default CandidateLawFirmView
