import React, { useState } from "react"
import { IMAGES } from "../constants/images.jsx"
import { Modal, Button, Row } from "react-bootstrap"

const RecruiterMatchModal = ({ show, handleClose }) => {
  return (
    <Modal show={show} onHide={handleClose} centered>
      <Modal.Body className="flex flex-column items-center justify-center">
        <Row className="items-center justify-center gap-y-2 text-center mb-2 ">
          <img src={IMAGES.CONE} className=" max-w-fit h-full	" />
          <p className="font-bold text-2xl">Hooray!</p>
        </Row>
        <Row className="items-center justify-between border-2 border-[#46D6EA] rounded-full my-2 w-[45%]">
          <div className="flex items-center justify-center  w-14 h-14 md:w-20 md:h-20 border-2 border-[#46D6EA] rounded-full">
            <img
              src={IMAGES.U_ICON}
              className="rounded-full max-w-fit h-full	"
            />
          </div>
          <div className="flex items-center justify-center  w-14 h-14 md:w-20 md:h-20 border-2 border-[#46D6EA] rounded-full">
            <img
              src={IMAGES.REC_LOGO}
              className="rounded-full max-w-fit h-full	"
            />
          </div>
        </Row>
        <Row className="items-center justify-center text-center  w-full ">
          {/* <p className="text-4xl font-bold">You have a match!</p> */}
          <p className="">You’ve matched Lauren with Senior Candidate!</p>
        </Row>
      </Modal.Body>
      <Modal.Footer>
        <Row className="flex items-center justify-center gap-y-2">
          <Row className="w-75 my-1">
            <Button
              className=" font-bold bg-[#46D6EA] text-black px-5 hover:bg-transparent"
              //   variant="info"

              // onClick={save}
            >
              Contact Candidate
            </Button>
          </Row>
          <Row className="w-75 my-1">
            <Button
              className=" font-bold border-[#46D6EA] text-black px-5 hover:bg-transparent "
              //   variant="info"

              onClick={handleClose}
            >
              Continue browsing
            </Button>
          </Row>
        </Row>
      </Modal.Footer>
    </Modal>
  )
}

export default RecruiterMatchModal
