import React from "react"
import { Controller } from "react-hook-form"
import ReactQuill from "react-quill"
import "react-quill/dist/quill.snow.css"

const Content2 = ({ control, errors }) => {
  const toolbarOptions = [
    [{ font: [] }],
    ["bold", "italic", "underline", "strike"], // toggled buttons
    ["blockquote"],
    ["link", "image"],
    // [{ header: 1 }, { header: 2 }], // custom button values
    [{ list: "ordered" }, { list: "bullet" }, { list: "check" }],
    // [{ script: "sub" }, { script: "super" }], // superscript/subscript
    // [{ indent: "-1" }, { indent: "+1" }], // outdent/indent
    // [{ direction: "rtl" }], // text direction
    // [{ size: ["small", false, "large", "huge"] }], // custom dropdown
    [{ header: [1, 2, 3, 4, 5, 6, false] }],
    [{ color: [] }, { background: [] }], // dropdown with defaults from theme
    [{ align: [] }],
    ["clean"], // remove formatting button
  ]

  const module = {
    toolbar: toolbarOptions,
  }
  return (
    <div className="flex flex-column  bg-white  rounded-md w-full p-2 ">
      <div className="flex flex-column items-start my-3 px-4">
        <p className="font-bold">Job Description</p>

        {/* the rest of the form */}
        <div className="w-100">
          {/* Description */}
          <div className="flex flex-column w-100">
            <label className="my-2">
              Please provide a job description that will be useful for the
              candidates
            </label>

            <Controller
              name="jobDescription"
              control={control}
              render={({ field }) => {
                return (
                  <ReactQuill
                    {...field}
                    modules={module}
                    placeholder="Write a description of the job which might help the candidates decide whether they are the right fit"
                  />
                )
              }}
            />
            {errors.jobDescription && (
              <p className="text-red-400">{errors.jobDescription.message}</p>
            )}
          </div>

          <br />
          {/* Additional Description */}
          <div className="flex flex-column w-100">
            <label>Additional Description</label>
            <Controller
              name="jobAdditionalDescription"
              control={control}
              render={({ field }) => {
                return (
                  <ReactQuill
                    {...field}
                    modules={module}
                    placeholder="Write an additional description of the job which might help the candidates decide whether they are the right fit"
                  />
                )
              }}
            />
            {errors.jobAdditionalDescription && (
              <p className="text-red-400">
                {errors.jobAdditionalDescription.message}
              </p>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

export default Content2
