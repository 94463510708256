import React, { useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useLocation, useNavigate } from "react-router-dom"
import { IMAGES } from "../../constants/images.jsx"
import styles from "./CandidateData.module.css"

const button_text = "Finish"
const button_back_text = "Back"

const CandidateData3 = () => {
  const loggedUser = useSelector((state) => state.data.value)

  const { state } = useLocation()
  const {
    uid,
    email,
    name,
    surname,
    location,
    // description,
    selectedLanguages,
    searchingStatus,
    profileImg,
    queryLoca,
    queryFirmType,
    queryExpertise,
    queryAnnual,
    queryNotMatching,
    sliderValue,
    selectedInstitute,
    graduationDate,
    master,
    cv,
    coverLetter,
    transcripts,
    employmentHistory,
  } = state // Read values passed on state

  const [isEducationNotListed, setIsEducationNotListed] = useState(false)
  const [institutes, setInstitutes] = useState([])
  const [status, setStatus] = useState(true)
  // const [editable, setEditable] = useState(false)

  const handleEducationNotListedChange = (checked) => {
    setIsEducationNotListed(checked)
  }

  // const editData = async (e) => {
  //   e.preventDefault()
  //   try {
  //     await updateDoc(doc(db, "users", loggedUser.uid), {
  //       isEducationNotListed,
  //       institutes,
  //       status,
  //     })
  //   } catch (error) {
  //     console.log(error)
  //   }
  // }

  const dispatch = useDispatch()

  const navigate = useNavigate()

  const nextWindow = async () => {
    // const data = {
    //   uid: uid,
    //   email: email,
    //   name: name,
    //   surname: surname,
    //   location: location,
    //   // description: description,
    //   searchingStatus: searchingStatus,
    //   profileImg: profileImg,
    //   queryLoca: queryLoca,
    //   queryFirmType: queryFirmType,
    //   queryExpertise: queryExpertise,
    //   queryAnnual: queryAnnual,
    //   queryNotMatching: queryNotMatching,
    //   sliderValue: sliderValue,
    //   selectedInstitute: selectedInstitute,
    //   graduationDate: graduationDate,
    //   master: master,
    //   cv: cv,
    //   coverLetter: coverLetter,
    //   transcripts: transcripts,
    //   employmentHistory: employmentHistory,
    //   // editable: editable,
    // }
    // // await updateDoc(doc(db, "users", loggedUser.uid), {
    // //   editable,
    // // })
    // await signInOrLogin(data, dispatch, saveData)

    // navigate("/homecandidate")
    navigate("/candidatedata6", {
      state: {
        uid,
        email,
        name,
        surname,
        location,
        searchingStatus,
        profileImg,
        selectedLanguages,
        queryLoca,
        queryFirmType,
        queryExpertise,
        queryAnnual,
        queryNotMatching,
        sliderValue,
        selectedInstitute,
        graduationDate,
        master,
        cv,
        coverLetter,
        transcripts,
        employmentHistory,
      },
    })
  }
  const previousWindow = (e) => {
    navigate("/candidatedata2", {
      state: {
        uid,
        email,
      },
    })
  }

  return (
    <div className={styles.container}>
      {/* Box Container */}
      <div className={styles.boxcontainer}>
        <img src={IMAGES.JLFULL} className="h-12 " />

        {/* ------------------------ Actively looking / Causally looking ------------------------*/}
        <div className={styles.innercontainer}>
          <p className={styles.p}>What is your status?</p>
          <p className={styles.textreg}>
            Please specify what law firm specifications you prefer so that we
            find the ones that suit you best.
          </p>
          {/* <div className={styles.row}>
            <button
              className={
                status
                  ? "button_switch border_left"
                  : "button_switch_selected border_left"
              }
              onClick={() => setStatus(!status)}
            >
              Actively looking
            </button>
            <button
              className={
                status
                  ? "button_switch_selected border_right"
                  : "button_switch border_right"
              }
              onClick={() => setStatus(!status)}
            >
              Casually looking
            </button>
          </div> */}

          {/* ------------------------ Location  ------------------------*/}
          <p className={styles.p}>Location</p>
          <input className={styles.input} placeholder="Search location" />

          {/* ------------------------ Type of firm  ------------------------*/}
          <p className={styles.p}>Type of firm</p>
          <input className={styles.input} placeholder="Select type" />

          {/* ------------------------ Select Expertise  ------------------------*/}
          <p className={styles.p}>Expertise</p>
          <input className={styles.input} placeholder="Select type" />

          {/* ------------------------ remuneration  ------------------------*/}
          <p className={styles.p}>Remuneration</p>
          <input
            className={styles.input}
            placeholder="Select range of remuneration"
          />

          {/* ------------------------ Exclude  ------------------------*/}
          {/* <p className={styles.textreg}>
            Are there any law firms you would like to exclude yourself from? If
            so, please include them here.
          </p>
          <input className={styles.input} placeholder="Type law firm name" /> */}

          {/* ------------------------ Back / Next ------------------------*/}
          <div className={styles.bottombar}>
            {/* {loggedUser.editable ? (
              <>
                <button
                  className={styles.buttonback}
                  onClick={(e) => previousWindow(e)}
                >
                  {button_back_text}
                </button>
                <button
                  className={styles.buttonUpdate}
                  onClick={(e) => handleUpdate(e)}
                >
                  update and next
                </button>
                <button
                  className={styles.buttonnext}
                  onClick={(e) => {
                    finish_register(e)
                  }}
                >
                  Skip and Finish
                </button>
              </>
            ) : (
              <>
                <button
                  className={styles.buttonback}
                  onClick={(e) => previousWindow(e)}
                >
                  {button_back_text}
                </button>
                <button
                  className={styles.buttonnext}
                  onClick={(e) => {
                    finish_register(e)
                  }}
                >
                  {button_text}
                </button>
              </>
            )} */}

            {/* ------------------------ Back ------------------------*/}
            <button
              className={styles.buttonback}
              onClick={(e) => previousWindow(e)}
            >
              {button_back_text}
            </button>
            {/* ------------------------ Update button  ------------------------*/}
            {/* {loggedUser.editable && (
              <>
                <button
                  className={styles.buttonUpdate}
                  onClick={(e) => {
                    handleUpdate(e)
                  }}
                >
                  Finish and Save
                </button>
              </>
            )} */}
            {/* ------------------------ Next ------------------------*/}
            <button
              className={styles.buttonnext}
              onClick={(e) => {
                nextWindow(e)
              }}
            >
              Next
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default CandidateData3
